import { useState, useEffect } from "react";
import { RenderElement } from "./renderElement";
import { RenderObject } from "./renderObject";
import { v4 as uuidv4 } from "uuid";

export const RenderContent: React.FC<{
  content: any | any[];
}> = ({ content }): React.ReactNode => {
  return (
    <>
      {content && Array.isArray(content) ? (
        content.map((item: any, index) => (
          <>
            {typeof item === "object" ? (
              <RenderObject
                obj={item as { [key: string]: string }}
                key={uuidv4()}
              />
            ) : (
              <RenderElement element={item} key={uuidv4()} />
            )}
          </>
        ))
      ) : (
        <>
          {typeof content === "object" ? (
            <RenderObject obj={content as { [key: string]: string }} />
          ) : (
            <RenderElement element={content as string} />
          )}
        </>
      )}
    </>
  );
};

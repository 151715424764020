import { validateState } from "@/helpers/validators";
import React, { useEffect, useState } from "react";
import { Outlet, useParams, useOutlet } from "react-router-dom";
import InvalidState from "./InvalidState";
import StateWrapper from "./StateWrapper";
import StateDetail from "./StateDetail";

const StateView: React.FC = () => {
  const { state } = useParams<{ state: string }>();
  const [isValidState, setIsValidState] = useState(true);
  const outlet = useOutlet();

  useEffect(() => {
    if (state) {
      setIsValidState(validateState(state));
    }
  }, [state]);

  return (
    <div className="m-8 overflow-hidden">
      {isValidState ? (
        outlet ? (
          <StateWrapper state={state || ""}>
            <Outlet />
          </StateWrapper>
        ) : (
          <StateDetail state={state || ""} />
        )
      ) : (
        <InvalidState />
      )}
    </div>
  );
};

export default StateView;

import PageHeader from "../components/page-header";
import { useState, useEffect } from "react";
import Api from "@/utils/api";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { v4 as uuidv4 } from "uuid";
import { PlanInfo, pgPlanInfo } from "@/types/plan";
import { RenderContent } from "../components/renderContent";
import { cn } from "@/lib/utils";
const dataSelectOptions = [
  { value: "tdus", label: "By TDU", getOptions: Api.getTDUs },
  { value: "retailers", label: "By Retailer", getOptions: Api.getRetailers },
  { value: "zipcodes", label: "By Zipcode" },
  { value: "recently-deleted", label: "Recently Deleted" },
  { value: "recently-added", label: "Recently Added" },
];

const DataSelect: React.FC<{
  selected: string;
  setSelected: (value: string) => void;
  options: any[];
}> = ({ selected, setSelected, options }) => {
  return (
    <Select value={selected} onValueChange={setSelected}>
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder="Select a data type" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Plans</SelectLabel>
          {options.map((option) => (
            <SelectItem
              key={uuidv4()}
              value={option["getxid"] || option["value"]}
            >
              {option["name"] || option["label"]}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

const mapPlanData = (plan: PlanInfo) => {
  const aiAnalysis = plan["ai-analysis"];

  return (
    <div key={uuidv4()} className="flex flex-col gap-4">
      <div className="text-sm font-medium">{plan["info"]["plan-name"]}</div>
    </div>
  );
};

const PlanListItem: React.FC<{
  plan: pgPlanInfo;
  selected: boolean;
  setSelectedPlan: (plan: pgPlanInfo) => void;
}> = ({ plan, selected, setSelectedPlan }) => {
  return (
    <div
      className={cn(
        "flex flex-row justify-between gap-4 border border-stone-300 rounded-md p-4 cursor-pointer",
        selected && "bg-stone-200 !border-white"
      )}
      onClick={() => setSelectedPlan(plan)}
    >
      <div className="text-sm font-medium">
        {plan?.data?.["info"]?.["plan-name"]}
      </div>
      <div className="text-sm text-stone-500">{plan?.tdu}</div>
    </div>
  );
};

const PlanDetail: React.FC<{
  plan: pgPlanInfo;
}> = ({ plan }) => {
  return (
    <div className="w-full">
      <RenderContent content={plan.data} />
    </div>
  );
};

const PlanList: React.FC<{
  plans: pgPlanInfo[];
}> = ({ plans }) => {
  const [selectedPlan, setSelectedPlan] = useState<pgPlanInfo | null>(null);
  return (
    <div className="grid grid-cols-[12vw_1fr] gap-4 max-h-[80vh] max-w-[80vw]">
      <div className="flex flex-col gap-2 overflow-y-auto">
        {plans.map((plan) => (
          <PlanListItem
            plan={plan}
            key={uuidv4()}
            selected={selectedPlan === plan}
            setSelectedPlan={setSelectedPlan}
          />
        ))}
      </div>
      <div className="flex flex-col gap-4 justify-start items-left p-4 border border-stone-300 rounded-md bg-stone-200 overflow-y-auto">
        {selectedPlan && <PlanDetail plan={selectedPlan} />}
      </div>
    </div>
  );
};
// We are going to hardcode to use retailers for now
export const Plans2 = () => {
  const [dataItems, setDataItems] = useState<any[]>([]);
  const [selectedDataType, setSelectedDataType] = useState<string>("retailers");
  const [dataTypeOptions, setDataTypeOptions] = useState<any[]>([]);
  const [selectedDataItem, setSelectedDataItem] = useState<string>("");

  useEffect(() => {
    switch (selectedDataType) {
      case "tdus":
        Api.getTDUs().then(setDataTypeOptions);
        break;
      case "recently-deleted":
        Api.getRecentlyDeletedPlans().then(setDataItems);
        break;
      case "recently-added":
        Api.getRecentlyCreatedPlans().then(setDataItems);
        break;
      default:
        Api.getRetailers().then(setDataTypeOptions);
    }
  }, [selectedDataType]);

  useEffect(() => {
    if (selectedDataItem) {
      Api.plansByRetailer(selectedDataItem).then(setDataItems);
    }
  }, [selectedDataItem]);

  const renderDataItems = () => {
    switch (selectedDataType) {
      case "recently-deleted":
        return <pre>{JSON.stringify(dataItems, null, 2)}</pre>;
      case "recently-added":
        return <pre>{JSON.stringify(dataItems, null, 2)}</pre>;
      default:
        return <PlanList plans={dataItems} />;
    }
  };

  return (
    <div className="flex flex-col gap-4 items-left justify-start w-full max-w-[80vw] h-full p-8">
      <PageHeader
        title="Plans"
        col1={
          <DataSelect
            selected={selectedDataType}
            setSelected={setSelectedDataType}
            options={dataSelectOptions}
          />
        }
        col2={
          selectedDataType !== "recently-deleted" &&
          selectedDataType !== "recently-added" && (
            <DataSelect
              selected={selectedDataItem}
              setSelected={setSelectedDataItem}
              options={dataTypeOptions}
            />
          )
        }
      />
      {renderDataItems()}
    </div>
  );
};

import React from "react";
import { useGuide } from "../GuideContext";
import AddressFinder from "../../address/AddressAutocomplete";
import NewSearch from "@/components/individual/newSearch";

const AddressStep: React.FC = () => {
  const { dispatch } = useGuide();

  const handleAddressSelect = (
    address: string,
    coordinates: { lat: number; lng: number }
  ) => {
    dispatch({ type: "SET_ADDRESS", payload: address });
    dispatch({ type: "SET_HAS_ADDRESS", payload: true });
  };

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-xl font-semibold text-gray-900">
          Where are you moving to?
        </h2>
        <p className="mt-1 text-sm text-gray-500">
          Enter your new service address
        </p>
      </div>

      <NewSearch />
    </div>
  );
};

export default AddressStep;

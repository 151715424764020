import SearchBar2 from "@/components/individual/newSearch";
import { LuminaState } from "@/components/lumina/Lumina";
import { useIsMobile } from "@/hooks/use-mobile";
import { cn } from "@/lib/utils";
import { motion } from "framer-motion";
import { Shield } from "lucide-react";
import React, { useState } from "react";
import HeroStats from "./HeroStats";
const Hero: React.FC = () => {
  const isMobile = useIsMobile();
  const [selectedState, setSelectedState] = useState<LuminaState>("default");
  return (
    <section
      id="hero"
      className={cn(
        "relative min-h-[calc(100vh-4rem)]",
        "flex items-center justify-center",
        "bg-gradient-to-br from-blue-900 via-blue-800 to-blue-900",
        "overflow-hidden"
      )}
      aria-labelledby="hero-heading"
    >
      {/* Background Pattern */}
      {!isMobile && (
        <div
          className={cn(
            "absolute inset-0 bg-cover bg-center bg-no-repeat",
            "opacity-40 mix-blend-soft-light",
            "transform scale-105",
            "filter grayscale"
          )}
          style={{
            backgroundImage:
              "url(https://storage.googleapis.com/getelectricity-assets/images/hero-home.jpg)",
            backgroundPosition: "50% 60%",
          }}
          aria-hidden="true"
        />
      )}

      <div className="relative max-w-[720px] mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="mx-auto text-center">
          {/* Trust Badge */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className={cn(
              "inline-flex items-center gap-2 px-4 py-2",
              "rounded-full bg-green-400/10 backdrop-blur-sm mb-8"
            )}
          >
            <Shield className="w-4 h-4 text-green-400" />
            <span className="text-sm text-green-100 font-medium tracking-wide">
              Licensed by Texas PUC
            </span>
          </motion.div>

          {/* Main Headline */}
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className={cn(
              "text-4xl sm:text-6xl lg:text-7xl",
              "font-black text-white",
              "tracking-[0.02em] leading-[1.1]",
              "mb-6"
            )}
          >
            <span className="text-4xl sm:text-6xl lg:text-7xl tracking-[0.02em]">
              Get the
            </span>{" "}
            <motion.span
              animate={{
                color: [
                  "rgba(255, 255, 255, 1)",
                  "rgba(147, 197, 253, 1)",
                  "rgba(255, 255, 255, 1)",
                ],
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                ease: "easeInOut",
              }}
              className="tracking-[0.02em]"
            >
              Lowest
            </motion.span>
            <br />
            <span className="tracking-[0.02em]">Electricity Rates</span>
          </motion.h1>

          {/* Subtitle */}
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="text-xl text-blue-100 mb-4 tracking-[0.02em] font-medium"
          >
            Compare rates from top providers and find your best plan
          </motion.p>

          {/* ZIP Input */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="mb-6"
          >
            <SearchBar2 enroll={false} width="w-[100%]" />
          </motion.div>

          {/* Time Estimate */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="text-sm text-blue-200 mb-3 tracking-[0.02em] font-medium"
          >
            Takes 2 minutes • Sign up today, save tomorrow
          </motion.div>

          {/* Trust Text */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
            className="text-sm text-blue-200 mb-16 tracking-[0.02em] font-medium"
          >
            Trusted by 50,000+ Texas households
          </motion.div>
        </div>

        {/* Stats */}
        <HeroStats />
      </div>
    </section>
  );
};

export default Hero;

import { useIsMobile } from "@/hooks/use-mobile";
import ForecastUsage from "./ForecastUsage";
import { PlansGrid } from "./PlansGrid";
import { RecommendedPlans } from "./RecommendedPlans";
import { FilterPanel } from "./filters/Filters";
import { Pane } from "@/components/Pane";
import { PlansTable } from "./PlansTable";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { Grid, List } from "lucide-react";

export const PlanDisplay = () => {
  const isMobile = useIsMobile();
  const [displayMode, setDisplayMode] = useState<"grid" | "table">("grid");

  const PlanDisplayToolbar = () => {
    const buttonBaseClass = "relative inline-flex items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset";
    const activeClass = "bg-gray-100 text-gray-900 ring-gray-300";
    const inactiveClass = "bg-white text-gray-500 ring-gray-300 hover:bg-gray-50";

    return (
      <Pane className="w-full border-b border-gray-200" bgClass="bg-white">
        <div className="w-full flex flex-row justify-end gap-2">
          <span className="isolate inline-flex rounded-md shadow-sm">
            <button
              type="button"
              onClick={() => setDisplayMode("grid")}
              className={`${buttonBaseClass} rounded-l-lg ${
                displayMode === "grid" ? activeClass : inactiveClass
              } focus:z-10`}
            >
              <Grid className="w-6 h-6" />
            </button>
            <button
              type="button"
              onClick={() => setDisplayMode("table")}
              className={`${buttonBaseClass} -ml-px rounded-r-lg ${
                displayMode === "table" ? activeClass : inactiveClass
              } focus:z-10`}
            >
              <List className="w-6 h-6" />
            </button>
          </span>
        </div>
      </Pane>
    );
  };

  if (isMobile) {
    return (
      <div className="w-full h-full">
        <PlansGrid />
      </div>
    );
  } else {
    return (
      <div className="w-full h-full flex flex-row gap-2">
        <div className="w-full flex flex-col justify-start max-w-[25%]">
          {/* <ForecastUsage /> */}
          <FilterPanel />
        </div>
        <div className="w-full flex flex-col justify-start p-2">
          <PlanDisplayToolbar />
          {/* <RecommendedPlans /> */}
          {displayMode === "grid" ? <PlansGrid /> : <PlansTable />}
        </div>
      </div>
    );
  }
};

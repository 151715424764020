export interface tdu {
  key: string;
  "display-name": string;
  "lookup-names": string[];
}

export type TypicalContractLengthKeys =
  | "month"
  | "3month"
  | "6month"
  | "12month"
  | "24month";

export type RateType = "fixed" | "variable" | "indexed";
export type GreenImportance = "low" | "medium" | "high";
export type PeakUsageTime = "morning" | "afternoon" | "evening" | "night";

export const peakTimeOptions: Record<
  PeakUsageTime,
  {
    id: PeakUsageTime;
    label: string;
    time: string;
  }
> = {
  morning: { id: "morning", label: "Morning", time: "6 AM - 12 PM" },
  afternoon: { id: "afternoon", label: "Afternoon", time: "12 PM - 5 PM" },
  evening: { id: "evening", label: "Evening", time: "5 PM - 10 PM" },
  night: { id: "night", label: "Night", time: "10 PM - 6 AM" },
};

export const typicalContractLengths: Record<
  TypicalContractLengthKeys,
  {
    id: TypicalContractLengthKeys;
    label: string;
    description: string;
  }
> = {
  month: {
    id: "month",
    label: "Month-to-Month",
    description: "No long-term commitment",
  },
  "3month": {
    id: "3month",
    label: "3 Months",
    description: "Short-term commitment",
  },
  "6month": {
    id: "6month",
    label: "6 Months",
    description: "Medium-term commitment",
  },
  "12month": {
    id: "12month",
    label: "12 Months",
    description: "Standard term length",
  },
  "24month": {
    id: "24month",
    label: "24 Months",
    description: "Best long-term rates",
  },
};

export interface RawProduct {
  [key: string]: any;
  getxid: string;
  time: string;
  tdu: string;
  source: string;
  data: { [key: string]: any };
}

export interface TDU {
  active: string;
  created: string;
  "created-by": string;
  "is-dirty": string;
  name: string;
  "primary-key": string;
  updated: string;
  "updated-by": string;
  _active: string;
  _created: string;
  "_created-by": string;
  _dirty: string;
  _name: string;
  _pk: string;
  _updated: string;
  "_updated-by": string;
  abbreviation: string;
  code: string;
  "ista-id": string;
  "move-in-fee": string;
  "priority-move-in-fee": string;
  "switch-fee": string;
  "type-of-tdu": string;
  [key: string]: string;
}

export type ZipInfo_T = {
  zipCode: string;
  city: string;
  state: string;
};

export interface pgTdu {
  getxid: string;
  tdu: string;
  data: tdu;
}

export interface pgPriceSheet {
  getxid: string;
  tdu: string;
  "raw-product-getxid": string;
  data: PricingSheet;
}

export interface Retailer {
  getxid: string;
  name: string;
  "lookup-names": string[];
  logo?: string;
  "logo-small"?: string;
  links?: {
    website?: string;
    "tos-link"?: string;
    "efl-link"?: string;
    "yrac-link"?: string;
  };
  contact?: {
    phone?: string;
    email?: string;
  };
  "enrollment-fields"?: string[];
  "social-media-links"?: {
    facebook?: string;
    twitter?: string;
    linkedin?: string;
    instagram?: string;
    youtube?: string;
  };
  reviews?: {
    [key: string]: {
      "average-rating"?: number;
      "total-reviews"?: number;
    };
  };
  place?: {
    "place-id"?: string;
  };
}

export interface pgPlanInfo {
  getxid: string;
  tdu: string;
  data: PlanInfo;
}

export interface PlanInfo {
  getxid: string;
  retailer: Retailer;
  info: {
    "plan-name": string;
    "plan-code": string;
    "plan-type": string;
    "plan-id": string;
    "price-plan-id"?: string;
    "plan-description"?: string;
    "pricing-sheet-getxid": string;
    "raw-product-getxid": string;
    "dwelling-type"?: string;
    "txn-type"?: string;
    "promo-code"?: string;
    "enroll-phone"?: string;
    "new-customer"?: string;
    "enroll-method"?: string;
    source: Source;
  };
  dates: {
    "start-date": Date;
    "end-date"?: Date;
    "effective-date": Date;
    "source-created-date"?: Date;
  };
  "term-length": number;
  "plan-pricing": PlanPricing;
  "usage-credit"?: UsageCredit[];
  language?: string;
  rating?: string;
  tdu: {
    code: string;
    getxid: string;
    "apply-tdu-pricing": boolean;
    "tdu-pricing"?: TduPricing;
  };
  features: {
    "early-termination-fee"?: number;
    "paperless-billing"?: boolean;
    "pct-renewable"?: number;
    prepaid?: boolean;
    "usage-discount"?: boolean;
    "free-nights"?: boolean;
    "free-weekends"?: boolean;
    "new-customer"?: boolean;
    "time-of-use"?: boolean;
    "cancellation-fee"?: number;
    "satisfaction-guarantee"?: boolean;
    "energy-type"?: string;
    tags?: string[];
    promotion?: string;
  };
  links: {
    "destination-url"?: string;
    "efl-link"?: string;
    "tos-link"?: string;
    "yrac-link"?: string;
  };
  "ai-analysis"?: any;
  valueScore: number;
  featuresScore: number;
  renewablePercentage: number;
  flexibilityScore: number;
  puctNumber: number;
}

export const headerKeys = [
  '"plan-name"',
  "planCode",
  "kWh",
  "termLength",
  "pctRenewable",
  "startDate",
  "ISSUEDATE",
  "planType",
  "TDU_CODE",
  "PRODUCT_CODE",
];

export enum Source {
  PowerToChoose = "powertochoose",
  VBB = "VBB",
}

export enum EnrollMethod {
  LinkOff = "link-off",
  DirectEnroll = "direct-enroll",
  CallCenter = "call-center",
}

export interface PricingSheet {
  getxid: string;
  "raw-product-getxid": string;
  xid: string;
  retailer: string;
  "retailer-getxid": string;
  "product-id": string;
  "product-code"?: string;
  "product-name"?: string;
  "plan-price-id"?: string;
  "plan-type"?: string;
  "plan-code"?: string;
  "dwelling-type"?: string;
  "txn-type"?: string;
  tdu?: string;
  "tdu-name"?: string;
  "tdu-getxid"?: string;
  "product-description"?: string;
  "base-fee-dollars"?: number;
  "monthly-service-fee-dollars"?: number;
  "usage-credit-band-lower"?: number;
  "usage-credit-band-upper"?: number;
  prepaid?: boolean;
  "time-of-use"?: boolean;
  "kwh-rate"?: number;
  "kwh-1000"?: number;
  "kwh-500"?: number;
  "kwh-2000"?: number;
  "fixed-tdsp-charge-dollars"?: number;
  "variable-tdsp-charge-dollars"?: number;
  "term-length"?: number;
  "rate-type"?: string;
  "effective-date"?: Date;
  "end-date"?: Date;
  "usage-level"?: number;
  "usage-credit"?: number;
  "plan-url"?: string;
  "efl-link"?: string;
  "tos-link"?: string;
  "yrac-link"?: string;
  "energy-type"?: string;
  promotion?: string;
  "selling-points"?: string[];
  "additional-data"?: any;
  tags?: string[];

  language?: string;
  rating?: string;
  "cancel-fee"?: string;
  renewable?: string;
  "special-terms"?: string;
  "facts-url"?: string;
  "enroll-url"?: string;
  "enroll-phone"?: string;
  "new-customer"?: string;
  "min-usage-fees-credits"?: string;
  "source-created-date"?: string;

  metadata?: {
    ingested: number;
    source: Source;
    "enroll-method": EnrollMethod;
    "ingested-date": string;
  };
}

export const planInfoKeys: { [K in keyof PricingSheet]?: string[] } = {
  "efl-link": ["efl-link", "_eflFileName", "FactsURL"],
  getxid: ["GETXid", "getxid"],
  xid: ["xid", "XID", "idKey", "PrimaryKey"],
  "end-date": ["endDate", "_endDate"],
  "energy-type": ["energyType"],
  "fixed-tdsp-charge-dollars": ["fixedTDSPChargeDollars", "_tduMonthlyCharge"],
  "monthly-service-fee-dollars": [
    "monthlyServiceFeeDollars",
    "_monthlyChargeOrCredit",
    "Fees/Credits",
  ],
  "plan-code": ["planCode", "_rawProdCode", "ProductCode"],
  "plan-price-id": ["planPriceId", "_rateId"],
  "plan-type": ["planType", "_prodType"],
  "product-name": ["productName", "_productName", "Product"],
  "product-id": ["productId", "_prodId"],
  "product-code": ["productCode", "_productCode"],
  "product-description": ["productDescription", "_desc"],
  promotion: ["promotion", "Promotion"],
  prepaid: ["prepaid", "PrePaid"],
  "time-of-use": ["timeOfUse", "TimeOfUse"],
  "kwh-rate": ["rateDollars", "_energyCharge1"],
  "kwh-1000": ["rateDollars1000", "_kwh1000", "kwh1000"],
  "kwh-2000": ["rateDollars2000", "_kwh2000", "kwh2000"],
  "kwh-500": ["rateDollars500", "_kwh500", "kwh500"],
  retailer: ["retailer", "RepCompany"],
  "retailer-getxid": ["retailerGetxid"],
  tdu: ["TDU_TDSP", "tdu", "TDU", "TduCompanyName"],
  "term-length": ["termLength", "_numMonths", "TermValue"],
  "rate-type": ["rateType", "RateType"],
  "usage-credit": ["usageCredit"],
  "usage-credit-band-lower": [
    "usageCreditBandLower",
    "_firstConditionalCreditChargeStartKwh",
  ],
  "usage-credit-band-upper": [
    "usageCreditBandUpper",
    "_firstConditionalCreditChargeEndKwh",
  ],
  "usage-level": ["usageLevel"],
  "variable-tdsp-charge-dollars": [
    "variableTDSPChargeDollars",
    "_tduKwhCharge",
  ],
  "effective-date": ["effectiveDate", "_created"],
  "selling-points": ["sellingPoints", "string"],
  tags: ["tags", "_bannerClass"],
  "plan-url": ["planUrl", "Website"],
  language: ["language", "Language"],
  rating: ["rating", "Rating"],
  "cancel-fee": ["cancelFee", "CancelFee"],
  renewable: ["renewable", "Renewable"],
  "special-terms": ["specialTerms", "SpecialTerms"],
  "tos-link": ["TOSLink", "TermsURL"],
  "yrac-link": ["YRACLink", "YRACURL"],
  "enroll-url": ["enrollURL", "EnrollURL"],
  "enroll-phone": ["enrollPhone", "EnrollPhone"],
  "new-customer": ["newCustomer", "NewCustomer"],
  "min-usage-fees-credits": ["minUsageFeesCredits", "MinUsageFeesCredits"],
  "source-created-date": ["sourceCreatedDate", "Created"],
};

export interface UsageCredit {
  lower: number;
  upper: number;
  "dollars-credit": number;
}

export interface PlanPricing {
  "kwh-500": number;
  "kwh-1000": number;
  "kwh-2000": number;
  "kwh-rate"?: number;
  "base-fee-monthly"?: number;
  "base-fee-daily"?: number;
  "deposit-info"?: DepositInfo;
}

export interface DepositInfo {
  "deposit-threshold-1": number;
  "deposit-threshold-2": number;
  "deposit-data": {
    [key: string]: number;
  };
}

export const planPricingKeys = {
  "kwh-500": ["kwh500", "Rate In Cts @ 500 kWh"],
  "kwh-1000": ["kwh1000", "Rate In Cts @ 1000 kWh"],
  "kwh-2000": ["kwh2000", "Rate In Cts @ 2000 kWh"],
  "kwh-rate": ["kwhRate", "Energy Unit Rate $/kWh"],
  "base-fee-monthly": "baseFeeMonthly",
  "base-fee-daily": "baseFeeDaily",
};

export interface TduPricing {
  getxid: string;
  tdu: string;
  "tdu-getxid": string;
  "effective-date": Date;
  "end-date": Date | undefined;
  "dollars-per-kwh": number;
  "fixed-charge-dollars": number;
  data?: any;
}

export const TDUPricingKeys = {
  "dollars-per-kwh": ["Variable TDSP Rate", "Variable TDSP Charge"],
  "fixed-charge-dollars": ["Fixed TDSP Charge", "Fixed TDSP Rate"],
};

import { Pane } from "@/components/Pane";
import { useEffect, useState } from "react";
import Api from "@/utils/api";
import { Button } from "@/components/ui/button";
import { formatRelative } from "date-fns";
export const RecentSearches = () => {
  const [searches, setSearches] = useState<any[]>([]);

  const handleGetSearches = () => {
    Api.auth
      .getRecentSearches(new Date().valueOf() - 30 * 24 * 60 * 60 * 1000)
      .then(setSearches);
  };

  useEffect(() => {
    handleGetSearches();
    
    const interval = setInterval(handleGetSearches, 60000); // Poll every minute
    
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <Pane className="flex flex-col gap-1 w-[20%] px-4 items-center justify-center">
      <Button onClick={handleGetSearches}>Refresh</Button>

        {searches.length > 0 ? (
          searches.slice(0, 20).map((search) => (
            <div key={search.id} className="grid grid-cols-[20%_80%] gap-2 w-full">
              <div className="grid sub-grid text-sm text-gray-500 text-left">{search.searchTerm}</div>
              <div className="grid sub-grid text-xs text-gray-300 text-left">
                {formatRelative(new Date(search.createdAt), new Date())}
              </div>
            </div>
          ))
        ) : (
          <div>No recent searches</div>
        )}
    </Pane>
  );
};

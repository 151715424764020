import { cn } from '@/lib/utils';
import React, { useEffect, useState } from 'react';
import { PlanInfo } from '@/types/plan';
import { PlanCard2 } from '@/views/Plans2/components/planCard/PlanCard2';
import Api from "@/utils/api"
import { usePlansStore } from '@/providers/planStore';


const PopularPlans: React.FC = () => {
  const [featuredPlans, setFeaturedPlans] = useState<PlanInfo[]>([]);
  const planStore = usePlansStore();

  useEffect(() => {
    planStore.fetchPlans("75051");
  }, []);

  useEffect(() => {
    let plans = planStore.plans.map(plan => plan.data);
    let sortedByRating = plans.sort((a, b) => parseFloat(b.rating || "0") - parseFloat(a.rating || "0"));
    setFeaturedPlans(sortedByRating.slice(0, 3));
  }, [planStore.plans]);

  return (
    <section className="py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Section Header */}
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Popular Plans in Texas
          </h2>
          <p className="text-lg text-gray-600">
            Compare our most popular electricity plans, each carefully selected to match different needs
          </p>
        </div>

        {/* Plans Grid */}
        <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto mb-16">
          {featuredPlans.map((featured, index) => {
            return (
                <PlanCard2 
                  key={featured.getxid}
                  plan={featured}
                />
            );
          })}
        </div>

        {/* Trust Indicators */}
        <div className="flex justify-center">
          <div className="grid grid-cols-3 divide-x divide-gray-200 text-center">
            {[
              'No Hidden Fees',
              'Licensed by Texas PUC',
              '100% Satisfaction Guarantee'
            ].map((trust) => (
              <div key={trust} className="px-4 lg:px-8 py-4 text-sm font-medium text-gray-600 text-center flex flex-col items-center justify-center">
                {trust}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PopularPlans;
import Page from "@/components/Page";
import { EnrollStep, useEnrollStore } from "@/providers/enrollStore";
import { usePlansStore } from "@/providers/planStore";
import Api from "@/utils/api";
import React, { memo, useCallback } from "react";
import { PlanDetails } from "./components/PlanDetails/PlanDetails";
import { PlanMap3 } from "./components/PlanDetails/PlanMap/PlanMap3";
import { PlanDisplay } from "./components/PlanDisplay";
import { PlanProgress } from "./components/PlanProgress";
import { CompareDrawer } from "./components/ProductCompare/ProductCompare";

export const Plans2: React.FC = memo(() => {
  // // // Destructure only what we need from stores
  const { fetchPlans } = usePlansStore();
  const {
    step,
    locationSpec,
    service_address_zip,
    setEnroll_service_address_zip,
    setEnroll_service_coords,
    setEnroll_step,
  } = useEnrollStore();

  // Memoize the fetch function
  const fetchZipInfo = useCallback(
    async (zip: string) => {
      console.log("Fetching zip info for:", zip);
      const zipInfo = await Api.getZipInfo(zip);
      if (zipInfo?.data?.lat && zipInfo?.data?.lon) {
        setEnroll_service_coords({
          lat: zipInfo.data.lat,
          lng: zipInfo.data.lon,
        });
      }
    },
    [setEnroll_service_coords]
  );

  // Memoize the initialization function
  const initializeLocation = () => {
    console.log("INSIDE INITIALIZE LOCATION", service_address_zip);

    if (service_address_zip && locationSpec !== "zip") {
      console.log("Setting location spec to zip");
    }

    if (service_address_zip && service_address_zip !== service_address_zip) {
      console.log("Setting service address zip to:", service_address_zip);
      setEnroll_service_address_zip(service_address_zip);
      fetchPlans(service_address_zip);
    }

    if (service_address_zip && step !== EnrollStep.PLAN_SELECTION) {
      console.log("Setting enroll step to PLAN_SELECTION");
      setEnroll_step(EnrollStep.PLAN_SELECTION);
    }
  };

  // Use useEffect with memoized callback
  React.useEffect(() => {
    console.log("Plans2 useEffect initializing location");
    initializeLocation();
  }, []);

  return (
    <Page className="mt-12">
      <PlanProgress />
      <PlanMap3 zipCodes={[service_address_zip]} />

      <PlanDisplay />
      <PlanDetails />
      <CompareDrawer />
    </Page>
  );
});

Plans2.displayName = "Plans2";

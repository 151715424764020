// src/main.tsx
import initializeSentry from "@/helpers/sentry";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { ThemeProvider } from "./theme/ThemeContext";
import { v4 as uuidv4 } from "uuid";
import { AuthProvider } from "@/providers/authProvider";

initializeSentry();
export const mapId = uuidv4();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ThemeProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);

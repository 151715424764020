import { RouteObject } from "react-router-dom";
import Enrollment from "./Enrollment/Enrollment";
import Landing from "./Landing/Landing";
import Home from "./Home/HomePage";
import LocalizedView from "./Localized/Localized";
import News from "./News/News";
import AuthPanel from "./Panels/auth/authPanel";
import Profile from "./Panels/auth/profile";
import { Plans2 } from "./Plans2/Plans2";
import StateView from "./State/StateView";
import ProvidersPage from "./Providers/ProvidersPage";


const viewsRoutes: RouteObject[] = [
  {
    path: "plans",
    element: <Plans2 />,
  },
  {
    path: "enrollment",
    element: <Enrollment />,
  },
];

const routes: RouteObject[] = [
  {
    path: "/",
    element: <Home />,
    children: viewsRoutes,
  },
  {
    path: ":state",
    element: <StateView />,
    children: [
      {
        path: ":localeOrService",
        element: <LocalizedView />,
        children: [
          {
            path: ":localeOrService",
            element: <LocalizedView />,
            children: viewsRoutes,
          },
          ...viewsRoutes,
        ],
      },
      ...viewsRoutes,
    ],
  },
  {
    path: "news",
    element: <News />,
  },
  {
    path: "/auth",
    element: <AuthPanel />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/providers",
    element: <ProvidersPage />,
  },
];

export default routes;

import MainLayout from "@/layouts/mainLayout";
import { protectedRoutes } from "@/views-auth/routes";
import routes from "@/views/routes";
import * as React from "react";
import { createBrowserRouter } from "react-router-dom";

export interface RouteParams {
  element?: JSX.Element;
  label?: string;
  path?: string;
  index?: boolean;
  isProtected?: boolean;
  loader?: ({ params }: { params: { zipCode: string } }) => Promise<any>;
  children?: RouteParams[];
  id?: string;
  icon?: React.ElementType;
}

export const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: routes.map(({ path, element, children }) => ({
      path,
      element: element,
      children: children?.map(({ path, element }) => ({
        path,
        element: element,
        children: children?.map(({ path, element }) => ({
          path,
          element: element,
        })),
      })),
    })),
  },

  ...protectedRoutes,
]);

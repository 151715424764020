import React from 'react';
import { Home, ArrowRight } from 'lucide-react';
import { useGuide } from '../GuideContext';
import { cn } from '../../../lib/utils';

const ServiceTypeStep: React.FC = () => {
  const { state, dispatch } = useGuide();

  const options = [
    {
      id: 'moving',
      title: 'Moving to a New Address',
      description: 'Set up electricity service for your new home',
      icon: Home
    },
    {
      id: 'switching',
      title: 'Switching Providers',
      description: 'Find a better plan for your current address',
      icon: ArrowRight
    }
  ];

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-xl font-semibold text-gray-900">
          I need electricity for...
        </h2>
        <p className="mt-1 text-sm text-gray-500">
          Let's find the right plan for your situation
        </p>
      </div>

      <div className="grid grid-cols-2 gap-4">
        {options.map((option) => {
          const Icon = option.icon;
          return (
            <button
              key={option.id}
              onClick={() => dispatch({ 
                type: 'SET_SERVICE_TYPE', 
                payload: option.id as 'moving' | 'switching'
              })}
              className={cn(
                "p-6 rounded-xl border-2 text-left transition-all",
                state.serviceType === option.id
                  ? "border-blue-500 bg-blue-50 ring-2 ring-blue-200 ring-opacity-50"
                  : "border-gray-200 hover:border-blue-200 hover:bg-gray-50"
              )}
            >
              <Icon className={cn(
                "w-8 h-8 mb-4",
                state.serviceType === option.id ? "text-blue-500" : "text-gray-400"
              )} />
              <div className="space-y-2">
                <div className="font-medium text-gray-900">{option.title}</div>
                <div className="text-sm text-gray-500">{option.description}</div>
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default ServiceTypeStep;
import { BaseMap } from "./BaseMap";
import { useEffect, useState } from "react";
import { Marker } from "@vis.gl/react-google-maps";


export const MarkerMap: React.FC<{
  markers: {
    lat: number;
    lng: number;
  }[];
  position?: { lat: number; lng: number; zoom?: number };
}> = ({ markers, position }) => {
  console.log(markers);
  return (
    <BaseMap position={position}>
      {markers.map((marker, index) => (
        <Marker key={index} position={marker} />
      ))}
    </BaseMap>
  );
};

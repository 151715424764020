import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { Input } from "@/components/ui/input";
import { Slider } from "@/components/ui/slider";
import Api from "@/utils/api";
import { useEffect, useState } from "react";
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts";

const EvSavings = () => {
  const [gasPrice, setGasPrice] = useState(3.5);
  const [kwhPrice, setKwhPrice] = useState(0.14);
  const [evMilesPerYear, setEvMilesPerYear] = useState(6000);
  const [zipCode, setZipCode] = useState("75051");
  const [gasBuddyData, setGasBuddyData] = useState<any[]>([]);

  useEffect(() => {
    const fetchGasBuddyPrices = async () => {
      const response = await Api.getGasBuddyPrices(zipCode);
      if (response) {
        setGasBuddyData(response);
      }
    };
    
    fetchGasBuddyPrices();
  }, []);
  // Constants for calculations
  const GAS_CAR_MPG = 25; // Average gas car efficiency
  const EV_MILES_PER_KWH = 3.5; // Average EV efficiency

  // Calculate yearly costs based on inputs
  const calculateYearlyCosts = () => {
    const years = Array.from({ length: 6 }, (_, i) => i); // 0 to 5 years

    return years.map((year) => {
      const totalMiles = evMilesPerYear * year;

      // Gas car cost calculation
      const gallonsUsed = totalMiles / GAS_CAR_MPG;
      const gasCost = gallonsUsed * gasPrice;

      // EV cost calculation
      const kwhUsed = totalMiles / EV_MILES_PER_KWH;
      const evCost = kwhUsed * kwhPrice;

      return {
        year,
        gasCarCost: Math.round(gasCost),
        evCost: Math.round(evCost),
      };
    });
  };

  // Generate data whenever inputs change
  const data = calculateYearlyCosts();

  const chartConfig = {
    gasCarCost: { label: "Gas Car Cost", color: "orange" },
    evCost: { label: "EV Cost", color: "green" },
  } satisfies ChartConfig;

  const description = "A line chart with dots";

  return (
    <div className="flex flex-col items-center justify-center border-2 border-stone-500 rounded-lg p-4">
      <h2 className="text-xl font-bold mb-4">EV vs Gas Cost Calculator</h2>
      <pre>{JSON.stringify(gasBuddyData, null, 2)}</pre>
      <div className="w-full mb-4">
        <label>Miles per Year</label>
        <Slider
          value={[evMilesPerYear]}
          onValueChange={(value) => setEvMilesPerYear(value[0])}
          min={0}
          max={20000}
          step={100}
        />
        <Input
          value={evMilesPerYear}
          onChange={(e) => setEvMilesPerYear(Number(e.target.value))}
        />
      </div>

      <div className="w-full mb-4">
        <label>Gas Price ($/gallon)</label>
        <Slider
          value={[gasPrice]}
          onValueChange={(value) => setGasPrice(value[0])}
          min={0}
          max={10}
          step={0.1}
        />
        <Input
          value={gasPrice}
          onChange={(e) => setGasPrice(Number(e.target.value))}
        />
      </div>

      <div className="w-full mb-4">
        <label>Electricity Rate ($/kWh)</label>
        <Slider
          value={[kwhPrice]}
          onValueChange={(value) => setKwhPrice(value[0])}
          min={0}
          max={1}
          step={0.01}
        />
        <Input
          value={kwhPrice}
          onChange={(e) => setKwhPrice(Number(e.target.value))}
        />
      </div>
      <p>{description}</p>
      <div className="w-[600px] h-[400px]">
        <ChartContainer config={chartConfig}>
          <LineChart
            width={600}
            height={400}
            data={data}
            margin={{
              top: 20,
              bottom: 20,
              left: 32,
              right: 12,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="year"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickFormatter={(value) => `$${value}`}
            />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <Line
              dataKey="gasCarCost"
              type="monotone"
              stroke={chartConfig.gasCarCost.color}
              strokeWidth={2}
              dot
            />
            <Line
              dataKey="evCost"
              type="monotone"
              stroke={chartConfig.evCost.color}
              strokeWidth={2}
              dot
            />
          </LineChart>
        </ChartContainer>
      </div>
    </div>
  );
};

export default EvSavings;

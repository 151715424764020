import { create } from "zustand";
import Api from "@/utils/api";
import { toast } from "sonner";
import { SafeUser } from "@/types/auth";

interface AuthState {
  isAuthenticated: boolean;
  user: SafeUser | null;
  token: string | null;
  loading: boolean;
}

interface AuthActions {
  login: (
    username: string,
    password: string
  ) => Promise<{
    valid: boolean;
    redirect: string;
  }>;
  register: (
    username: string,
    password: string
  ) => Promise<{
    valid: boolean;
    redirect: string;
  }>;
  logout: () => Promise<void>;
  checkAuth: () => Promise<void>;
  addMetadata: (metadata: Record<string, any>) => void;
}

export const useAuthStore = create<AuthState & AuthActions>((set, get) => ({
  // Initial State
  isAuthenticated: true,
  user: null,
  token: localStorage.getItem("authToken"),
  loading: false,

  // Actions
  login: async (username: string, password: string) => {
    try {
      const result = await Api.auth.login(username, password);
      if (result.valid) {
        set({
          isAuthenticated: true,
          user: result.user,
          token: result.token,
        });
        localStorage.setItem("authToken", result.token);
        toast.success("Logged in successfully");
      }
      return result;
    } catch (error) {
      toast.error("Login failed");
      return { valid: false, redirect: "/auth" };
    }
  },

  register: async (username: string, password: string) => {
    try {
      const result = await Api.auth.register(username, password);
      if (result.valid) {
        set({
          isAuthenticated: true,
          user: result.user,
          token: result.token,
        });
        localStorage.setItem("authToken", result.token);
        toast.success("Registered successfully");
      }
      return result;
    } catch (error) {
      toast.error("Registration failed");
      return { valid: false, redirect: "/auth" };
    }
  },

  logout: async () => {
    try {
      const success = await Api.auth.logout();
      if (success) {
        set({
          isAuthenticated: false,
          user: null,
          token: null,
        });
        localStorage.removeItem("authToken");
        toast.success("Logged out successfully");
      }
    } catch (error) {
      toast.error("Logout failed");
    }
  },

  checkAuth: async () => {
    try {
      set({ loading: true });
      const token = localStorage.getItem("authToken");

      const userData = await Api.auth.checkToken();
      if (userData) {
        set({
          isAuthenticated: true,
          user: userData,
          token,
          loading: false,
        });
      } else {
        localStorage.removeItem("authToken");
        set({
          isAuthenticated: false,
          user: null,
          token: null,
          loading: false,
        });
      }
    } catch (error) {
      localStorage.removeItem("authToken");
      set({
        isAuthenticated: false,
        user: null,
        token: null,
        loading: false,
      });
    }
  },
  addMetadata: (metadata: Record<string, any>) => {
    if (get().user) {
      set((state) => ({
        user: { ...state.user, metadata } as SafeUser,
      }));
      if (get().user?.userId) {
        const userId = get().user?.userId;
        if (userId) {
          Api.updateMetadata(userId, metadata);
        }
      }
    }
  },
}));

import { CircleCheck } from "lucide-react";
import { PlanBadges } from "./components/PlanBadges";
import {
  Award,
  ThumbsUp,
  Zap,
  Leaf,
  Moon,
  Sun,
  Trophy,
  ClockAlert,
  CircleDollarSign,
  BookOpenCheck,
  CalendarDays,
} from "lucide-react";
import { PlanInfo } from "@/types/plan";
export const PlanCardContent = ({ plan }: { plan: PlanInfo }) => {
  // return (
  //   <div className="grid grid-rows-2 gap-4 h-full">
  //     <PlanBadges plan={plan} />
  //     {plan["ai-analysis"]?.sellingPoints?.length > 0 && (
  //       <div className="flex flex-col items-left justify-start gap-1 text-sm">
  //         {plan["ai-analysis"]?.sellingPoints?.slice(0, 4).map((point:string, index:number) => (
  //           <div key={index} className="inline-flex items-center gap-4">
  //             <CircleCheck className="w-4 h-4 text-green-700 flex-shrink-0 self-start" />
  //             <span className="text-xs text-gray-500 leading-tight">{point}</span>
  //           </div>
  //         ))}
  //       </div>
  //     )}
  //   </div>
  // );

  return (
    <div className="w-full h-full flex flex-col items-center justify-between px-2 pb-4 overflow-hidden">
      <div className="w-full flex flex-row flex-wrap items-center justify-start gap-1 px-2">
        <PlanBadges plan={plan} />
      </div>
      {plan["usage-credit"] &&
        plan["usage-credit"]?.length > 0 &&
        plan["usage-credit"][0]["dollars-credit"] > 0 && (
          <div className="w-full flex flex-col items-center justify-center gap-2 bg-green-50 text-green-800 border-green-200 hover:bg-green-100">
            <div className="text-xs font-medium">
              ${plan["usage-credit"][0]["dollars-credit"]} bill credit
            </div>
            <span>at {plan["usage-credit"][0]["lower"]}+ kWh</span>
          </div>
        )}
      {plan["ai-analysis"]?.sellingPoints?.length > 0 && (
        <div className="flex flex-col items-left justify-start gap-1 text-sm border-1 border-gray-50 rounded-lg p-2">
          {plan["ai-analysis"]?.sellingPoints
            ?.slice(0, 4)
            .map((point: string, index: number) => (
              <div key={index} className="inline-flex items-center gap-4">
                <CircleCheck className="w-4 h-4 text-green-700 flex-shrink-0 self-start" />
                <span className="text-xs text-gray-500 leading-tight">
                  {point}
                </span>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

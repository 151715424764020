import React from 'react';
import { Calendar } from "lucide-react";
import DatePicker from "@/components/individual/DatePicker";
import { cn } from "@/lib/utils";
import { useEnrollStore } from "@/providers/enrollStore";
const ContractEndStep: React.FC = () => {
  const { current_contract_end_date, setEnroll_current_contract_end_date } = useEnrollStore();

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-xl font-semibold text-gray-900">
          When does your current contract end?
        </h2>
        <p className="mt-1 text-sm text-gray-500">
          This helps us recommend the best time to switch
        </p>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <button
          onClick={() => {
            setEnroll_current_contract_end_date(null);
          }}
          className={cn(
            "p-6 rounded-xl border-2 text-left transition-all",
            !current_contract_end_date
              ? "border-blue-500 bg-blue-50 ring-2 ring-blue-200 ring-opacity-50"
              : "border-gray-200 hover:border-blue-200 hover:bg-gray-50"
          )}
        >
          <div className="space-y-2">
            <div className="font-medium text-gray-900">I'm Not Sure</div>
            <div className="text-sm text-gray-500">
              Show me all available plans
            </div>
          </div>
        </button>

        <div className={cn(
          "p-6 rounded-xl border-2 transition-all",
          current_contract_end_date
            ? "border-blue-500 bg-blue-50 ring-2 ring-blue-200 ring-opacity-50"
            : "border-gray-200"
        )}>
          <div className="space-y-4">
            <div>
              <div className="font-medium text-gray-900">I Know the Date</div>
              <div className="text-sm text-gray-500">Select contract end date</div>
            </div>
            <DatePicker
              date={current_contract_end_date || null}
              setDate={(date: Date) => {
                setEnroll_current_contract_end_date(date);
              }}
              disabled={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractEndStep;
import { Button } from "@/components/ui/button";
import { useAuthStore } from "@/providers/authStore";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

const LoginButton: React.FC<{ username?: string; password?: string }> = ({
  username,
  password,
}) => {
  const { login } = useAuthStore();
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!username || !password) {
      navigate("/auth");
      return;
    }

    try {
      const result = await login(username, password);
      if (result.valid) {
        navigate(result.redirect || "/dashboard");
      } else {
        toast.error("Invalid credentials");
        navigate("/auth");
      }
    } catch (error) {
      toast.error("Login failed");
      navigate("/auth");
    }
  };

  return <Button onClick={handleLogin}>Log In</Button>;
};

export default LoginButton;
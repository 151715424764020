import React, { useEffect, useRef, useState } from "react";
import { useLoadScript } from "@react-google-maps/api";
import { config } from "@/config";
import { Library } from "@googlemaps/js-api-loader";
import { useEnrollStore } from "@/providers/enrollStore";
import { useSessionStore } from "@/providers/store";
import { LatLngLiteral } from "leaflet";
import "./addressAutocomplete.css";
import Api from "@/utils/api";

interface AddressAutocompleteProps {
  onSelectAddress: (address: string) => void;
  placeholder?: string;
}

const libraries = ["places"];

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({
  onSelectAddress,
  placeholder,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [inputValue, setInputValue] = useState("");
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: config.GOOGLE_MAPS_API_KEY,
    libraries: libraries as Library[],
  });

  const {
    setEnroll_service_address,
    setEnroll_service_coords,
    setEnroll_service_address_city,
    setEnroll_service_address_state,
    setEnroll_service_address_zip,
    setEnroll_service_address_line1,
    setEnroll_service_address_esiId,
    setEnroll_service_address_place,
    updateLocation,
    setLocationSpec,
  } = useEnrollStore();

  useEffect(() => {
    if (!isLoaded || !inputRef.current) return;

    const autocomplete = new google.maps.places.Autocomplete(inputRef.current, {
      fields: ["formatted_address", "geometry", "address_components"],
      componentRestrictions: { country: "us" },
    });

    const handlePlaceSelect = () => {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        setInputValue(place.formatted_address || "");
        processPlace(place);
      }
    };

    const processPlace = (place: google.maps.places.PlaceResult) => {
      const { formatted_address, geometry, address_components } = place;

      const address = formatted_address;
      const coords = {
        lat: geometry?.location?.lat(),
        lng: geometry?.location?.lng(),
      };

      let streetNumber = "";
      let streetName = "";
      let city = "";
      let state = "";
      let zip = "";

      address_components?.forEach((component) => {
        const types = component.types;
        if (types.includes("locality")) {
          city = component.long_name;
        }
        if (types.includes("administrative_area_level_1")) {
          state = component.short_name;
        }
        if (types.includes("postal_code")) {
          zip = component.long_name;
        }
        if (types.includes("street_number")) {
          console.log("STREET NUMBER", component.long_name);
          streetNumber = component.long_name;
        }
        if (types.includes("route")) {
          console.log("STREET NAME", component.long_name);
          streetName = component.long_name;
        }
      });

      const addressLine1 = streetNumber && streetName ? `${streetNumber} ${streetName}` : "";

      updateLocation({
        service_address_line1: addressLine1,
        service_address_line2: "",
        service_address_city: city,
        service_address_state: state,
        service_address_zip: zip,
        service_address_place: place,
        service_coords: coords as LatLngLiteral,
      });

      if (addressLine1) {
        setLocationSpec("address");
        Api.findEsiId({
          rawAddress: address || "",
          addressLine: addressLine1,
          city,
          state,
          zip,
          coords: coords as LatLngLiteral,
        }).then((esiId) => {
          setEnroll_service_address_esiId(esiId);
        });
      } else {
        zip && setLocationSpec("zip");
      }

      if (zip) {
        onSelectAddress(zip);
      }
    };

    autocomplete.addListener("place_changed", handlePlaceSelect);

    return () => {
      google.maps.event.clearInstanceListeners(autocomplete);
    };
  }, [isLoaded]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    if (value === "") {
      onSelectAddress("");
    }
  };

  return (
    <input
      ref={inputRef}
      type="text"
      placeholder={placeholder || "Enter your address"}
      className="grow w-full outline-none bg-inherit mx-6 rounded-lg"
      onChange={handleInputChange}
      value={inputValue}
    />
  );
};

export default AddressAutocomplete;

import { Polygon } from "./Polygon";
import { BaseMap } from "./BaseMap";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import Api from "@/utils/api";
import { areArraysEqual } from "@/utils/arrayUtils";
import { useEnrollStore } from "@/providers/enrollStore";

interface PolygonData {
  paths: { lat: number; lng: number }[];
  style: {
    strokeColor: string;
    strokeWeight: number;
    fillColor: string;
  };
}

export const ZipMap: React.FC<{
  zipCodes: string[];
  position?: { lat: number; lng: number };
}> = React.memo(
  ({ zipCodes, position }) => {
    const { data: polygons } = useQuery({
      queryKey: ["zipPolygons", zipCodes.sort().join(",")],
      queryFn: () => Api.getMapPolygons(zipCodes),
      enabled: zipCodes.length > 0,
      staleTime: 1000 * 60 * 60,
      gcTime: 1000 * 60 * 60 * 24,
    });

    return (
      <>
        <BaseMap position={position}>
          {polygons &&
            polygons.map((polygon: PolygonData, index: number) => (
              <Polygon
                key={`${zipCodes[index]}-${index}`}
                paths={polygon.paths}
                strokeColor={polygon.style.strokeColor}
                strokeWeight={polygon.style.strokeWeight}
                fillColor={polygon.style.fillColor}
              />
            ))}
        </BaseMap>
      </>
    );
  },
  (prevProps, nextProps) =>
    areArraysEqual(prevProps.zipCodes, nextProps.zipCodes)
);

ZipMap.displayName = "ZipMap";

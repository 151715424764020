import React, { useEffect, useState } from "react";
import { cn } from "@/lib/utils";
import { Retailer } from "@/types/plan";
import Api from "@/utils/api";
import Marquee from "react-fast-marquee";
import styles from "./IconScroll.module.css";
import { useRetailerStore } from "@/providers/retailerStore";

const ProviderScroll: React.FC = () => {
  const [images, setImages] = useState<string[]>([]);
  const { getRetailers, retailers } = useRetailerStore();

  useEffect(() => {
    const fetchImages = async () => {
      try {
        getRetailers();
        const logos = retailers
          .map((retailer) => retailer.logo)
          .filter((logo) => logo !== undefined) as string[];
        setImages(Array(4).fill(logos).flat());
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };
    fetchImages();
  }, []);
  return (
    <section className="my-8 lg:my-12 overflow-hidden">
      <div className="p-4 sm:px-6 lg:px-8 bg-white ">
        <h2 className="text-center text-2xl font-bold text-gray-900 mb-8 sm:mb-12">
          TRUSTED PROVIDERS YOU CAN COUNT ON
        </h2>

        {/* Scrolling Container */}
        <div className="relative w-full">
          {/* Gradient Overlays */}
          <div className="absolute left-0 top-0 bottom-0 w-32 bg-gradient-to-r from-white to-transparent z-10" />
          <div className="absolute right-0 top-0 bottom-0 w-32 bg-gradient-to-l from-white to-transparent z-10" />

          {/* Scrolling Content */}
          <div className={cn("overflow-hidden relative w-full min-w-100vw")}>
            <Marquee>
              {images.length > 0
                ? images.map((image, index) => (
                    <div key={index} className={styles.imageWrapper}>
                      {image && <img src={image} alt={image} />}
                    </div>
                  ))
                : null}
            </Marquee>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProviderScroll;

import { RSSFeed_T } from "@/types/rss";
import { cn } from "@/lib/utils";
import { motion } from "framer-motion";
import { AddNews } from "./AddNews";
import { Button } from "@/components/ui/button";
import { Plus, Loader2 } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useState } from "react";
import { formatDistanceToNow } from "date-fns";

export const NewsHeader: React.FC<{
  sectionKey: string | undefined;
  setSectionKey: (section: string | undefined) => void;
  feeds: RSSFeed_T[];
  getNews: () => void;
  isLoading: boolean;
  lastFetch: Date | null;
}> = ({ sectionKey, setSectionKey, feeds, getNews, isLoading, lastFetch }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 pb-5 sm:pb-0 w-full flex flex-col gap-2">
      <div className="flex flex-row justify-between">
        <h3 className="text-4xl font-semibold text-gray-900 cursor-pointer">
          Energy News Room
        </h3>
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button variant="default" size="icon">
              <Plus />
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <AddNews
              onSuccess={() => {
                setOpen(false);
                getNews();
              }}
            />
          </PopoverContent>
        </Popover>
      </div>
      <div className="mt-2">
        {isLoading ? (
          <p className="text-xs text-muted-foreground flex flex-row items-center gap-2">
            <Loader2 className="animate-spin" />
          </p>
        ) : lastFetch ? (
          <p className="text-xs text-muted-foreground flex flex-row items-center gap-2">
            Last fetched: {formatDistanceToNow(lastFetch)} ago
          </p>
        ) : null}
      </div>
      <div className="mt-3 sm:mt-4">
        <div className="sm:hidden">
          <label htmlFor="current-tab" className="sr-only">
            Select a tab
          </label>
          <select
            id="current-tab"
            name="current-tab"
            value={feeds.find((tab) => tab.key === sectionKey)?.title}
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            onChange={(e) => {
              const selectedTab = feeds.find(
                (tab) => tab.title === e.target.value
              );
              if (selectedTab) {
                setSectionKey(selectedTab.key);
              }
            }}
          >
            {feeds.map((tab) => (
              <option key={tab.title}>{tab.title}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="-mb-px flex space-x-8">
            {feeds.map((tab) => (
              <motion.button
                key={tab.title}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setSectionKey(tab.key)}
                className={cn(
                  "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium",
                  tab.key === sectionKey
                    ? "border-indigo-500 text-indigo-600 text-lg"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                )}
              >
                {tab.title}
              </motion.button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

import { create } from "zustand";
import { v4 as uuidv4 } from "uuid";


export interface Expense {
  id: string;
  label: string;
  key: string;
  amount: number;
  timeValue: "monthly" | "yearly";
}

function makeNewExpense(expense: Partial<Expense>): Expense {
  return {
    id: uuidv4(),
    label: expense.label || "expense",
    key: expense.key || "expense",
    amount: expense.amount || 0,
    timeValue: expense.timeValue || "monthly",
  };
}

interface BudgetValues {
  budget: number;
  totalExpenses: number;
  expenses: Expense[];
}

interface BudgetActions {
  setBudget: (budget: number) => void;
  setTotalExpenses: (totalExpenses: number) => void;
  setExpenses: (expenses: Expense[]) => void;
  addExpense: (expense: Partial<Expense>) => void;
  updateExpense: (expense: Expense) => void;
  deleteExpense: (id: string) => void;
}

interface BudgetStore extends BudgetValues, BudgetActions {}

const calculateTotalExpenses = (expenses: Expense[]): number => {
  return expenses.reduce((total, expense) => {
    const amount =
      expense.timeValue === "yearly" ? expense.amount / 12 : expense.amount;
    return total + amount;
  }, 0);
};

export const useBudgetStore = create<BudgetStore>((set) => ({
  budget: 0,
  setBudget: (budget) => set({ budget }),
  totalExpenses: 0,
  setTotalExpenses: (totalExpenses) => set({ totalExpenses }),
  expenses: [],
  setExpenses: (expenses) =>
    set({
      expenses,
      totalExpenses: calculateTotalExpenses(expenses),
    }),
  addExpense: (expense) =>
    set((state) => {
      const newExpenses = [...state.expenses, makeNewExpense(expense)];
      return {
        expenses: newExpenses,
        totalExpenses: calculateTotalExpenses(newExpenses),
      };
    }),
  updateExpense: (expense) =>
    set((state) => {
      const newExpenses = state.expenses.map((e) =>
        e.id === expense.id ? expense : e
      );
      return {
        expenses: newExpenses,
        totalExpenses: calculateTotalExpenses(newExpenses),
      };
    }),
  deleteExpense: (id) =>
    set((state) => {
      const newExpenses = state.expenses.filter((e) => e.id !== id);
      return {
        expenses: newExpenses,
        totalExpenses: calculateTotalExpenses(newExpenses),
      };
    }),
}));

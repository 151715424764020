import CryptoJS from 'crypto-js';
import { config } from '@/config';

const secretKey = config.ENCODING_SECRET;
if (!secretKey) throw new Error('ENCODING_SECRET is not defined');

// Function to generate a random IV
const generateIV = () => {
  return CryptoJS.lib.WordArray.random(16).toString(CryptoJS.enc.Hex);
};

// Function to encrypt the data
export const encrypt = (data: string): string => {
  const iv = generateIV();
  const encrypted = CryptoJS.AES.encrypt(
    data,
    CryptoJS.enc.Utf8.parse(secretKey),  // Use Utf8 parsing instead of Hex
    {
      iv: CryptoJS.enc.Hex.parse(iv),
    }
  ).toString();
  return iv + encrypted; // Prepend IV to the ciphertext
};

// Function to decrypt the data
export const decrypt = (ciphertext: string): string => {
  const iv = ciphertext.slice(0, 32); // Extract IV (first 32 hex characters)
  const encrypted = ciphertext.slice(32); // Extract the actual ciphertext
  const decrypted = CryptoJS.AES.decrypt(
    encrypted,
    CryptoJS.enc.Utf8.parse(secretKey),  // Use Utf8 parsing instead of Hex
    {
      iv: CryptoJS.enc.Hex.parse(iv),
    }
  );
  return decrypted.toString(CryptoJS.enc.Utf8);
};

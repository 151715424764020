import { Button } from "@/components/ui/button";
import WidgetShell from "../../components/widgetShell";
import BudgetItem from "./budgetItem";
import { useBudgetStore } from "@/providers/budgetStore";





const Budget = () => {
  const { expenses, totalExpenses } = useBudgetStore();
  return (
    <WidgetShell>
      <div>Budget</div>
      <div>
        <Button>Add Expense</Button>
      </div>
      {expenses.map((expense) => (
        <BudgetItem key={expense.id} expense={expense} />
      ))}
    </WidgetShell>
  );
};

export default Budget;

import { PlanInfo } from "@/types/plan";
import { PlanCard } from "./planCard/PlanCard";
import { PlanCard2 } from "./planCard/PlanCard2";
import { usePlansStore } from "@/providers/planStore";

export const PlansGrid = () => {
  const planStore = usePlansStore();
  const plans = planStore.filteredPlans.map((plan) => plan.data);
  const plansWithScores = plans.map((plan) => ({
    ...plan,
    valueScore: 0,
    featuresScore: 0,
    flexibilityScore:0,
    puctNumber: 0,
  }));

  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-4">
      {planStore.filteredPlans.map((plan) => plan.data).map((plan: PlanInfo) => (
        <PlanCard2
          key={plan.getxid}
          plan={plan}
          className="col-span-1"
        />
      ))}
    </div>
  );
};

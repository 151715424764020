import { Expense } from "@/providers/budgetStore";




const BudgetItem: React.FC<{ expense: Expense }> = ({ expense }) => {
  return (
    <div className="flex flex-row items-center justify-between gap-4 w-full">
      <div>{expense.label}</div>
      <div>{expense.amount.toLocaleString()}</div>
    </div>
  );
};

export default BudgetItem;

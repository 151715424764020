import { RenderArray } from "./renderArray";
import { RenderContent } from "./renderContent";
import { RenderElement } from "./renderElement";
import React, { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import * as C from "@/components/ui/collapsible";
import { Plus, Minus } from "lucide-react";
export const RenderObject: React.FC<{
  obj: {
    [key: string]: string | null;
  };
}> = ({ obj }) => {
  // Use addFlag when needed, e.g., if you encounter a table:
  // if (someCondition) addFlag("db");

  if (!obj) {
    return null;
  }
  if (Object.keys(obj).length === 0) {
    return null;
  }

  if (obj.table) {
    console.log("table", obj.table);
  }

  function renderObjectValue(key: string, value: any) {
    if (!Array.isArray(value) && typeof value !== "object") {
      return <RenderElement element={value} />;
    }
    if (!Array.isArray(value) && typeof value === "object") {
      return <RenderObject obj={value} />;
    }
    return <RenderArray arr={value} />;
  }

  return (
    <div key={uuidv4()} className="w-full flex flex-col">
      {obj.title && (
        <RenderElement id="title" element={obj.title} type="title" />
      )}
      {obj.text && <RenderElement id="text" element={obj.text} />}
      {obj.content && <RenderContent content={obj.content} />}
      {Object.entries(obj).map(
        ([key, value]) =>
          key !== "title" &&
          key !== "text" &&
          key !== "content" &&
          key !== "type" &&
          key !== "table" && (
            <div key={key} className="flex flex-row gap-2 border border-stone-300 rounded-md p-2 bg-white">
              <RenderElement id="key" element={`${key}: `} className="font-bold"/>
              <div className="ml-8">
                {value && renderObjectValue(key, value)}
              </div>
            </div>
          )
      )}
    </div>
  );
};

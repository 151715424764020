import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "@/components/ui/card";
import LoginButton from "@/components/auth/loginButton";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

import { useState } from "react";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  return (
    <Card className="w-full min-w-[400px] flex flex-col gap-4 shadow-lg text-primary bg-background">
      <CardHeader className="flex flex-col gap-2 text-center">
        <CardTitle className="text-2xl font-bold text-primary">Login</CardTitle>

      </CardHeader>
      <CardContent className="flex flex-col gap-4 text-primary">
        <Label>Email or Username</Label>
        <Input type="email" placeholder="Email or Username" value={username} onChange={(e) => setUsername(e.target.value)} />
        <Label>Password</Label>
        <Input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <LoginButton username={username} password={password} />
      </CardContent>
    </Card>
  );
};

export default Login;

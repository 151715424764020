import React from "react";
import { Shield, Zap, DollarSign, Clock } from "lucide-react";
import { cn } from "@/lib/utils";
import { Pane } from "@/components/Pane";

const Features: React.FC = () => {
  const features = [
    {
      icon: DollarSign,
      title: "Save Money",
      description:
        "Compare rates from top providers to find the best deals on electricity.",
    },
    {
      icon: Shield,
      title: "Trusted Service",
      description: "All providers are licensed and regulated by the Texas PUC.",
    },
    {
      icon: Clock,
      title: "Quick Setup",
      description: "Get your electricity service set up in minutes, not days.",
    },
    {
      icon: Zap,
      title: "Smart Tools",
      description:
        "Use our calculator to estimate costs and find the perfect plan.",
    },
  ];

  return (
    <section className="" aria-labelledby="features-heading">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2
            id="features-heading"
            className="text-3xl font-bold text-gray-900 mb-4"
          >
            Why Choose Us?
          </h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            We make it easy to find and switch to the best electricity plan
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => {
            const Icon = feature.icon;
            return (
              <Pane
                key={feature.title}
                className={cn(
                  "group relative py-4 px-8 rounded-xl text-center",
                  "bg-white border border-gray-100",
                  "hover:shadow-md hover:border-blue-100",
                  "transform transition-all duration-300 hover:-translate-y-1"
                )}
              >
                {/* Icon Container */}
                <div className="grid grid-rows-[25%_auto] h-full w-full gap-4">
                  <div
                    className={cn(
                      "mx-auto inline-flex items-center justify-center",
                      "w-12 h-12 rounded-xl mb-6",
                      "bg-gradient-to-br from-blue-50 to-blue-100",
                      "group-hover:from-blue-100 group-hover:to-blue-200",
                      "transition-colors duration-300 row-span-1"
                    )}
                  >
                    <Icon
                      className={cn(
                        "w-6 h-6 text-blue-600",
                        "transform transition-transform duration-300",
                        "group-hover:scale-110"
                      )}
                      aria-hidden="true"
                    />
                  </div>

                  {/* Content */}
                  <div className="row-start-2 row-span-1">
                    <h3 className="text-xl font-semibold text-gray-900">
                      {feature.title}
                    </h3>
                    <p className="text-gray-600 leading-relaxed">
                      {feature.description}
                    </p>
                  </div>
                </div>

                {/* Decorative Elements */}
                <div
                  className={cn(
                    "absolute inset-0 rounded-xl",
                    "bg-gradient-to-br from-blue-500/0 to-blue-500/0",
                    "group-hover:from-blue-500/5 group-hover:to-blue-500/10",
                    "transition-colors duration-300",
                    "pointer-events-none"
                  )}
                  aria-hidden="true"
                />
              </Pane>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Features;

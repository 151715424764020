import React from 'react';
import { ArrowRight } from 'lucide-react';

const ProviderGuide: React.FC = () => {
  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-900 mb-6">
        Complete Guide to Texas Electricity Providers
      </h2>

      <div className="space-y-4">
        {[
          { title: 'Best Electricity Provider Deals Right Now', href: '/providers/deals' },
          { title: 'A Beginner\'s Guide to Choosing an Electricity Provider', href: '/providers/beginners-guide' },
          { title: 'What Provider Should I Choose?', href: '/providers/how-to-choose' },
          { title: 'How To Switch Electricity Providers', href: '/providers/how-to-switch' },
          { title: 'Pros and Cons of Different Providers', href: '/providers/pros-and-cons' },
          { title: 'How To Get the Most From Your Provider', href: '/providers/maximize-benefits' },
          { title: 'Top Electricity Providers FAQs', href: '/providers/faqs' }
        ].map((link) => (
          <a
            key={link.href}
            href={link.href}
            className="flex items-center justify-between p-4 bg-white rounded-lg border border-gray-200 hover:border-blue-300 hover:shadow-sm transition-all group"
          >
            <span className="text-gray-900 group-hover:text-blue-600">
              {link.title}
            </span>
            <ArrowRight className="w-5 h-5 text-gray-400 group-hover:text-blue-600 transform group-hover:translate-x-1 transition-all" />
          </a>
        ))}
      </div>

      <div className="mt-8">
        <h3 className="text-xl font-semibold text-gray-900 mb-4">
          Best Electricity Provider Deals Right Now
        </h3>
        <p className="text-gray-600 mb-6">
          If you're looking to switch providers this week, you may want to know which providers have the highest-value welcome bonuses and promotions right now. This week, these providers have the best offers by category. Keep in mind that rates and additional benefits will vary, often significantly, so weigh the pros and cons of a particular provider beyond just the promotional offers before you switch.
        </p>
        <p className="text-sm text-gray-500">
          Up-to-date as of {new Date().toLocaleDateString('en-US', { 
            month: 'long',
            day: 'numeric',
            year: 'numeric'
          })}
        </p>
      </div>
    </div>
  );
};

export default ProviderGuide;
import React, { useState } from "react";
import {
  Facebook,
  Instagram,
  Twitter,
  Youtube,
  Mail,
  Shield,
} from "lucide-react";
import { z } from "zod";
import { cn } from "@/lib/utils";
import Api from "@/utils/api";
import { SocialIcon } from "react-social-icons";
import { config } from "@/config";
import { useSessionStore } from "@/providers/store";

const Footer: React.FC = () => {
  const { submitEmail, emailSubmitted } = useSessionStore();
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const showLinks = false;

  const emailSchema = z.string().email();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    try {
      emailSchema.parse(email);
      setIsValid(true);
      submitEmail(email);
    } catch (error) {
      setIsValid(false);
    }
  };

  const getRingColor = () => {
    if (isValid === null) return "ring-gray-300";
    return isValid ? "ring-green-500" : "ring-red-500";
  };

  return (
    <footer className="bg-white border-t border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="w-full grid grid-cols-2 md:grid-cols-4 gap-8">
          {/* Logo & Info */}
          <div className="col-span-2 md:col-span-1 space-y-4 flex flex-col items-center lg:items-start">
            <img
              alt="Get Electricity"
              src="https://storage.googleapis.com/getelectricity-assets/logo.png"
              className="h-16 w-16 lg:h-24 lg:w-24 p-2 lg:p-4 rounded-full bg-inherit lg:border-b-2 lg:border-b-gray-200"
            />
            <div className="space-y-2 flex flex-col items-center lg:items-start">
              <p className="text-sm text-gray-500">
                Compare electricity rates and find the best plan.
              </p>
              <div className="flex items-center gap-2 text-sm text-gray-500">
                <img
                  src="https://storage.googleapis.com/getelectricity-assets/local/StateSVGs/Texas.svg"
                  alt="Texas"
                  width={16}
                  height={16}
                />
                <span>Made in Texas</span>
              </div>
            </div>

            {/* Social Icons */}
            <div className="flex items-center gap-4">
              <a
                href={config.SOCIALS.Facebook}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-blue-600 transition-colors"
                aria-label="Follow us on Facebook"
              >
                <SocialIcon
                  url="https://facebook.com"
                  style={{ height: 25, width: 25 }}
                  fgColor="#FFFFFF"
                  bgColor="#6b7280"
                  as="div"
                />
              </a>
              <a
                href={config.SOCIALS.Instagram}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-pink-600 transition-colors"
                aria-label="Follow us on Instagram"
              >
                <SocialIcon
                  url="https://instagram.com"
                  style={{ height: 25, width: 25 }}
                  fgColor="#FFFFFF"
                  bgColor="#6b7280"
                  as="div"
                />
              </a>
              <a
                href={config.SOCIALS.X}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-blue-400 transition-colors"
                aria-label="Follow us on Twitter"
              >
                <SocialIcon
                  url="https://twitter.com"
                  style={{ height: 25, width: 25 }}
                  fgColor="#FFFFFF"
                  bgColor="#6b7280"
                  as="div"
                />
              </a>
              <a
                href={config.SOCIALS.Youtube}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-red-600 transition-colors"
                aria-label="Subscribe on YouTube"
              >
                <SocialIcon
                  url="https://youtube.com"
                  style={{ height: 25, width: 25 }}
                  fgColor="#FFFFFF"
                  bgColor="#6b7280"
                  as="div"
                />
              </a>
            </div>
          </div>

          {/* Quick Links */}
          <div className={cn({ hidden: !showLinks })}>
            <h3 className="font-semibold text-gray-900 mb-4">Quick Links</h3>
            <ul className="space-y-3">
              <li>
                <a
                  href="#how-it-works"
                  className="text-gray-500 hover:text-gray-900"
                >
                  How It Works
                </a>
              </li>
              <li>
                <a href="#plans" className="text-gray-500 hover:text-gray-900">
                  Plans
                </a>
              </li>
              <li>
                <a
                  href="#providers"
                  className="text-gray-500 hover:text-gray-900"
                >
                  Providers
                </a>
              </li>
            </ul>
          </div>

          {/* Resources */}
          <div className={cn({ hidden: !showLinks })}>
            <h3 className="font-semibold text-gray-900 mb-4">Resources</h3>
            <ul className="space-y-3">
              <li>
                <a href="#faq" className="text-gray-500 hover:text-gray-900">
                  FAQ
                </a>
              </li>
              <li>
                <a
                  href="#support"
                  className="text-gray-500 hover:text-gray-900"
                >
                  Support
                </a>
              </li>
              <li>
                <a
                  href="#contact"
                  className="text-gray-500 hover:text-gray-900"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>

          {/* Newsletter */}
          <div
            className={cn(
              "lg:col-start-4",
              "col-span-2 md:col-span-1 flex flex-col items-center lg:items-start"
            )}
          >
            {!emailSubmitted ? (
              <>
                <h3 className="font-semibold text-gray-900 mb-4">
                  Subscribe to Our Newsletter
                </h3>
                <p className="text-sm text-gray-500 mb-4">
                  Get energy savings tips electric rates in your inbox
                </p>
                <form
                  onSubmit={handleSubmit}
                  className="mt-6 sm:flex sm:max-w-md lg:mt-0"
                >
                  <input
                    id="email-address"
                    name="email-address"
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    autoComplete="email"
                    className={cn(
                      "w-full min-w-0 appearance-none rounded-md border-0 bg-white px-3 py-1.5 text-base text-gray-900 shadow-sm ring-1 ring-inset",
                      getRingColor(),
                      "placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mainBlue sm:w-56 sm:text-sm/6"
                    )}
                  />
                  <div className="mt-4 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
                    <button
                      type="submit"
                      className="flex w-full items-center justify-center rounded-md bg-mainBlue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-mainBlue/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mainBlue"
                    >
                      Subscribe
                    </button>
                  </div>
                </form>
              </>
            ) : (
              <p className="text-sm text-gray-500 mb-4">
                Thank you for subscribing!
              </p>
            )}
          </div>
        </div>

        {/* Broker Information */}
        <div className="mt-12 pt-8 border-t border-gray-200">
          <div className="flex items-start gap-3 mb-4 text-sm text-gray-500 flex-col lg:flex-row items-center lg:items-start text-center lg:text-left">
            <Shield className="w-4 h-4 flex-shrink-0 mt-0.5" />
            <p>
              GetElectricity.com is operated by Get Electricity LLC, which holds
              the Public Utility Commission of Texas (PUCT) broker registration
              number BR230243.
            </p>
          </div>
          <p className="text-sm text-gray-500 text-center">
            © {new Date().getFullYear()} Get Electricity. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

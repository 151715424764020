import React from 'react';
import { Shield } from 'lucide-react';
import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';
import SearchBar2 from '@/components/individual/newSearch';
import { useIsMobile } from "@/hooks/use-mobile";

const CallToAction: React.FC = () => {
  const isMobile = useIsMobile();
  return (
    <section
      className="relative py-24 overflow-hidden"
      aria-labelledby="cta-heading"
    >
      {/* Background */}
      <div className="absolute inset-0 bg-gradient-to-br from-blue-900 via-blue-800 to-blue-900">
        {!isMobile && <div 
          className="absolute inset-0 bg-cover bg-center opacity-10 mix-blend-soft-light"
          style={{ 
            backgroundImage: 'url(https://storage.googleapis.com/getelectricity-assets/images/hero.jpg)'
          }}
          aria-hidden="true"
        />}
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <motion.h2 
            id="cta-heading"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl font-black text-white mb-6 tracking-wide"
          >
            Ready to Find Your Best Power Plan?
          </motion.h2>

          <motion.p 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.1 }}
            className="text-xl text-blue-100 mb-12"
          >
            Enter your zip code to compare rates and find the best plan for your home.
          </motion.p>

          {/* ZIP Input */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="mb-16"
          >
            <SearchBar2 enroll={false} />
          </motion.div>

          {/* Stats - Horizontal Layout */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.3 }}
            className="max-w-2xl mx-auto"
          >
            <div className="grid grid-cols-3 gap-4">
              {[
                { value: '15+', label: 'Trusted Providers' },
                { value: '50k+', label: 'Happy Customers' },
                { value: '$350/yr', label: 'Average Savings' }
              ].map((stat) => (
                <div 
                  key={stat.label}
                  className="flex flex-col items-center bg-white/5 backdrop-blur-sm rounded-xl p-6 transition-all duration-300 hover:bg-white/10"
                >
                  <div className="text-xl sm:text-2xl lg:text-4xl font-black text-white mb-2">
                    {stat.value}
                  </div>
                  <div className="text-sm text-blue-100">
                    {stat.label}
                  </div>
                </div>
              ))}
            </div>
          </motion.div>

          {/* Trust Badge */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.4 }}
            className="mt-12 inline-flex items-center gap-2 px-4 py-2 rounded-full bg-white/10 backdrop-blur-sm"
          >
            <Shield className="w-4 h-4 text-green-400" />
            <span className="text-sm text-white font-medium">
              Licensed and Regulated by the Texas PUC
            </span>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
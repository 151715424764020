import { useAuthStore } from "./authStore";
import { FC, ReactNode, useEffect } from "react";
import Api from "@/utils/api";
import { useNavigate } from "react-router-dom";

export const AuthProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { checkAuth, isAuthenticated } = useAuthStore();
  useEffect(() => {
    checkAuth();
  }, []);
  return (
    <>
      {children}
    </>
  );
};


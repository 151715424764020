import * as React from "react";
import { cn } from "@/lib/utils";
import { PlanInfo } from "@/types/plan";

type Variant = "default" | "secondary" | "outline" | "destructive";

export const featureColorMap: Record<keyof PlanInfo["features"] | "lowest-price", string> = {
  "early-termination-fee": "red",
  "paperless-billing": "blue",
  "pct-renewable": "green",
  "prepaid": "purple",
  "usage-discount": "orange",
  "free-nights": "yellow",
  "free-weekends": "yellow",
  "new-customer": "yellow",
  "time-of-use": "yellow",
  "cancellation-fee": "red",
  "satisfaction-guarantee": "blue",
  "energy-type": "green",
  "tags": "yellow",
  "promotion": "orange",
  "lowest-price": "green",
};

export interface FeatureChipProps extends React.HTMLAttributes<HTMLDivElement> {
  feature: keyof PlanInfo["features"] | "lowest-price";
  variant?: Variant;
  children?: React.ReactNode;
}

function FeatureChip({ className, feature, variant = "default", children, ...props }: FeatureChipProps) {
  const baseClasses = "inline-flex items-center rounded-md px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 will-change-auto";

  const statusVariants = (baseColor: string) => ({
    default: `border-transparent bg-${baseColor}-500 text-white shadow hover:bg-${baseColor}-600`,
    secondary: `border-transparent bg-${baseColor}-100 text-${baseColor}-800 hover:bg-${baseColor}-200`,
    outline: `border border-${baseColor}-500 text-${baseColor}-500 hover:bg-${baseColor}-50`,
    destructive: `border-transparent bg-red-500 text-white shadow hover:bg-red-600`,
  });
  
  const statusColorClasses = Object.fromEntries(
    Object.entries(featureColorMap).map(([featureKey, color]) => [
      featureKey,
      statusVariants(color)
    ])
  );

  const variantClass = statusColorClasses[feature]?.[variant] || '';

  return (
    <div className={cn(baseClasses, variantClass, className)} {...props}>
      {children}
    </div>
  );
}

export default FeatureChip;

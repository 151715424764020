import { FC} from 'react';
import { cn } from '@/lib/utils';

interface HubLayoutProps {
  title: string;
  subtitle: string;
  backgroundImage: string;
  trustBadges: Array<{
    icon: React.ElementType;
    text: string;
  }>;
  keyBenefits: string[];
  children: React.ReactNode;
}

const HubLayout: React.FC<HubLayoutProps> = ({
  title,
  subtitle,
  backgroundImage,
  trustBadges,
  keyBenefits,
  children,
}) => {
  return (
    <div className="min-h-screen bg-gray-50">
      
      {/* Hero Section */}
      <section className="relative py-24 bg-gradient-to-br from-blue-900 via-blue-800 to-blue-900">
        <div 
          className="absolute inset-0 bg-cover bg-center opacity-10 mix-blend-soft-light"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        />

        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl">
            <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold text-white mb-6">
              {title}
            </h1>
            
            <p className="text-xl text-blue-100 mb-8">
              {subtitle}
            </p>

            {/* ZIP Input */}
            <div className="max-w-xl mb-12">
            </div>

            {/* Trust Badges */}
            <div className="flex flex-wrap gap-4">
              {trustBadges.map((badge, index) => {
                const Icon = badge.icon;
                return (
                  <div 
                    key={index}
                    className="flex items-center gap-2 px-3 py-1.5 bg-white/10 backdrop-blur-sm rounded-full"
                  >
                    <Icon className="w-4 h-4 text-white" />
                    <span className="text-sm font-medium text-white">{badge.text}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      {/* Key Benefits Bar */}
      <section className="bg-white border-b border-gray-200 py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-wrap justify-center gap-8">
            {keyBenefits.map((benefit, index) => (
              <div key={index} className="text-sm font-medium text-gray-600">
                • {benefit}
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Main Content */}
      {children}
    </div>
  );
};

export default HubLayout;
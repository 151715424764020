import { Pane } from "@/components/Pane";
import { useEffect, useState } from "react";
import Api from "@/utils/api";
import { Button } from "@/components/ui/button";

interface SearchCount {
  searchTerm: string;
  count: number;
}

interface Search {
  searchTerm: string;
}

export const SearchBarGraph = () => {
  const [searchCounts, setSearchCounts] = useState<SearchCount[]>([]);

  const handleGetSearches = () => {
    Api.auth
      .getRecentSearches(new Date().valueOf() - 30 * 24 * 60 * 60 * 1000)
      .then((searches) => {
        // Count occurrences of each search term
        const counts = searches.reduce((acc: { [key: string]: number }, search: Search) => {
          acc[search.searchTerm] = (acc[search.searchTerm] || 0) + 1;
          return acc;
        }, {});

        // Convert to array and sort by count
        const sortedCounts = Object.entries(counts)
          .map(([searchTerm, count]) => ({ searchTerm, count: count as number }))
          .sort((a, b) => b.count - a.count)
          .slice(0, 10); // Show top 10 searches

        setSearchCounts(sortedCounts);
      });
  };

  useEffect(() => {
    handleGetSearches();
    
    const interval = setInterval(handleGetSearches, 60000);
    return () => clearInterval(interval);
  }, []);

  // Find the maximum count for scaling
  const maxCount = Math.max(...searchCounts.map(item => item.count));

  return (
    <Pane className="flex flex-col gap-4 w-[30%] p-4">
      <div className="flex flex-col justify-between items-center">
        <h3 className="font-semibold">Popular Searches</h3>
        <Button variant="outline" size="sm" onClick={handleGetSearches}>
          Refresh
        </Button>
      </div>

      <div className="flex flex-col gap-2 w-[50%]">
        {searchCounts.length > 0 ? (
          searchCounts.map((item) => (
            <div key={item.searchTerm} className="flex flex-col gap-1">
              <div className="flex justify-between text-sm">
                <span>{item.searchTerm}</span>
                <span className="text-gray-500">{item.count}</span>
              </div>
              <div className="w-full bg-gray-100 rounded-full h-2">
                <div
                  className="bg-blue-500 h-2 rounded-full transition-all duration-500"
                  style={{ width: `${(item.count / maxCount) * 100}%` }}
                />
              </div>
            </div>
          ))
        ) : (
          <div className="text-center text-gray-500">No search data available</div>
        )}
      </div>
    </Pane>
  );
};

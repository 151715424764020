import { v4 as uuidv4 } from "uuid";
import { RenderElement } from "./renderElement";
import { RenderObject } from "./renderObject";

export const RenderArray: React.FC<{ arr: any[] }> = ({
  arr,
}): React.ReactNode => {
  return (
    <ul key={uuidv4()} className="">
      {arr.map((item) => (
        <li key={uuidv4()} className="text-sm ">
          {Array.isArray(item) ? (
            <RenderArray arr={item} />
          ) : typeof item === "object" ? (
            <RenderObject obj={item as { [key: string]: string }} />
          ) : (
            <div key={uuidv4()} className="">
              <RenderElement element={item} />
              <br />
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};

import React from 'react';
import { Star, Shield, Zap, Heart } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Pane } from '@/components/Pane';

const Testimonials: React.FC = () => {
  const testimonials = [
    {
      id: 1,
      author: "Sarah Johnson",
      location: "Houston, TX",
      image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      quote: "I saved over $400 on my annual electricity bill by switching providers. The process was incredibly easy!",
      rating: 5
    },
    {
      id: 2,
      author: "Michael Rodriguez",
      location: "Dallas, TX",
      image: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      quote: "The comparison tools made it simple to find a plan that matched my usage patterns. Highly recommended!",
      rating: 5
    },
    {
      id: 3,
      author: "Emily Chen",
      location: "Austin, TX",
      image: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      quote: "Found a great green energy plan that fits my budget. The customer service was exceptional throughout.",
      rating: 5
    }
  ];

  const trustIndicators = [
    {
      id: 'savings',
      icon: Heart,
      label: 'Average $350 Annual Savings',
      color: 'text-blue-600'
    },
    {
      id: 'switch',
      icon: Zap,
      label: 'Switch in Minutes',
      color: 'text-blue-600'
    },
    {
      id: 'guarantee',
      icon: Shield,
      label: 'Risk-Free Guarantee',
      color: 'text-blue-600'
    }
  ];

  return (
    <section className="py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Section Header */}
        <div className="text-center max-w-2xl mx-auto mb-12 lg:mb-16">
          <h2 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-4">
            What Our Customers Say
          </h2>
          <p className="text-lg text-gray-600">
            Join thousands of satisfied customers who found their perfect power plan
          </p>
        </div>

        {/* Testimonials Grid */}
        <div className="max-w-5xl mx-auto">
          <div className="grid gap-6 md:gap-8 md:grid-cols-3">
            {testimonials.map((testimonial) => (
              <Pane
                key={testimonial.id}
                className={cn(
                  "relative p-6 sm:p-8 text-center",
                  "border border-gray-100 hover:border-blue-100",
                  "shadow-sm hover:shadow-xl",
                  "transform transition-all duration-300 hover:-translate-y-1"
                )}
              >
                {/* Rating */}
                <div 
                  className="flex justify-center mb-4" 
                  aria-label={`Rated ${testimonial.rating} out of 5 stars`}
                >
                  {[...Array(5)].map((_, i) => (
                    <Star
                      key={i}
                      className="w-5 h-5 text-yellow-400 fill-current"
                      aria-hidden="true"
                    />
                  ))}
                </div>

                {/* Quote */}
                <p className="text-gray-600 italic leading-relaxed mb-6">
                  {testimonial.quote}
                </p>

                {/* Author */}
                <div className="flex items-center justify-center gap-3">
                  <img
                    src={testimonial.image}
                    alt=""
                    className="w-10 h-10 rounded-full object-cover"
                    loading="lazy"
                  />
                  <div className="text-left">
                    <div className="font-medium text-gray-900">{testimonial.author}</div>
                    <div className="text-sm text-gray-500">{testimonial.location}</div>
                  </div>
                </div>
              </Pane>
            ))}
          </div>
        </div>

        {/* Trust Indicators */}
        <div className="text-center mt-12">
          <p className="text-sm text-gray-500 mb-6">
            Based on over 10,000 verified customer reviews
          </p>
          <div className="flex flex-wrap justify-center gap-8">
            {trustIndicators.map((indicator) => (
              <div 
                key={indicator.id}
                className="flex items-center gap-2 px-4 py-2 rounded-full bg-blue-50"
              >
                <indicator.icon className={cn("w-4 h-4", indicator.color)} />
                <span className="text-sm font-medium text-blue-700">
                  {indicator.label}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
import { useEffect, useState } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Button } from "@/components/ui/button";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useNavigate, useLocation } from "react-router-dom";
import UserTopBar from "@/components/auth/userTopBar";
import SearchBar2 from "@/components/individual/newSearch";
import { cn } from "@/lib/utils";
const navigation: any[] = [];

const MainTopBar: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isHome, setIsHome] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    setIsHome(location.pathname === "/" || location.pathname === "");
  }, [location.pathname]);
  const bgColor = isHome ? "bg-transparent" : "bg-white";
  return (
    <header
      className={cn(
        isHome
          ? "sticky top-0 left-0 right-0 z-10 bg-white"
          : "fixed top-0 left-0 right-0 z-100 bg-white border-b border-gray-200"
      )}
      style={{ zIndex: isHome ? 10 : 100 }}
    >
      <div className="max-h-16 mx-auto flex flex-row justify-between min-w-[70%] max-w-[1200px] gap-8">
        <div className="flex flex-row items-center justify-center gap-8">
          <div
            className={cn("flex rounded-full cursor-pointer", bgColor)}
            style={{
              zIndex: isHome ? 10 : 90,
              borderBottom: isHome ? "" : "1px solid #e5e7eb",
            }}
            onClick={() => navigate("/")}
          >
            <img
              alt=""
              src="https://storage.googleapis.com/getelectricity-assets/logo.png"
              className="mt-4 h-16 w-16 lg:h-24 lg:w-24 p-2 lg:p-4 rounded-full bg-white"
              style={{ zIndex: 110 }}
            />
          </div>
          <div className="flex items-center gap-1">
            <span className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-blue-600">
              Get
            </span>
            <span className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-gray-900">
              Electricity
            </span>
          </div>
        </div>
        <nav
          aria-label="Global"
          className="flex max-w-7xl items-center justify-between p-6 lg:hidden"
        >
          <div className="flex lg:hidden">
            <Button
              variant="outline"
              onClick={() => setMobileMenuOpen(true)}
              className={cn(
                "-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 bg-stone-50/50"
              )}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="h-8 w-8" />
            </Button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-sm/6 font-semibold text-gray-900"
              >
                {item.name}
              </a>
            ))}
          </div>
        </nav>
        <div className="hidden lg:flex flex-col items-center justify-center p-4">
          <UserTopBar />
        </div>
      </div>

      <Dialog
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className="lg:hidden"
      >
        <div className="fixed inset-0 z-10" />
        <DialogPanel
          className={cn(
            "fixed inset-y-0 right-0 z-10 w-full overflow-y-auto",
            bgColor
          )}
        >
          <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Get Electricity</span>
              <img
                alt="Get Electricity"
                src="https://storage.googleapis.com/getelectricity-assets/logo.png"
                className="h-8 w-auto"
              />
            </a>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="py-6">
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                >
                  Log in
                </a>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
};

export default MainTopBar;

import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import Api from "@/utils/api";
import { RSSFeed_T } from "@/types/rss";

export const AddNews: React.FC<{ onSuccess: () => void }> = ({ onSuccess }) => {
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");

  const handleAddNews = async () => {
    const key = title.toLowerCase().replace(/[^a-z0-9_-]/g, "-");
    let feedUrl = url;
    if (!feedUrl.startsWith("http")) {
      feedUrl = "https://" + feedUrl;
    }
    const feed: RSSFeed_T = {
      url: feedUrl,
      title,
      key,
    };
    console.log("attempting to add feed", feed);
    const response = await Api.addNewsFeed(feed);
    if (response) {
      onSuccess();
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <h3 className="text-2xl font-semibold">Add News</h3>
      <Label>URL</Label>
      <Input value={url} onChange={(e) => setUrl(e.target.value)} />
      <Label>Title</Label>
      <Input value={title} onChange={(e) => setTitle(e.target.value)} />
      <Button onClick={handleAddNews}>Add</Button>
    </div>
  );
};

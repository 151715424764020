import { useState, useEffect } from "react";
import DataPanel from "../tasks/DataPanel";
import DataPane from "../tasks/DataPane";
import Api from "@/utils/api";

const TDUPanel = () => {
  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    Api.getTDUs().then(setData);
  }, []);
  return (
    <div className="flex flex-col gap-4">
      <div className="text-lg font-semibold">TDU Panel</div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <DataPanel defaultDataSource="tdus" />
        <DataPane item={data[0]} />
      </div>
    </div>
  );
};

export default TDUPanel;

import React from "react";
import { useEnrollStore } from "@/providers/enrollStore";
import { v4 as uuidv4 } from "uuid";
import EnrollmentStepper from "./components/EnrollmentStepper";

const Enrollment: React.FC = () => {
  const enroll = useEnrollStore();
  if (!enroll.enrollId) {
    enroll.setEnroll_enrollId(uuidv4());
  }
  return (
    <div className="w-full max-w-[900px] mx-auto h-full">
      <EnrollmentStepper />
    </div>
  );
};

export default Enrollment;

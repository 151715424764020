import { toTitleCase } from "@/helpers/genericHelpers";
import { cn } from "@/lib/utils";

export interface HomeReccsProps {
  homeSizes: {
    [key: string]: { recommendation: string };
  };
}

const homeSizeOptions = ["apartment", "small", "medium", "large"];
const homeSizeValues = {
  apartment: "500- 800 sqft",
  small: "800- 1500 sqft",
  medium: "1500- 2500 sqft",
  large: "2500+ sqft",
};
const fits = ["Good Fit", "Perfect Fit", "Not Ideal"];

export const RecommendationChip: React.FC<{ recommendation: string }> = ({
  recommendation,
}) => {
  if (!fits.includes(recommendation)) {
    return null;
  }
  const baseClasses =
    "inline-flex items-center rounded-md px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 will-change-auto";
  const variantClasses: Record<string, string> = {
    "Good Fit": "bg-green-200 text-green-800 border border-green-800",
    "Perfect Fit": "bg-purple-200 text-purple-800 border border-purple-800",
    "Not Ideal": "bg-red-200 text-red-800 border border-red-800",
  };
  return (
    <div className={cn(baseClasses, variantClasses[recommendation])}>
      {recommendation}
    </div>
  );
};

export const SizeRecc: React.FC<{ size: string; recommendation: string }> = ({
  size,
  recommendation,
}) => {
  return (
    <div className="w-full flex aspect-square flex-col items-center justify-around border-2 border-gray-200 rounded-lg sm:p-1 gap-1">
      <div className="text-sm lg:text-md font-semibold text-center">{toTitleCase(size)}</div>
      <div className="text-xs lg:text-sm text-center text-gray-500">{homeSizeValues[size as keyof typeof homeSizeValues]}</div>
      <div>{<RecommendationChip recommendation={recommendation} />}</div>
    </div>
  );
};

export const HomeReccs: React.FC<HomeReccsProps> = ({ homeSizes }) => {
  return (
    <div className="w-full flex flex-col items-center text-center justify-around md:border-2 md:border-gray-200 rounded-lg md:p-1 my-4">
      <div className="text-md lg:text-lg font-semibold py-2">Home Size Recommendations</div>
      <div className="w-full grid grid-cols-2 2xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-4 gap-2">
        {Object.entries(homeSizes)
          .sort(([sizeA], [sizeB]) => {
            const indexA = homeSizeOptions.indexOf(sizeA);
            const indexB = homeSizeOptions.indexOf(sizeB);
            return indexA - indexB;
          })
          .map(([size, recommendation]) => (
            <SizeRecc
              key={size}
              size={size}
              recommendation={recommendation.recommendation}
            />
          ))}
      </div>
      {/* <pre>{JSON.stringify(homeSizes, null, 2)}</pre> */}
    </div>
  );
};

import React from "react";
import { useTheme } from "@/theme/ThemeContext";
import { cn } from "@/lib/utils";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

interface Props {
  children: React.ReactNode;
  className?: string;
  background?: string;
}

export default function Page({
  children,
  className,
  background,
}: Props): JSX.Element {
  const { mode } = useTheme();
  const [isHome, setIsHome] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setIsHome(location.pathname === "/" || location.pathname === "");
  }, [location.pathname]);

  return (
    <div
      className={cn(
        "min-h-screen w-full",
        background,
        isHome ? "mt-0" : "mt-12"
      )}
    >
      <div
        className={cn(
          "min-h-screen max-w-[900px] lg:max-w-[1200px] mx-auto",
          "text-foreground",
          `app ${mode}`,
          className
        )}
      >
        {children}
      </div>
    </div>
  );
}

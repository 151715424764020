import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Slider } from "@/components/ui/slider";
import {
  CartesianGrid,
  Line,
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";

const MortgageCalculator = () => {
  const [homePrice, setHomePrice] = useState(300000);
  const [downPayment, setDownPayment] = useState(60000);
  const [interestRate, setInterestRate] = useState(6.5);
  const [loanTerm, setLoanTerm] = useState(30);

  // Calculate monthly mortgage payment
  const calculateMonthlyPayment = () => {
    const principal = homePrice - downPayment;
    const monthlyRate = interestRate / 100 / 12;
    const numberOfPayments = loanTerm * 12;

    const monthlyPayment =
      (principal * monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) /
      (Math.pow(1 + monthlyRate, numberOfPayments) - 1);

    return monthlyPayment;
  };

  // Add this new function to calculate monthly interest
  const calculateMonthlyInterest = () => {
    const principal = homePrice - downPayment;
    const monthlyRate = interestRate / 100 / 12;
    return principal * monthlyRate;
  };

  // Generate amortization schedule data for the chart
  const generateAmortizationData = () => {
    const monthlyPayment = calculateMonthlyPayment();
    const monthlyRate = interestRate / 100 / 12;
    const principal = homePrice - downPayment;

    let remainingBalance = principal;
    const years = Array.from({ length: loanTerm + 1 }, (_, i) => i);

    return years.map((year) => {
      // Calculate for each month of the year
      for (let month = 0; month < 12; month++) {
        if (year === 0) break; // Skip calculations for initial year
        
        const monthlyInterest = remainingBalance * monthlyRate;
        const monthlyPrincipal = monthlyPayment - monthlyInterest;
        remainingBalance = Math.max(0, remainingBalance - monthlyPrincipal);
      }

      return {
        year,
        remainingBalance: Math.round(remainingBalance),
        yearlyPayment: Math.round(monthlyPayment * 12),
        yearlyInterest: Math.round(remainingBalance * (interestRate / 100)), // Add interest data
        yearlyPrincipal: Math.round((monthlyPayment * 12) - (remainingBalance * (interestRate / 100))) // Add principal data
      };
    });
  };

  const data = generateAmortizationData();

  const chartConfig = {
    remainingBalance: { label: "Remaining Balance", color: "orange" },
    yearlyPayment: { label: "Yearly Payment", color: "green" },
    yearlyInterest: { label: "Yearly Interest", color: "red" },
    yearlyPrincipal: { label: "Yearly Principal", color: "blue" }
  } satisfies ChartConfig;

  return (
    <div className="flex flex-col items-center justify-center border-2 border-stone-500 rounded-lg p-4">
      <h2 className="text-xl font-bold mb-4">Mortgage Calculator</h2>
      <div className="flex flex-row items-center justify-between gap-4 w-full">
        <div className="w-full flex flex-col m-4">
          <label>Home Price</label>
          <Slider
            value={[homePrice]}
            onValueChange={(value) => setHomePrice(value[0])}
            min={50000}
            max={1000000}
            step={1000}
          />
          <Input
            value={homePrice.toLocaleString()}
            onChange={(e) => {
              const rawValue = e.target.value.replace(/[^0-9]/g, "");
              if (rawValue) {
                setHomePrice(Number(rawValue));
              }
            }}
            type="text"
          />
        </div>
      </div>

      <div className="w-full mb-4 flex flex-col">
        <div className="flex flex-row items-center justify-between gap-4">
          <div className="flex flex-col w-full mx-4">
            <label>Down Payment</label>
            <Slider
              value={[downPayment]}
              onValueChange={(value) => setDownPayment(value[0])}
              min={0}
              max={homePrice}
              step={1000}
            />
            <Input
              value={downPayment}
              onChange={(e) => setDownPayment(Number(e.target.value))}
            />
            <span className="text-sm text-muted-foreground">Amount ($)</span>
          </div>
          <div className="flex flex-col w-full mx-4">
            <label>Down Payment Percentage</label>
            <Slider
              value={[(downPayment / homePrice) * 100]}
              onValueChange={(value) =>
                setDownPayment((value[0] / 100) * homePrice)
              }
              min={0}
              max={100}
              step={1}
            />
            <div className="relative w-full">
              <Input
                value={((downPayment / homePrice) * 100).toFixed(1)}
                onChange={(e) =>
                  setDownPayment((Number(e.target.value) / 100) * homePrice)
                }
                type="number"
                className="pr-8"
              />
              <span className="absolute right-3 top-1/2 -translate-y-1/2 text-sm text-muted-foreground">
                %
              </span>
            </div>
            <span className="text-sm text-muted-foreground">
              Percentage (%)
            </span>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-row items-center justify-center gap-4">
        <div className="w-full mb-4 flex flex-col mx-4">
          <label>Interest Rate</label>
          <Slider
            value={[interestRate]}
            onValueChange={(value) => setInterestRate(value[0])}
            min={1}
            max={15}
            step={0.1}
          />
          <Input
            value={interestRate}
            onChange={(e) => setInterestRate(Number(e.target.value))}
          />
          <span className="text-sm text-muted-foreground">Percentage (%)</span>
        </div>

        <div className="w-full mb-4 flex flex-col mx-4">
          <label>Loan Term</label>
          <Slider
            value={[loanTerm]}
            onValueChange={(value) => setLoanTerm(value[0])}
            min={15}
            max={30}
            step={5}
          />
          <div className="relative w-full">
            <Input
              value={loanTerm}
              onChange={(e) => setLoanTerm(Number(e.target.value))}
            />
            <span className="absolute right-3 top-1/2 -translate-y-1/2 text-sm text-muted-foreground">
              years
            </span>
          </div>
          <span className="text-sm text-muted-foreground">Years</span>
        </div>
      </div>
      <div className="w-full mb-4 p-4 bg-muted rounded-lg">
        <div className="text-lg font-semibold">
          Monthly Payment: ${calculateMonthlyPayment().toFixed(2)}
        </div>
        <div className="text-sm text-muted-foreground space-y-1">
          <div>Total Loan Amount: ${(homePrice - downPayment).toLocaleString()}</div>
          <div>Monthly Interest: ${calculateMonthlyInterest().toFixed(2)}</div>
          <div>Monthly Principal: ${(calculateMonthlyPayment() - calculateMonthlyInterest()).toFixed(2)}</div>
        </div>
      </div>

      <div className="w-[600px] h-[400px]">
        <ChartContainer config={chartConfig}>
          <LineChart
            width={600}
            height={400}
            data={data}
            margin={{
              top: 20,
              bottom: 20,
              left: 32,
              right: 12,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="year"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickFormatter={(value) => `$${value.toLocaleString()}`}
            />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <Line
              dataKey="remainingBalance"
              type="monotone"
              stroke={chartConfig.remainingBalance.color}
              strokeWidth={2}
              dot={false}
            />
            <Line
              dataKey="yearlyPayment"
              type="monotone"
              stroke={chartConfig.yearlyPayment.color}
              strokeWidth={2}
              dot={false}
            />
            <Line
              dataKey="yearlyInterest"
              type="monotone"
              stroke={chartConfig.yearlyInterest.color}
              strokeWidth={2}
              dot={false}
            />
            <Line
              dataKey="yearlyPrincipal"
              type="monotone"
              stroke={chartConfig.yearlyPrincipal.color}
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ChartContainer>
      </div>
    </div>
  );
};

export default MortgageCalculator;

import React from 'react';
import { ArrowRight, Zap, Home } from 'lucide-react';
import ServiceCard from './ServiceCard';
import { cn } from '../../../lib/utils';

interface ServiceSelectorProps {
  onServiceSelect: (type: 'switch' | 'move') => void;
}

const ServiceSelector: React.FC<ServiceSelectorProps> = ({ onServiceSelect }) => {
  return (
    <section className="my-8 lg:my-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            What would you like to do today?
          </h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            We make it easy to get the best electricity plan for your needs.
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          {/* Switch Service Card */}
          <ServiceCard
            icon={<Zap className="w-6 h-6" />}
            title="Switch to a Better Deal"
            description="Is your contract expiring or already expired? Compare plans and switch today to start saving."
            ctaText="Find Your Best Plan"
            onClick={() => onServiceSelect('switch')}
            gradientColors="from-blue-500 to-blue-600"
          />

          {/* Move Service Card */}
          <ServiceCard
            icon={<Home className="w-6 h-6" />}
            title="Moving to a New Home?"
            description="We'll get you connected in time for your move, with the best plan for your new home."
            ctaText="Start Electricity Service"
            onClick={() => onServiceSelect('move')}
            gradientColors="from-purple-500 to-purple-600"
          />
        </div>

        {/* Trust Indicators */}
        <div className="mt-16 text-center">
          <div className="inline-flex items-center gap-6 px-6 py-3 rounded-full bg-gray-50">
            {['Licensed Provider', 'A+ BBB Rating', '100% Satisfaction Guarantee'].map((trust) => (
              <span 
                key={trust}
                className="text-sm font-medium text-gray-600"
              >
                {trust}
              </span>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceSelector;
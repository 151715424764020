import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { deregulationData } from "./data";

const EnergyDeregulationTable = () => {
  const navigate = useNavigate()

  return (
    <Table className="mt-4">
      <TableCaption>Energy Deregulation Status in the United States</TableCaption>
      <TableHeader>
        <TableRow>
          <TableHead>State</TableHead>
          <TableHead>Electricity</TableHead>
          <TableHead>Natural Gas</TableHead>
          <TableHead>Notes</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {deregulationData.map((row) => (
          <TableRow key={row.state}>
            <TableCell className="font-medium p-0">
              <button
                onClick={() => navigate(`/${row.state.toLowerCase().replace(/\s+/g, '-')}`)}
                className="w-full h-full px-4 py-2 text-left hover:bg-gray-100 transition-colors"
              >
                {row.state}
              </button>
            </TableCell>
            <TableCell>{row.electricity}</TableCell>
            <TableCell>{row.naturalGas}</TableCell>
            <TableCell>{row.notes}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default EnergyDeregulationTable
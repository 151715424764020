import { isDeregulated } from "./helpers";
import DeregulatedTable from "./DeregulatedTable";
import { toTitleCase } from "@/helpers/genericHelpers";
import { findState } from "@/helpers/validators";

const StateDeregulationInfo = ({ state }: { state: string }) => {
  let stateName = findState(state);
  stateName = toTitleCase(stateName);
  const dereg = isDeregulated(stateName);
  return (
    <div className="p-2 mt-4">
      {dereg ? (
        <div>
          <h6 className="text-sm text-stone-500">
            {stateName} is fully deregulated for both electricity and natural
            gas.
          </h6>
        </div>
      ) : (
        <div>
          <h4 className="text-xl text-stone-500">
            Unfortunately, {stateName} is not deregulated for electricity.
          </h4>
          <h4 className="text-xl text-stone-500">
            Below is a table of the current status of states that are
            deregulated for either electricity or natural gas.
          </h4>
          <DeregulatedTable />
        </div>
      )}
    </div>
  );
};

export default StateDeregulationInfo;

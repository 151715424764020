import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { toTitleCase } from "@/helpers/genericHelpers";
import Api from "@/utils/api";
import { useEffect, useState } from "react";
import { Plus as PlusIcon, Search as SearchIcon } from "lucide-react";
import { UserNav } from "./components/user-nav";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { pgPlanInfo } from "@/types/plan";

export interface DataSource {
  name: string;
  description: string;
  apiCall: () => Promise<any[]>;
  permissions: ("add" | "edit" | "delete" | "plans")[];
}

const popOutKeys = ["getxid"];
const skipKeys = ["id", "logosmall"];

export const dataSourceMap: { [key: string]: DataSource } = {
  retailers: {
    name: "Retailers",
    description: "Retailers in the system",
    apiCall: Api.getRetailers,
    permissions: ["add", "edit", "delete", "plans"],
  },
  tdus: {
    name: "TDUs",
    description: "TDUs in the system",
    apiCall: Api.getTDUs,
    permissions: ["add", "edit", "delete"],
  },
};

const DataPanel: React.FC<{ defaultDataSource?: keyof typeof dataSourceMap }> = ({ defaultDataSource }) => {
  const [data, setData] = useState<any[]>([]);
  const [dataSource, setDataSource] = useState<string>(defaultDataSource?.toString() ?? "retailers");
  const [plans, setPlans] = useState<pgPlanInfo[]>([]);
  const [selectedRetailer, setSelectedRetailer] = useState<any | null>(null);

  useEffect(() => {
    dataSourceMap[dataSource].apiCall().then(setData);
  }, [dataSource]);

  useEffect(() => {
    if (selectedRetailer) {
      getPlansForRetailer(selectedRetailer.getxid);
    }
  }, [selectedRetailer]);

  const closePlans = () => {
    setSelectedRetailer(null);
    setPlans([]);
  };

  const getPlansForRetailer = (retailerId: string) => {
    Api.plansByRetailer(retailerId).then((pgPlans) => {
      const filterPlans = pgPlans.map((plan: pgPlanInfo) =>
        !!plan.data ? { name: plan.data.info["plan-name"], ...plan.data } : {}
      );
      setPlans(filterPlans);
    });
  };

  const columns = selectedRetailer
    ? Object.keys(selectedRetailer).filter((key) => !skipKeys.includes(key))
    : data[0]
    ? Object.keys(data[0]).filter((key) => !skipKeys.includes(key))
    : [];
  const maxColumnWidth = 50;

  const cellStyle = {
    maxWidth: `${maxColumnWidth}px`,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap" as const,
  };

  const headerStyle = {
    ...cellStyle,

    backgroundColor: "#f5f5f5",
  };

  const tableStyle = {
    cellStyle,
    headerStyle,
  };

  return (
    <div className="hidden h-full flex-1 flex-col space-y-8 p-8 md:flex">
      <div className="flex items-center justify-between space-y-2">
        <div>
          <h2 className="text-2xl font-bold tracking-tight">
            {toTitleCase(dataSource)}
          </h2>
          <p className="text-muted-foreground">
            Here are all the {dataSource} in the system
          </p>
          <Select value={dataSource} onValueChange={setDataSource}>
            <SelectTrigger className="w-[360px]">
              <SelectValue placeholder="Select Data Source" />
            </SelectTrigger>
            <SelectContent>
              {Object.keys(dataSourceMap).map((key) => (
                <SelectItem value={key} key={key}>
                  {toTitleCase(key)}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex flex-col items-center space-x-2">
          <UserNav />
        </div>
      </div>
      <div className="space-y-4 w-full">
        <div className="rounded-md border w-full">
          {renderTable(data, columns, tableStyle)}
        </div>
        {!!(plans && plans.length > 0 && selectedRetailer) &&
          <RenderPlans plans={plans} cellStyle={tableStyle.cellStyle} />}
      </div>
      <div className="flex justify-end">
        {dataSourceMap[dataSource].permissions.includes("add") && (
          <Button>
            <PlusIcon />
            Add {toTitleCase(dataSource)}
          </Button>
        )}
      </div>
    </div>
  );
};

export default DataPanel;

const targetKeys: { [key: string]: string } = {
  tdu: "code",
  retailer: "name",
};

function renderCell(value: any, column: string) {
  const stringValue =
    value && typeof value === "string" ? value : JSON.stringify(value);
  if (
    (!!stringValue && stringValue.length > 24) ||
    popOutKeys.includes(column)
  ) {
    return (
      <Popover>
        <PopoverTrigger asChild>
          <Button variant="outline" size="sm">
            <SearchIcon />
            {Object.keys(targetKeys).includes(column)
              ? value[targetKeys[column]]
              : column}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-300">
          <pre className="max-h-[300px] overflow-auto p-4">
            {JSON.stringify(value)}
          </pre>
        </PopoverContent>
      </Popover>
    );
  }
  return <span>{stringValue}</span>;
}

function renderContent(value: any, column: string) {
  if (typeof value === "string" && value.length > 24) {
    return <span>{value}</span>;
  }
  if (Array.isArray(value)) {
    return (
      <ul>
        {value.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    );
  }
  if (typeof value === "object") {
    return (
      <ul>
        {!!value &&
          Object.keys(value).map((key) => (
            <li key={key}>
              {key}: {JSON.stringify(value[key])}
            </li>
          ))}
      </ul>
    );
  }
  return <>{JSON.stringify(value)}</>;
}

export const RenderPlans: React.FC<{ plans: pgPlanInfo[], cellStyle: React.CSSProperties }> = ({ plans, cellStyle }) => {
  const plansColumns = plans[0]
    ? Object.keys(plans[0]).filter((key) => !skipKeys.includes(key) && !!key)
    : [];
  return (
    <div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>Description</TableHead>
            <TableHead>Data</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody className="overflow-auto">
          {plans.map((row, index) => (
            <TableRow key={index}>
              {plansColumns.map((column) => (
                <TableCell key={column} style={cellStyle}>
                  {renderCell(row[column as keyof pgPlanInfo], column)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

function renderTable(
  data: any[],
  columns: string[],
  tableStyle: {
    cellStyle: React.CSSProperties;
    headerStyle: React.CSSProperties;
  }
) {
  if (!data || data.length === 0) {
    return <div>No data</div>;
  }
  if (!columns || columns.length === 0) {
    return <div>No columns</div>;
  }
  return (
    <Table>
      <TableHeader>
        <TableRow>
          {columns.map((column) => (
            <TableHead key={column} style={tableStyle.headerStyle}>
              {column}
            </TableHead>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.map((row, index) => (
          <TableRow key={index}>
            {columns.map((column) => (
              <TableCell key={column} style={tableStyle.cellStyle}>
                {renderCell(row[column], column)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

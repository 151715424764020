import { MoreHorizontal, Filter, ArrowDownAZ, ArrowDownZA } from "lucide-react";
import { FC, useEffect, useState } from "react";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { formatRelative } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { MarkerMap } from "@/components/Map/MarkerMap";
import Api from "@/utils/api";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { formatDistanceToNow } from "date-fns";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

export interface Column {
  key: string;
  header: string;
  hidden?: boolean;
  itemRenderer?: (item: any) => React.ReactNode;
}

export type Sort = {
  key: string;
  direction: "asc" | "desc";
};

export type Filter = {
  key: string;
  value: string;
};

export const EsiTable: FC<{ esiIds: any[] | null }> = ({ esiIds }) => {
  const [sort, setSort] = useState<Sort>({
    key: "esi_id",
    direction: "asc",
  });
  const [filter, setFilter] = useState<Filter[]>([]);
  const [displayEsiIds, setDisplayEsiIds] = useState<any[]>(esiIds || []);

  useEffect(() => {
    let filteredEsiIds: any[] = esiIds || [];
    if (filter.length > 0) {
      filteredEsiIds = filteredEsiIds.filter((esiId) => {
        return filter.every((filter) => {
          return esiId[filter.key].includes(filter.value);
        });
      });
    }
    const sortedEsiIds = filteredEsiIds.sort((a, b) => {
      const aValue = a[sort.key];
      const bValue = b[sort.key];
      return sort.direction === "asc"
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    });
    setDisplayEsiIds(sortedEsiIds || []);
  }, [filter, sort, esiIds]);

  const columns: Column[] = [
    { key: "esi_id", header: "ESI ID" },
    { key: "premise_type", header: "Status" },
    { key: "address", header: "Address" },
    { key: "city", header: "City" },
    { key: "zipcode", header: "Zipcode" },
    { key: "date_added", header: "Date Added" },
  ];
  const columnWidth = `${100 / columns.length}%`;

  const [viewTemps, setViewTemps] = useState(false);
  const [geoCodedAddresses, setGeoCodedAddresses] = useState<any[]>([]);

  useEffect(() => {
    if (!viewTemps) {
      setFilter([]);
    } else {
      setFilter([{ key: "address", value: " TEMP" }]);
    }
  }, [viewTemps]);

  useEffect(() => {
    if (!esiIds) {
      return;
    }
    for (const esiId of esiIds) {
      Api.auth
        .geoCodeAddress(
          `${esiId.address}, ${esiId.city}, ${esiId.state}, ${esiId.zipcode}`
        )
        .then((res) => {
          if (res.coordinates) {
            setGeoCodedAddresses((prev) => [
              ...prev,
              { lng: res.coordinates.x, lat: res.coordinates.y },
            ]);
          }
        });
    }
  }, [esiIds]);

  return (
    <Card className="w-full">
      <CardHeader className="max-w-[300px]">
        <CardTitle>ESI IDs</CardTitle>
        <CardDescription>Manage your ESI IDs.</CardDescription>
        <Button onClick={() => setViewTemps(!viewTemps)}>View Temps</Button>
      </CardHeader>
      <CardContent>
        <div className="w-full h-[600px]">
          <MarkerMap markers={geoCodedAddresses} position={{ lat: 27.8, lng: -97.8, zoom: 8 }} />
        </div>
        <Table>
          <TableHeaderComponent
            columns={columns}
            columnWidth={columnWidth}
            sort={sort}
            setSort={setSort}
            filter={filter}
            setFilter={setFilter}
            esiIds={esiIds}
          />
          <TableBody>
            {Array.isArray(displayEsiIds) &&
              displayEsiIds.map((esiId) => (
                <TableRowComponent
                  key={esiId.id}
                  esiId={esiId}
                  columns={columns}
                  columnWidth={columnWidth}
                />
              ))}
          </TableBody>
        </Table>
      </CardContent>
      <CardFooter>
        <div className="text-xs text-muted-foreground">
          TODO: Pagination goes here
        </div>
      </CardFooter>
    </Card>
  );
};

export const TableHeaderComponent: FC<{
  columns: Column[];
  columnWidth: string;
  sort: Sort;
  setSort: (sort: Sort) => void;
  filter: Filter[];
  setFilter: (filter: Filter[]) => void;
  esiIds: any[] | null;
}> = ({ columns, columnWidth, sort, setSort, filter, setFilter, esiIds }) => {
  return (
    <TableHeader>
      <TableRow>
        {columns.map((column) => {
          if (column.hidden) {
            return (
              <TableHead key={column.key}>
                <span className="sr-only">{column.header}</span>
              </TableHead>
            );
          }
          return (
            <TableHeaderCell
              key={column.key}
              column={column}
              columnWidth={columnWidth}
              esiIds={esiIds}
              sort={sort}
              setSort={setSort}
              filter={filter}
              setFilter={setFilter}
            />
          );
        })}
      </TableRow>
    </TableHeader>
  );
};

export const TableRowComponent: FC<{
  esiId: any;
  columns: Column[];
  columnWidth: string;
}> = ({ esiId, columns, columnWidth }) => {
  return (
    <TableRow>
      {columns.map((column) => {
        return (
          <TableCell
            key={column.key}
            className="h-12 p-2 align-middle"
            style={{ width: columnWidth }}
          >
            {column.itemRenderer
              ? column.itemRenderer(esiId)
              : esiId[column.key]}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export const TableHeaderCell: FC<{
  column: Column;
  columnWidth: string;
  esiIds: any[] | null;
  sort: Sort;
  setSort: (sort: Sort) => void;
  filter: Filter[];
  setFilter: (filter: Filter[]) => void;
}> = ({ column, columnWidth, esiIds, sort, setSort, filter, setFilter }) => {
  return (
    <TableHead style={{ width: columnWidth }} className="p-0">
      <div className="relative h-[100px] group">
        {/* Static Header Text */}
        <div className="absolute bottom-0 left-0 right-0 p-2">
          {column.header}
        </div>

        {/* Hover Controls */}
        <div
          className="absolute bottom-[40%] left-0 right-0 invisible 
                      group-hover:visible bg-background border 
                      border-border rounded-lg shadow-lg p-2 
                      flex flex-col gap-2 opacity-0 
                      group-hover:opacity-100 transition-all 
                      duration-200 ease-in-out z-10"
        >
          <div className="flex justify-between items-center gap-2">
            <SortComponent
              column={column}
              columnWidth={columnWidth}
              sort={sort}
              setSort={setSort}
            />
            <FilterComponent
              column={column}
              columnWidth={columnWidth}
              esiIds={esiIds}
              filter={filter}
              setFilter={setFilter}
            />
          </div>
        </div>
      </div>
    </TableHead>
  );
};

export const SortComponent: FC<{
  column: Column;
  columnWidth: string;
  sort: Sort;
  setSort: (sort: Sort) => void;
}> = ({ column, columnWidth, sort, setSort }) => {
  const isActive = sort.key === column.key;

  return (
    <div className="flex items-center gap-1">
      <div className="flex gap-1">
        <Button
          variant={isActive && sort.direction === "asc" ? "default" : "outline"}
          className={`${
            isActive && sort.direction === "asc"
              ? "text-primary bg-primary/10"
              : "hover:text-primary/70"
          }`}
          onClick={() => setSort({ key: column.key, direction: "asc" })}
        >
          <ArrowDownAZ className="h-4 w-4" />
        </Button>
        <Button
          variant={
            isActive && sort.direction === "desc" ? "default" : "outline"
          }
          className={`${
            isActive && sort.direction === "desc"
              ? "text-primary bg-primary/10"
              : "hover:text-primary/70"
          }`}
          onClick={() => setSort({ key: column.key, direction: "desc" })}
        >
          <ArrowDownZA className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};

export const FilterComponent: FC<{
  column: Column;
  columnWidth: string;
  esiIds: any[] | null;
  filter: Filter[];
  setFilter: (filter: Filter[]) => void;
}> = ({ column, columnWidth, esiIds, filter, setFilter }) => {
  if (!esiIds) {
    return null;
  }
  const filterOptions = Array.from(
    new Set(esiIds.map((esiId) => esiId[column.key]))
  ).filter(Boolean);

  // Get current selected values for this column
  const selectedValues = new Set(
    filter.filter((f) => f.key === column.key).map((f) => f.value)
  );

  const toggleValue = (value: string) => {
    const otherFilters = filter.filter((f) => f.key !== column.key);

    if (selectedValues.has(value)) {
      // Remove the value
      const newSelectedValues = new Set(selectedValues);
      newSelectedValues.delete(value);
      setFilter([
        ...otherFilters,
        ...Array.from(newSelectedValues).map((v) => ({
          key: column.key,
          value: v,
        })),
      ]);
    } else {
      // Add the value
      setFilter([
        ...otherFilters,
        ...Array.from(selectedValues).map((v) => ({
          key: column.key,
          value: v,
        })),
        { key: column.key, value },
      ]);
    }
  };

  return (
    <div className="flex items-center gap-2">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" className="ml-2">
            <Filter />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          <DropdownMenuLabel>Filter by {column.header}</DropdownMenuLabel>
          {filterOptions.map((option) => (
            <DropdownMenuItem
              key={option}
              onClick={() => toggleValue(option)}
              className="flex items-center gap-2"
            >
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={selectedValues.has(option)}
                  className="h-4 w-4"
                  onChange={() => {}}
                />
                {option}
              </div>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export const deregulationData = [
  {
    state: "Texas",
    electricity: "Yes",
    naturalGas: "Partial",
    notes: "85% of state has electric choice",
  },
  {
    state: "Pennsylvania",
    electricity: "Yes",
    naturalGas: "Yes",
    notes: "Statewide deregulation for both",
  },
  {
    state: "Ohio",
    electricity: "Yes",
    naturalGas: "Yes",
    notes: "Full deregulation for both",
  },
  {
    state: "New York",
    electricity: "Yes",
    naturalGas: "Yes",
    notes: "Statewide deregulation",
  },
  {
    state: "California",
    electricity: "Limited",
    naturalGas: "Yes",
    notes: "Electric choice through lottery system",
  },
  {
    state: "Florida",
    electricity: "No",
    naturalGas: "Yes",
    notes: "Only natural gas is deregulated",
  },
  {
    state: "Illinois",
    electricity: "Yes",
    naturalGas: "Yes",
    notes: "75% eligible for natural gas choice",
  },
  {
    state: "Massachusetts",
    electricity: "Yes",
    naturalGas: "Yes",
    notes: "Statewide deregulation for both",
  },
  {
    state: "Michigan",
    electricity: "Yes",
    naturalGas: "Yes",
    notes: "Limited electric choice",
  },
  {
    state: "New Jersey",
    electricity: "Yes",
    naturalGas: "Yes",
    notes: "Statewide deregulation for both",
  },
];

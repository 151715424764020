import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
    CardDescription,
  } from "@/components/ui/card";
  import LoginButton from "@/components/auth/loginButton";
  import { Input } from "@/components/ui/input";
  import { Label } from "@/components/ui/label";
  
  
  const Register = () => {
    return (
      <Card className="w-full min-w-[400px] flex flex-col gap-4 text-primary bg-background">
        <CardHeader className="flex flex-col gap-2 text-center">
          <CardTitle className="text-2xl font-bold">Register</CardTitle>
  
        </CardHeader>
        <CardContent className="flex flex-col gap-4">
          <Label>Email</Label>
          <Input type="email" placeholder="Email" />
          <Label>Create Password</Label>
          <Input type="password" placeholder="Password" />
          <Label>Confirm Password</Label>
          <Input type="password" placeholder="Confirm Password" />
          <LoginButton />
        </CardContent>
      </Card>
    );
  };
  
  export default Register;
  
import { useState, useEffect } from "react";
import { RenderContent } from "../components/renderContent";

const DataPane: React.FC<{ item: any }> = ({ item }) => {
  const [keys, setKeys] = useState<string[]>([]);

  useEffect(() => {
    if (item) {
      setKeys(Object.keys(item));
    }
  }, [item]);

  return (
    <div className="flex flex-col gap-4 items-center justify-center w-full h-full p-8">
      <div className="flex flex-col gap-4 bg-stone-200 rounded-lg p-4 max-w-[700px]">
        <div className="text-lg font-semibold">DataPane</div>
        <div className="flex flex-col gap-4">
          {keys.map((key) => (
            <div className="flex flex-row gap-2 border border-stone-300 rounded-md p-2 bg-background">
              <RenderContent content={item[key]} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DataPane;

import React from "react";
import { ArrowRight } from "lucide-react";
import { cn } from "../../../lib/utils";
import { Pane } from "@/components/Pane";

interface ServiceCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  ctaText: string;
  onClick: () => void;
  gradientColors: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({
  icon,
  title,
  description,
  ctaText,
  onClick,
  gradientColors,
}) => {
  return (
    <Pane
      className="group h-full relative border-2 border-gray-200 rounded-lg p-4 text-center hover:border-transparent hover:shadow-xl transition-all duration-300"
      bgClass="bg-white"
    >
      {/* Gradient Border Effect on Hover */}
      <div
        className={cn(
          "absolute inset-0 rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300",
          "bg-gradient-to-r",
          gradientColors,
          "-z-10 blur-sm"
        )}
      />
      <div className="h-full grid grid-rows-[25%_auto_25%]">
        {/* Icon */}
        <div
          className={cn(
            "flex flex-col items-center justify-center w-16 h-16 rounded-xl mb-6 mx-auto row-span-1",
            "bg-gradient-to-br",
            gradientColors,
            "text-white shadow-lg"
          )}
        >
          {icon}
        </div>

        {/* Content */}
        <div className="row-start-2 row-span-1">
          <h3 className="text-2xl font-bold text-gray-900 mb-4">
            {title}
          </h3>
          <p className="text-gray-600 mb-8 max-w-sm mx-auto place-self-center">
            {description}
          </p>
        </div>
        {/* CTA Button */}
        <button
          onClick={onClick}
          className={cn(
            "inline-flex items-center gap-2 px-8 py-4 rounded-xl",
            "text-base font-semibold text-white shadow-sm",
            "bg-gradient-to-r row-span-1 items-center justify-center text-center",
            gradientColors,
            "hover:shadow-md transition-shadow duration-200",
            "transform group-hover:translate-y-0 translate-y-0 group-hover:shadow-xl"
          )}
        >
          {ctaText}
          <ArrowRight className="w-5 h-5 transition-transform duration-200 group-hover:translate-x-1" />
        </button>
      </div>
    </Pane>
  );
};

export default ServiceCard;

import React from 'react';
import { useGuide } from '../GuideContext';
import { Search } from 'lucide-react';
import { cn } from '../../../lib/utils';

const CurrentProviderStep: React.FC = () => {
  const { state, dispatch } = useGuide();

  const providers = [
    "4Change Energy",
    "Ambit Energy",
    "Cirro Energy",
    "Direct Energy",
    "Gexa Energy",
    "Green Mountain Energy",
    "Just Energy",
    "Reliant Energy",
    "TXU Energy",
    "Other",
    "I don't know"
  ];

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-xl font-semibold text-gray-900">
          Who is your current electricity provider?
        </h2>
        <p className="mt-1 text-sm text-gray-500">
          This helps us find plans that could save you money
        </p>
      </div>

      <div className="grid grid-cols-2 gap-4">
        {providers.map((provider) => (
          <button
            key={provider}
            onClick={() => dispatch({ 
              type: 'SET_CURRENT_PROVIDER', 
              payload: provider 
            })}
            className={cn(
              "p-4 rounded-lg border-2 text-left transition-all",
              state.currentProvider === provider
                ? "border-blue-500 bg-blue-50 ring-2 ring-blue-200 ring-opacity-50"
                : "border-gray-200 hover:border-blue-200 hover:bg-gray-50"
            )}
          >
            <div className="font-medium text-gray-900">{provider}</div>
          </button>
        ))}
      </div>

      {state.currentProvider === "I don't know" && (
        <div className="bg-blue-50 rounded-lg p-4 flex items-start gap-3">
          <Search className="w-5 h-5 text-blue-600 flex-shrink-0 mt-0.5" />
          <div className="text-sm text-blue-700">
            <p className="font-medium mb-1">Need help finding your provider?</p>
            <p>We can look it up using your address</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CurrentProviderStep;
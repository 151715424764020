import { PlanInfo } from "@/types/plan";

interface LineItemProps {
  label: string;
  amount: number;
  subtext?: string;
  isCredit?: boolean;
}

const LineItem: React.FC<LineItemProps> = ({
  label,
  amount,
  subtext,
  isCredit = false,
}) => (
  <div className="flex flex-col gap-1">
    <div className="text-sm flex justify-between">
      <span className="font-semibold">{label}</span>
      <span className={isCredit ? "text-green-600" : ""}>
        {isCredit ? "-" : ""}${Math.abs(amount).toFixed(2)}
      </span>
    </div>
    {subtext && <span className="text-xs text-gray-600">{subtext}</span>}
  </div>
);

const PlanDetailsInfoSection: React.FC<{
  planInfo: PlanInfo;
  usage: number;
}> = ({ planInfo, usage }) => {
  const tduBaseCharge =
    planInfo.tdu?.["tdu-pricing"]?.["fixed-charge-dollars"] || 0;
  const planBaseCharge = planInfo["plan-pricing"]?.["base-fee-monthly"] || 0;
  const totalBaseCharge = tduBaseCharge + planBaseCharge;
  const tduVariableCharge =
    planInfo.tdu?.["tdu-pricing"]?.["dollars-per-kwh"] || 0;
  const planVariableCharge =
    planInfo["plan-pricing"]?.["kwh-rate"] &&
    planInfo["plan-pricing"]?.["kwh-rate"] > 0
      ? planInfo["plan-pricing"]?.["kwh-rate"] / 100
      : planInfo["plan-pricing"]?.["kwh-rate"];
  const hasUsageCredits =
    planInfo?.["usage-credit"] &&
    planInfo["usage-credit"].length > 0 &&
    planInfo["usage-credit"].filter((credit) => credit["dollars-credit"] > 0)
      .length > 0;
  const nonZeroUsageCredits = hasUsageCredits
    ? planInfo["usage-credit"]?.filter((credit) => credit["dollars-credit"] > 0)
    : [];
  const usageCredits = nonZeroUsageCredits
    ?.map(
      (credit) =>
        `${credit["lower"]} - ${credit["upper"]} kWh: $${credit["dollars-credit"]}`
    )
    .join(", ");

  const hasEarlyTerminationFee =
    planInfo?.["features"]["early-termination-fee"] !== undefined;
  const hasPlanDescription = planInfo?.["info"]["plan-description"];
  const totalCost =
    totalBaseCharge + (planVariableCharge ? planVariableCharge * usage : 0);

  return (
    <div className="bg-white">
      {/* Provider Charges Section */}
      <div className="space-y-4">
        <div className=" bg-gray-100 p-2 rounded-md border border-gray-300">
          <h4 className="font-semibold mb-2">Provider Charges</h4>
          {planVariableCharge && (
            <LineItem
              label="Energy Charge"
              amount={planVariableCharge * usage}
              subtext={`${(planVariableCharge * 100).toFixed(
                2
              )}¢ per kWh x ${usage} kWh`}
            />
          )}

          {hasUsageCredits && nonZeroUsageCredits && (
            <div className="mt-2">
              <LineItem
                label="Bill Credit"
                amount={nonZeroUsageCredits[0]?.["dollars-credit"]}
                subtext={`($${nonZeroUsageCredits[0]?.["dollars-credit"]} credit if usage at least ${nonZeroUsageCredits[0]?.["lower"]})`}
                isCredit
              />
            </div>
          )}

          {planVariableCharge && nonZeroUsageCredits && (
            <div className="text-sm flex justify-between font-semibold mt-3 pt-2 px-2 border-t">
              <span>Provider Charges Subtotal:</span>
              <span>
                $
                {(
                  planVariableCharge * usage -
                  (nonZeroUsageCredits[0]?.["dollars-credit"] || 0)
                ).toFixed(2)}
              </span>
            </div>
          )}
        </div>

        {/* Utility Delivery Charges Section */}
        <div className="mt-4 bg-gray-100 p-2 rounded-md border border-gray-300">
          <h4 className="font-semibold mb-2 text-sm">
            Utility Delivery Charges ({planInfo.tdu?.code || "TDU"})
          </h4>

          <LineItem
            label="Base Charge"
            amount={tduBaseCharge}
            subtext={`$${tduBaseCharge.toFixed(2)} charge per billing cycle`}
          />

          <div className="mt-2">
            <LineItem
              label="Delivery Charge"
              amount={tduVariableCharge * usage}
              subtext={`${(tduVariableCharge * 100).toFixed(
                4
              )}¢ per kWh x ${usage} kWh`}
            />
          </div>
        </div>
      </div>

      <hr className="my-4" />
      <div className="space-y-2">
        <div className="flex justify-between border border-gray-300 p-2 rounded-md text-mainBlue">
          <span className="font-semibold text-sm lg:text-lg">
            Estimated Monthly Cost
          </span>
          <span className="font-semibold text-sm lg:text-lg">
            ${totalCost.toFixed(2)}
          </span>
        </div>
        <div className="flex justify-between px-4">
          <span className="font-semibold text-xs lg:text-xs">
            Estimated Price Per kWh
          </span>
          <span className="font-semibold text-xs lg:text-xs">
            {((totalCost / usage) * 100).toFixed(2)} ¢
          </span>
        </div>
      </div>
      {hasPlanDescription && (
        <p className="mt-4 text-xs">• {planInfo.info["plan-description"]}</p>
      )}
      <p className="mt-4 text-xs">
        • Your price will not change during the term of this plan except to
        reflect actual changes in TDU charges; changes to the Electric
        Reliability Council of Texas or Texas Regional Entity administrative
        fees; or changes resulting from federal, state or local laws that impose
        new or modified fees or costs that are beyond our control.
      </p>
    </div>
  );
};

export default PlanDetailsInfoSection;

import { PlanInfo } from "@/types/plan";
import { ComparePlanButton } from "./ComparePlanButton";
import {
  Lock,
  Leaf,
  Bookmark,
  Star,
  ChevronDown,
  ChevronUp,
} from "lucide-react";
import { StarRating } from "./components/StarRating";
import { PriceDisplay } from "./components/PriceDisplay";

export const PlanCardHeader: React.FC<{ plan: PlanInfo }> = ({ plan }) => {
  return (
    <div className="w-full flex flex-col items-center justify-start max-h-[300px] mb-4">
      <div className="grid grid-cols-3 w-full py-2">
        <div className="col-start-1 row-start-1 flex flex-col items-start justify-center pointer-events-auto cursor-pointer"></div>
        <img
          src={plan.retailer.logo}
          alt={plan.retailer.name}
          className="h-12 object-contain"
          loading="lazy"
        />
        <div className="flex flex-col items-end justify-center pointer-events-auto cursor-pointer">
          <ComparePlanButton id={plan.getxid} />
        </div>
      </div>

      <div className="w-full flex flex-col items-center justify-start px-4">
        <h2 className="w-full text-xl font-bold text-slate-900 text-center">
          {plan.info["plan-name"]}
        </h2>
        <h2 className="w-full text-sm font-light text-slate-600 text-center">
          {plan.retailer.name}
        </h2>

        <PriceDisplay plan={plan} />
        <div className="w-full flex flex-row items-center justify-center gap-2">
          <StarRating rating={plan["ai-analysis"]?.rating || -1} />
          <div className="text-sm text-gray-500 leading-tight">
            Customer Rating
          </div>
        </div>
      </div>
    </div>
  );
};

import { MarkerMap } from "@/components/Map/MarkerMap";
import { ZipMap } from "@/components/Map/ZipMap";
import { Pane } from "@/components/Pane";
import { useState } from "react";

import SearchBar2 from "@/components/individual/newSearch";
import { useEnrollStore } from "@/providers/enrollStore";
import { usePlansStore } from "@/providers/planStore";
import { MapPinHouse } from "lucide-react";
import { cn } from "@/lib/utils";

export const PlanMap3: React.FC<{
  zipCodes: string[];
}> = ({ zipCodes }) => {
  const {
    service_address_zip,
    service_address_line1,
    service_address_city,
    service_address_state,
    locationSpec,
    service_coords,
  } = useEnrollStore();
  const { tdus } = usePlansStore();
  const [searchOpen, setSearchOpen] = useState(false);
  const mapContainerStyle = {
    height: "20vh",
    width: "100%",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    position: "relative" as const,
    overflow: "hidden",
  };

  return (
    <Pane className="!p-0">
      <div style={mapContainerStyle}>
        {locationSpec === "zip" && (
          <ZipMap zipCodes={zipCodes} position={service_coords} />
        )}
        {locationSpec === "address" && (
          <MarkerMap markers={[service_coords]} position={service_coords} />
        )}
      </div>
      <div className="w-full rounded-b-lg p-4 flex flex-col items-center justify-center">
        <div className="flex flex-col sm:grid sm:grid-cols-[70%_30%] items-center justify-between gap-2 w-full">
          <div
            id="left"
            className="w-full flex flex-col sm:flex-row sm:items-center sm:justify-center flex-1 items-center justify-center gap-4"
          >
            <MapPinHouse
              className="w-10 h-10 text-mainBlue"
              style={{ strokeWidth: 1.5 }}
            />

            <div
              className={cn(
                "w-full h-full flex flex-col md:flex-row md:justify-start md:grow items-center justify-center gap-2 p-2",
                locationSpec === "zip" ? "" : "hidden"
              )}
            >
              <span className="text-lg md:text-xl font-semibold">
                {service_address_zip}
              </span>
              <div className="w-full md:w-auto md:grow flex flex-row items-center justify-center gap-2 px-2 md:px-4">
                <SearchBar2
                  enroll={false}
                  placeholder={
                    window.innerWidth < 768
                      ? "Enter your address"
                      : "Enter your address to confirm availability and improve usage forecast"
                  }
                />
              </div>
            </div>
            <div
              className={cn(
                "w-full h-full flex flex-col md:flex-row md:justify-start md:grow items-center justify-center gap-2 p-2",
                locationSpec === "address" ? "" : "hidden"
              )}
            >
              <div className="text-lg md:text-xl font-semibold flex flex-row items-center justify-center gap-2">
                <span>{service_address_line1}</span>
                <span>
                  {service_address_city}, {service_address_state}
                </span>
                <span>{service_address_zip}</span>
              </div>
            </div>
          </div>
          <div
            id="right"
            className="flex flex-row items-center justify-center gap-2"
          >
            {tdus.map((tdu) => (
              <div
                key={tdu}
                className={`flex flex-row items-center justify-center gap-1.5 m-1 py-1 px-2 rounded-full font-medium border transition-colors cursor-default`}
                title={tdu}
              >
                <div className="text-sm">{tdu}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Pane>
  );
};

PlanMap3.displayName = "PlanMap3";

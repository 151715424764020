import React from 'react';

const ProviderMethodology: React.FC = () => {
  return (
    <div className="prose prose-sm max-w-none">
      <h2 className="text-2xl font-bold text-gray-900 mb-4">
        Best Electricity Providers Methodology
      </h2>
      
      <p className="text-gray-600 mb-6">
        As part of our provider rating process, our expert team evaluates over 30 electricity providers 
        in Texas within their respective categories, including residential, business, and renewable energy providers.
      </p>

      <p className="text-gray-600 mb-4">
        For each provider, we gathered information that potential customers would use in their 
        decision-making process, including:
      </p>

      <ul className="list-disc pl-6 mb-6 space-y-2 text-gray-600">
        <li>Customer satisfaction ratings and reviews</li>
        <li>Plan variety and pricing competitiveness</li>
        <li>Contract terms and conditions</li>
        <li>Renewable energy options</li>
        <li>Customer service quality and availability</li>
        <li>Additional benefits and incentives</li>
      </ul>

      <p className="text-gray-600 mb-6">
        Providers are weighed against others in their category according to which characteristics 
        are most important within that category. These scores allowed us to rank them based on 
        their terms and features and select the best electricity providers of {new Date().getFullYear()} for this list.
      </p>

      <p className="text-gray-500 italic">
        Please note: Providers on this list may not be shown in order of their star rating. 
        The star rating is determined solely by the editorial team.
      </p>
    </div>
  );
};

export default ProviderMethodology;
import React from "react";
import { Heart, Star, Leaf, Lock } from "lucide-react";
import { PlanInfo, PlanPricing } from "@/types/plan";
import { cn } from "@/lib/utils";
import { usePlansStore } from "@/providers/planStore";
import { Pane } from "@/components/Pane";


export const PlansTable: React.FC = () => {
  const { filters, plans, comparePlans } = usePlansStore();
  const displayPlans = Array.isArray(plans) ? plans.map((plan) => plan.data) : [];

  const columns = [
    { key: "provider", label: "Provider" },
    { key: "planName", label: "Plan Name" },
    { key: "rate", label: "Rate" },
    { key: "term", label: "Term" },
    { key: "greenEnergy", label: "Green Energy" },
    { key: "rating", label: "Rating" },
  ];

  return (
    <Pane className="border border-gray-200 rounded-lg !p-0 overflow-hidden">
      <table className="w-full border-collapse">
        <thead className="bg-gray-50">
          <tr>
            {columns.map((column) => (
              <th key={column.key} className="px-4 py-3 text-left text-md font-medium text-gray-900">
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {Array.isArray(displayPlans) &&
            displayPlans.map((plan) => {
              return (
              <tr
                key={plan.puctNumber}
                className="group bg-white hover:bg-gray-50 transition-colors"
              >
                <td className="px-4 py-4 text-sm bg-white">
                  <div className="flex items-center gap-3 bg-white">
                    <img
                      src={plan.retailer.logo}
                      alt={plan.retailer.name}
                      className="h-8 w-8 object-contain"
                    />
                    <span className="font-medium text-gray-900">
                      {plan.retailer.name}
                    </span>
                  </div>
                </td>
                <td className="px-4 py-4 bg-white">
                  <div className="space-y-1 bg-white">
                    <div className="text-sm font-medium text-gray-900">
                      {plan.info["plan-name"]}
                    </div>
                    <div className="text-xs text-gray-500">
                      PUCT #{plan.puctNumber}
                    </div>
                  </div>
                </td>
                <td className="px-4 py-4 bg-white">
                  <div className="flex flex-col text-sm items-left font-medium text-gray-900">
                    {(
                      plan["plan-pricing"][
                        `kwh-${filters.usageTier.value}` as keyof PlanPricing
                      ] as number
                    )?.toFixed(1)}
                    ¢
                  </div>
                  <div className="text-xs text-gray-500">per kWh</div>
                </td>
                <td className="px-4 py-4 bg-white">
                  <div className="gap-1.5 bg-white">
                    <Lock className="w-4 h-4 text-gray-400" />
                    <span className="text-sm text-gray-600">
                      {plan["term-length"]}mo
                    </span>
                  </div>
                </td>
                <td className="px-4 py-4 bg-white">
                  <div className="flex items-center justify-center gap-1.5 bg-white">
                    <Leaf className="w-4 h-4 text-green-500" />
                    <span className="text-sm text-gray-600">
                      {plan.renewablePercentage || 0}%
                    </span>
                  </div>
                </td>
                <td className="px-4 py-4 bg-white">
                  <div className="flex justify-center bg-white">
                    {plan.rating}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Pane>
  );
};

export default PlansTable;

import { Star } from "lucide-react";

export const StarRating: React.FC<{ rating: number }> = ({ rating }) => {
  if (rating < 0 || rating > 5) {
    return null;
  }
  return (
    <div className="flex">
      {[...Array(5)].map((_, i) => (
        <Star
          key={i}
          className={`w-4 h-4 ${
            i < rating ? "text-yellow-400 fill-current" : "text-gray-200"
          }`}
          aria-hidden="true"
        />
      ))}
    </div>
  );
};

import { useState } from "react";
import MainTopBar from "./mainTopBar";
import MainFooter from "./mainFooter";
import { Outlet } from "react-router-dom";
import { Toaster } from "@/components/ui/sonner";
import { useSessionStore } from "@/providers/store";

const MainLayout: React.FC = () => {
  const { bgColor = "bg-red-500" } = useSessionStore();
  return (
      <div className="min-h-screen min-w-screen flex flex-col">
        <MainTopBar />
        <Outlet />
        <MainFooter />
    </div>
  );
};

export default MainLayout;

import { toTitleCase } from "@/helpers/genericHelpers";
import { findState } from "@/helpers/validators";
import { getStateSVG } from "@/views/Localized/helpers";
import { isDeregulated } from "./helpers";


const StateHeader = ({ state }: { state: string }) => {
  let stateName = findState(state);
  stateName = toTitleCase(stateName);
  const dereg = isDeregulated(state);
  return (
    <div className="flex flex-row items-center justify-between sm:mx-8 w-[90%]">
      <h1 className="sm:text-6xl text-4xl font-bold">{stateName}</h1>
      <img src={getStateSVG(state)} alt={state} width={100} height={100} />
    </div>
  );
};

export default StateHeader;

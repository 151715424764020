import React from "react";
import {
  Award,
  ThumbsUp,
  Zap,
  Leaf,
  Moon,
  Sun,
  Trophy,
  ClockAlert,
  CircleDollarSign,
  BookOpenCheck,
  CalendarDays,
} from "lucide-react";
import { PlanInfo } from "@/types/plan";

interface PlanBadgesProps {
  plan: PlanInfo;
}

export const PlanBadges: React.FC<PlanBadgesProps> = ({ plan }) => {
  const badges = [];
  // Popular badge
  if (plan["term-length"]) {
    badges.push({
      id: "term-length",
      icon: (
        <CalendarDays className="w-3.5 h-3.5" style={{ strokeWidth: 1.5 }} />
      ),
      label: `${plan["term-length"]} Mo`,
      color: "bg-blue-50 text-blue-800 border-blue-300 hover:bg-blue-100",
    });
  }

  // Language badge
  if (plan.language?.toLowerCase() === "spanish") {
    badges.push({
      id: "spanish",
      label: "Español",
      icon: (
        <BookOpenCheck className="w-3.5 h-3.5" style={{ strokeWidth: 1.5 }} />
      ),
      color: "bg-white text-black border-red-200 hover:bg-yellow-100",
    });
  }
  if (
    plan["features"]["pct-renewable"] &&
    plan["features"]["pct-renewable"] > 0
  ) {
    badges.push({
      id: "green",
      label: `${plan["features"]["pct-renewable"] || 0}% Renewable`,
      icon: <Leaf className="w-3.5 h-3.5" style={{ strokeWidth: 1.5 }} />,
      color: "bg-green-50 text-green-700 border-green-200 hover:bg-green-100",
    });
  }
  if (plan["ai-analysis"]?.prepaid?.boolean) {
    badges.push({
      id: "prepaid",
      label: "Prepaid",
      icon: <Zap className="w-3.5 h-3.5" style={{ strokeWidth: 1.5 }} />,
      color:
        "bg-purple-50 text-purple-700 border-purple-200 hover:bg-purple-100",
    });
  }

  if (plan["ai-analysis"]?.cancellationFee?.fee > 190) {
    badges.push({
      id: "cancellation-fee",
      label: `$${plan["ai-analysis"]?.cancellationFee?.fee} Cancellation Fee`,
      icon: (
        <CircleDollarSign className="w-4 h-4" style={{ strokeWidth: 1.5 }} />
      ),
      color: "bg-red-50 text-red-700 border-red-300 hover:bg-red-100",
    });
  }

  // Satisfaction Guarantee badge
  if (plan.features["satisfaction-guarantee"]) {
    badges.push({
      id: "guarantee",
      label: `Satisfaction Guarantee`,
      icon: <Award className="w-3.5 h-3.5" style={{ strokeWidth: 1.5 }} />,
      color: "bg-green-50 text-green-700 border-green-200 hover:bg-green-100",
    });
  }

  // Green Energy badge
  if (plan["features"]["free-nights"]) {
    badges.push({
      id: "free-nights",
      label: "Free Nights",
      icon: <Moon className="w-3.5 h-3.5" style={{ strokeWidth: 1.5 }} />,
      color:
        "bg-indigo-50 text-indigo-700 border-indigo-200 hover:bg-indigo-100",
    });
  }

  // Green Energy badge
  if (
    plan.features["time-of-use"] &&
    (plan.features["time-of-use"] === true ||
      plan.features["time-of-use"] === "true" ||
      plan.features["time-of-use"] === "True")
  ) {
    badges.push({
      id: "time-of-use",
      label: "Time of Use",
      icon: <ClockAlert className="w-3.5 h-3.5" style={{ strokeWidth: 1.5 }} />,
      color:
        "bg-indigo-50 text-indigo-700 border-indigo-200 hover:bg-indigo-100",
    });
  }
  // Bill Credit badge
  if (
    plan["usage-credit"] &&
    plan["usage-credit"]?.length > 0 &&
    plan["usage-credit"][0]["dollars-credit"] > 0
  ) {
    badges.push({
      id: "bill-credit",
      label: "Bill Credit",
      icon: <Zap className="w-3.5 h-3.5" style={{ strokeWidth: 1.5 }} />,
      color:
        "bg-purple-50 text-purple-700 border-purple-200 hover:bg-purple-100",
    });
  }

  if (badges.length === 0) return null;

  return (
    <div className="flex flex-row flex-wrap items-start justify-start gap-1">
      {badges.slice(0, 4).map((badge) => (
        <div
          key={badge.id}
          className={`flex flex-row items-center justify-center gap-1.5 m-1 py-1 px-2 rounded-full font-medium border transition-colors cursor-default ${badge.color}`}
          title={badge.label}
        >
          {badge.icon}
          <div className="text-xs">{badge.label}</div>
        </div>
      ))}
    </div>
  );
};

import { useState, FC, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { X, ArrowRight } from "lucide-react";
import { cn } from "@/lib/utils";
import ServiceTypeStep from "./steps/ServiceTypeStep";
import MoveDateStep from "./steps/MoveDateStep";
import AddressStep from "./steps/AddressStep";
import CurrentProviderStep from "./steps/CurrentProviderStep";
import ContractEndStep from "./steps/ContractEndStep";
import { GuideProvider, useGuide } from "./GuideContext";
import { useSessionStore } from "@/providers/store";
import { useEnrollStore } from "@/providers/enrollStore";

const GuideContent: FC = () => {
  const { state } = useGuide();
  const { setModalOpen } = useSessionStore();
  const { service_changeType } = useEnrollStore();
  const [currentStep, setCurrentStep] = useState(1);

  const renderStep = () => {
    if (service_changeType === "moving") {
      switch (currentStep) {
        case 1:
          return <ServiceTypeStep />;
        case 2:
          return <MoveDateStep />;
        case 3:
          return <AddressStep />;
        default:
          return null;
      }
    } else {
      switch (currentStep) {
        case 1:
          return <ServiceTypeStep />;
        case 2:
          return <CurrentProviderStep />;
        case 3:
          return <ContractEndStep />;
        default:
          return null;
      }
    }
  };

  const totalSteps = 3;
  const onClose = () => {
    setModalOpen(null);
  };
  const handleNext = () => {
    if (currentStep < totalSteps) {
      setCurrentStep((prev) => prev + 1);
    } else {
      setModalOpen(null);
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const canContinue = () => {
    switch (currentStep) {
      case 1:
        return !!state.serviceType;
      case 2:
        return state.serviceType === "moving"
          ? true // Allow both with and without move date
          : !!state.currentProvider;
      case 3:
        return state.serviceType === "moving" ? state.hasAddress : true; // Allow both with and without contract end date
      default:
        return false;
    }
  };

  return (
    <div className="relative w-full max-w-2xl transform rounded-2xl bg-white p-6 text-left shadow-xl transition-all sm:p-8">
      {/* Progress Bar */}
      <div className="mb-8">
        <div className="mb-2 flex items-center justify-between">
          <span className="text-sm font-medium text-gray-500">
            Step {currentStep} of {totalSteps}
          </span>
          <span className="text-sm font-medium text-blue-600">
            {Math.round((currentStep / totalSteps) * 100)}%
          </span>
        </div>
        <div className="h-2 overflow-hidden rounded-full bg-gray-100">
          <div
            className="h-full transform bg-gradient-to-r from-blue-500 to-blue-600 transition-all duration-500 ease-out"
            style={{ width: `${(currentStep / totalSteps) * 100}%` }}
          />
        </div>
      </div>

      {/* Content */}
      <div className="min-h-[400px]">
        <div className="animate-fade-in">{renderStep()}</div>
      </div>

      {/* Footer */}
      <div className="mt-8 flex items-center justify-between border-t border-gray-100 pt-6">
        {currentStep > 1 ? (
          <button
            onClick={handleBack}
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-900"
          >
            <X className="h-4 w-4" />
            Back
          </button>
        ) : (
          <button
            onClick={onClose}
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-900"
          >
            <X className="h-4 w-4" />
            Cancel
          </button>
        )}
        <button
          onClick={handleNext}
          disabled={!canContinue()}
          className={cn(
            "flex items-center gap-2 rounded-lg px-6 py-2.5 text-sm font-medium transition-all",
            canContinue()
              ? "bg-gradient-to-r from-blue-500 to-blue-600 text-white shadow-sm hover:from-blue-600 hover:to-blue-700"
              : "cursor-not-allowed bg-gray-100 text-gray-400"
          )}
        >
          <span>{currentStep === totalSteps ? "View Plans" : "Continue"}</span>
          <ArrowRight className="h-4 w-4" />
        </button>
      </div>
    </div>
  );
};

const Guide: FC = () => {
  const { modalOpen, setModalOpen } = useSessionStore();
  return (
    <GuideProvider>
      <Transition show={modalOpen === "guide"} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={() => setModalOpen(null)}
        >
          <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500/75 backdrop-blur-sm transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block w-full transform overflow-hidden rounded-2xl bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:align-middle">
                <GuideContent />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </GuideProvider>
  );
};

export default Guide;

import { BaseMap } from "@/components/Map/BaseMap";
import { RecentSearches } from "@/views-auth/Dashboard/Widgets/recentSearches";
import { SearchBarGraph } from "@/views-auth/Dashboard/Widgets/searchBarGraph";
import { ZipMap } from "@/components/Map/ZipMap";
import { NewEsiIds } from "@/views-auth/Dashboard/Widgets/newEsiIds";

export const HomePanel = () => {
  return (
    <div className="flex flex-col gap-4">
      <NewEsiIds />
      <div className="flex flex-row gap-4">
        <RecentSearches />
        <SearchBarGraph />

        <ZipMap zipCodes={["78628", "75051", "75052"]} />
      </div>
    </div>
  );
};

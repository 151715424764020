import React from 'react';
import { MapPin, Search, Zap, Shield, ArrowRight } from 'lucide-react';
import { cn } from '@/lib/utils';
import { motion } from 'framer-motion';
import SearchBar2 from '@/components/individual/newSearch';

const HowItWorks: React.FC = () => {
  const steps = [
    {
      icon: MapPin,
      title: 'Enter Your ZIP Code',
      description: 'Get instant access to the best energy plans in your area',
      color: 'from-blue-500 to-blue-600'
    },
    {
      icon: Search,
      title: 'Compare Plans & Rates',
      description: 'Low rates, green energy, or flexible terms - find what works for you',
      color: 'from-indigo-500 to-indigo-600'
    },
    {
      icon: Zap,
      title: 'Get Connected',
      description: 'Confirm your energy plan and get connected in minutes',
      color: 'from-purple-500 to-purple-600'
    }
  ];

  return (
    <section className="" id="how-it-works">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl font-bold text-gray-900 mb-4"
          >
            How It Works
          </motion.h2>
          <motion.p 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.1 }}
            className="text-xl text-gray-600"
          >
            Find and switch to a better electricity plan in minutes
          </motion.p>
        </div>

        {/* Steps */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {steps.map((step, index) => {
            const Icon = step.icon;
            return (
              <motion.div
                key={step.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="relative group"
              >
                {/* Connecting Line */}
                {index < steps.length - 1 && (
                  <div className="hidden md:block absolute top-12 left-1/2 w-full h-[2px] bg-gradient-to-r from-blue-100 to-blue-200">
                    <motion.div
                      className="absolute inset-0 bg-gradient-to-r from-blue-500 to-blue-600"
                      initial={{ scaleX: 0 }}
                      whileInView={{ scaleX: 1 }}
                      viewport={{ once: true }}
                      transition={{ delay: 0.5, duration: 0.5 }}
                    />
                  </div>
                )}

                {/* Step Content */}
                <div className="relative flex flex-col items-center text-center">
                  <div className={cn(
                    "w-24 h-24 rounded-2xl mb-6",
                    "bg-gradient-to-br", step.color,
                    "flex items-center justify-center",
                    "transform transition-all duration-300 group-hover:scale-110",
                    "shadow-lg group-hover:shadow-xl"
                  )}>
                    <Icon className="w-10 h-10 text-white" />
                  </div>
                  <h3 className="text-xl font-bold text-gray-900 mb-2">
                    {step.title}
                  </h3>
                  <p className="text-gray-600 max-w-[80%]">
                    {step.description}
                  </p>
                </div>
              </motion.div>
            );
          })}
        </div>

        {/* CTA Section */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="relative max-w-3xl mx-auto"
        >
          <div className={cn(
            "relative overflow-hidden",
            "bg-gradient-to-br from-blue-600 to-indigo-600",
            "rounded-2xl shadow-xl",
            "p-4 sm:p-8 md:p-12"
          )}>
            {/* Gradient Background */}
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,_rgba(255,255,255,0.1),_transparent_50%)]" />

            <div className="relative">
              {/* CTA Content */}
              <div className="text-center mb-6 sm:mb-8">
                <h3 className="text-2xl sm:text-3xl font-bold text-white mb-3 sm:mb-4">
                  Start Saving Today
                </h3>
                <p className="text-sm sm:text-xl text-blue-100">
                  Compare rates and find the best plan for you
                </p>
              </div>

              {/* ZIP Input */}
              <div className="max-w-sm mx-auto">
                <SearchBar2 enroll={false} />
                
                {/* Trust Badge */}
                <div className="mt-4 sm:mt-6 flex items-center justify-center gap-2 text-blue-100">
                  <Shield className="w-4 h-4" />
                  <span className="text-sm">
                    50,000+ Texans trust us to find their best rates
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Decorative Elements */}
          <div className="absolute -top-6 -left-6 w-12 h-12 bg-blue-500 rounded-full opacity-20 blur-xl" />
          <div className="absolute -bottom-8 -right-8 w-16 h-16 bg-indigo-500 rounded-full opacity-20 blur-xl" />
        </motion.div>
      </div>
    </section>
  );
};

export default HowItWorks;
import { mapId } from "@/index";
import { Map, RenderingType } from "@vis.gl/react-google-maps";
import { memo } from "react";
import { APIProvider } from "@vis.gl/react-google-maps";
import { config } from "@/config";

const defaultPosition = { lat: 32.7294, lng: -96.9964 };

export const BaseMap: React.FC<{
  children?: React.ReactNode;
  position?: { lat: number; lng: number; zoom?: number };
}> = memo(({ children, position }) => {
  console.log("BaseMap rendering");
  return (
    <APIProvider apiKey={config.GOOGLE_MAPS_API_KEY}>
      <Map
        defaultZoom={position?.zoom || 10}
        defaultCenter={position || defaultPosition}
        renderingType={RenderingType.VECTOR}
        mapId={mapId}
      >
        {children}
      </Map>
    </APIProvider>
  );
});

BaseMap.displayName = "BaseMap";

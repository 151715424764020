import { cn } from "@/lib/utils";

export const Pane: React.FC<{
  children: React.ReactNode;
  bgClass?: string;
  className?: string;
}> = ({ children, bgClass, className }) => {
  return (
    <div
      id="pane"
      className={cn(
        "flex flex-col w-full items-center justify-center p-2 shadow-lg rounded-lg my-2",
        bgClass,
        className
      )}
    >
      {children}
    </div>
  );
};

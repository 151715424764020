import { validateZipCode } from "@/helpers/validators";
import { usePlansStore } from "@/providers/planStore";
import { useEnrollStore } from "@/providers/enrollStore";
import Api from "@/utils/api";
import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";

const Localized = () => {
  const { localeOrService } = useParams();
  const [zipInfo, setZipInfo] = useState<any>(null);
  const isZipCode = validateZipCode(localeOrService || "");
  const { fetchPlans } = usePlansStore();
  const { setEnroll_service_address_zip } = useEnrollStore();
  useEffect(() => {
    if (isZipCode) {
      Api.getZipInfo(localeOrService || "").then(setZipInfo);
      fetchPlans(localeOrService || "");
      setEnroll_service_address_zip(localeOrService || "");
    }
  }, [localeOrService]);

  return (
    <div>
      <Outlet />
    </div>
  );
};

export default Localized;

import React from 'react';
import { Calendar } from 'lucide-react';
import { useGuide } from '../GuideContext';
import DatePicker from '@/components/individual/DatePicker';
import { cn } from '@/lib/utils';
import { useEnrollStore } from '@/providers/enrollStore';
const MoveDateStep: React.FC = () => {
  const { service_startDate, setEnroll_service_startDate } = useEnrollStore();

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-xl font-semibold text-gray-900">
          When do you need service to start?
        </h2>
        <p className="mt-1 text-sm text-gray-500">
          You can schedule service up to 90 days in advance
        </p>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <button
          onClick={() => setEnroll_service_startDate(null)}
          className={cn(
            "p-6 rounded-xl border-2 text-left transition-all",
            !service_startDate
              ? "border-blue-500 bg-blue-50 ring-2 ring-blue-200 ring-opacity-50"
              : "border-gray-200 hover:border-blue-200 hover:bg-gray-50"
          )}
        >
          <Calendar className={cn(
            "w-8 h-8 mb-4",
            !service_startDate ? "text-blue-500" : "text-gray-400"
          )} />
          <div className="space-y-2">
            <div className="font-medium text-gray-900">Not Sure Yet</div>
            <div className="text-sm text-gray-500">
              I want to explore plans now and set the date later
            </div>
          </div>
        </button>

        <div className={cn(
          "p-6 rounded-xl border-2 transition-all",
          service_startDate
            ? "border-blue-500 bg-blue-50 ring-2 ring-blue-200 ring-opacity-50"
            : "border-gray-200"
        )}>
          <Calendar className={cn(
            "w-8 h-8 mb-4",
            service_startDate ? "text-blue-500" : "text-gray-400"
          )} />
          <div className="space-y-4">
            <div>
              <div className="font-medium text-gray-900">I Know My Move Date</div>
              <div className="text-sm text-gray-500">Select your move-in date</div>
            </div>
            <DatePicker
              date={service_startDate}
              setDate={(date) => setEnroll_service_startDate(date)}
              disabled={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoveDateStep;
import React, { useState } from 'react';
import { Lock, Leaf, Bookmark, Star, ChevronDown, ChevronUp } from 'lucide-react';
import { PlanInfo, PlanPricing } from '@/types/plan';
import { cn } from '@/lib/utils';
import { usePlansStore } from '@/providers/planStore';
import { PlanCardHeader } from './PlanCardHeader';
import { PlanCardContent } from './PlanCardContent';
import { PlanCardFooter } from './PlanCardFooter';
import { Pane } from '@/components/Pane';
interface PlanCardProps {
  plan: PlanInfo;
  className?: string;
}

export const PlanCard2: React.FC<PlanCardProps> = ({ plan, className }) => {

  return (
    <Pane className="border-2 border-gray-200 rounded-lg" bgClass="bg-white">
      <div className={cn("relative h-[550px]","flex flex-col justify-between items-center w-full pointer-events-none ", className)}>
        <PlanCardHeader plan={plan} />
        <PlanCardContent plan={plan} />
        <PlanCardFooter plan={plan} />
      </div>
    </Pane>
  );
};

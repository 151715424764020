import React from 'react';
import { Retailer } from '@/types/plan';
import { cn } from '../../lib/utils';

interface ProviderSummaryTableProps {
  retailers: Retailer[];
}

const ProviderSummaryTable: React.FC<ProviderSummaryTableProps> = ({ retailers }) => {
  return (
    <div className="overflow-x-auto">
      <table className="w-full border-collapse">
        <thead className="bg-[#4C5BA6] text-white">
          <tr>
            <th className="px-6 py-3 text-left text-sm font-medium">PROVIDER</th>
            <th className="px-6 py-3 text-left text-sm font-medium">BEST FOR</th>
            <th className="px-6 py-3 text-left text-sm font-medium">RATING</th>
            <th className="px-6 py-3 text-center text-sm font-medium">ANNUAL FEE</th>
            <th className="px-6 py-3 text-center text-sm font-medium">WELCOME BONUS</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {retailers.map((retailer) => (
            <tr key={retailer.getxid} className="hover:bg-gray-50">
              <td className="px-6 py-4">
                <div className="flex items-center gap-3">
                  <img 
                    src={retailer.logo} 
                    alt={retailer.name}
                    className="h-8 w-8 object-contain"
                  />
                  <span className="font-medium text-blue-600 hover:text-blue-700">
                    {retailer.name}
                  </span>
                </div>
              </td>
              <td className="px-6 py-4 text-sm">
                {retailer.name}
              </td>
              <td className="px-6 py-4">
                <span className="font-medium">{3.5}</span>
              </td>
              <td className="px-6 py-4 text-center">
                {retailer.name ? `$${retailer.name}` : 'N/A'}
              </td>
              <td className="px-6 py-4 text-center">
                {retailer.name || 'N/A'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProviderSummaryTable;
import { Retailer } from '@/types/plan';
import Api from '@/utils/api';
import { create } from 'zustand';

export interface RetailerStore {
  retailers: Retailer[];
  getRetailers: () => Retailer[];
  setRetailers: (retailers: Retailer[]) => void;
  getRetailer: (getxid: string) => Retailer | null;
  getRetailerLogo: (getxid: string) => string;
  getRetailersForPlans: () => void;
}

export const initialState = {
  retailers: [],
}

export const useRetailerStore = create<RetailerStore>((set,get) => ({
  ...initialState,
  setRetailers: (retailers: Retailer[]) => set({ retailers }),
  getRetailers: () => {
    Api.getRetailers().then((retailers) => set({ retailers }));
    return get().retailers;
  },
  getRetailer: (getxid: string) => {
    const retailers = get().retailers;
    if (!retailers || !retailers.length) {
      get().getRetailers();
    }
    return retailers.find((retailer) => retailer.getxid === getxid) || null;
  },
  getRetailerLogo: (getxid: string) => {
    const retailers = get().retailers;
    if (!retailers || !retailers.length) {
      get().getRetailers();
    }
    return retailers.find((retailer) => retailer.getxid === getxid)?.logo || "";
  },
  getRetailersForPlans: () => {
    const retailers = get().retailers;
    if (!retailers || !retailers.length) {
      get().getRetailers();
    }
  },
}));
import React, { useState } from 'react';
import { Star, ExternalLink, Building2, Users, Calendar, DollarSign, ChevronDown, ChevronUp, Shield } from 'lucide-react';
import { cn } from '../../lib/utils';
import ProviderRating from './ProviderRating';
import { Retailer } from '@/types/plan';
interface ProviderCardProps {
  retailer: Retailer;
}

const ProviderCard: React.FC<ProviderCardProps> = ({ retailer }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="bg-white rounded-xl border border-gray-200 overflow-hidden">
      {/* Main Content */}
      <div className="p-6">
        <div className="flex flex-col sm:flex-row sm:items-center gap-6">
          {/* Logo and Name */}
          <div className="flex items-center gap-4 sm:w-1/4">
            <img 
              src={retailer.logo} 
              alt={retailer.name}
              className="w-16 h-16 object-contain"
            />
            <div>
              <h3 className="text-lg font-semibold text-gray-900">
                {retailer.name}
              </h3>
              <div className="flex items-center gap-2">
                {/* <ProviderRating rating={retailer.reviews?.["average-rating"] || 0} size="sm" /> */}
                {/* <a 
                  href={retailer.ratesUrl}
                  className="text-sm text-blue-600 hover:text-blue-700 flex items-center gap-1"
                >
                  View Rates
                  <ExternalLink className="w-3 h-3" />
                </a> */}
              </div>
            </div>
          </div>

          {/* Quick Stats */}
          {/* <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 sm:flex-1"> */}
            {/* <div>
              <div className="flex items-center gap-1.5 text-sm text-gray-500 mb-1">
                <Users className="w-4 h-4" />
                Employees
              </div>
              <div className="font-medium">{retailer.employees}</div>
            </div> */}

            {/* <div>
              <div className="flex items-center gap-1.5 text-sm text-gray-500 mb-1">
                <Building2 className="w-4 h-4" />
                Parent Company
              </div>
              <div className="font-medium">{provider.parentCompany}</div>
            </div>

            <div>
              <div className="flex items-center gap-1.5 text-sm text-gray-500 mb-1">
                <DollarSign className="w-4 h-4" />
                Revenue
              </div>
              <div className="font-medium">{provider.revenue}</div>
            </div>

            <div>
              <div className="flex items-center gap-1.5 text-sm text-gray-500 mb-1">
                <Calendar className="w-4 h-4" />
                Years Active
              </div>
              <div className="font-medium">{provider.yearsInOperation} years</div>
            </div>
          </div> */}

          {/* Action Buttons */}
          <div className="sm:w-48 flex flex-col gap-2">
            <button className={cn(
              "w-full px-6 py-2.5 text-sm font-medium rounded-lg",
              "bg-green-600 text-white",
              "hover:bg-green-700 transition-colors"
            )}>
              Apply Now
            </button>
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="w-full flex items-center justify-center gap-1 text-sm text-gray-600 hover:text-gray-900"
            >
              {isExpanded ? (
                <>
                  <ChevronUp className="w-4 h-4" />
                  Show Less
                </>
              ) : (
                <>
                  <ChevronDown className="w-4 h-4" />
                  Show More
                </>
              )}
            </button>
          </div>
        </div>

        {/* Expanded Content */}
        {isExpanded && (
          <div className="mt-6 pt-6 border-t border-gray-100 animate-fade-in">
            <div className="grid md:grid-cols-2 gap-8">
              {/* Service Areas */}
              <div>
                <h4 className="text-sm font-medium text-gray-900 mb-3">Service Areas</h4>
                <div className="flex flex-wrap gap-2">
                  {/* {provider.serviceAreas.map((area) => (
                    <span 
                      key={area}
                      className="px-2.5 py-1 text-sm bg-gray-100 text-gray-700 rounded-full"
                    >
                      {area}
                    </span>
                  ))} */}
                </div>
              </div>

              {/* Customer Service */}
              <div>
                <h4 className="text-sm font-medium text-gray-900 mb-3">Customer Service</h4>
                <div className="space-y-2">
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600">Support Hours</span>
                    <span className="font-medium">24/7</span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600">Languages</span>
                    <span className="font-medium">English, Spanish</span>
                  </div>
                </div>
              </div>

              {/* Our Take */}
              <div className="md:col-span-2">
                <div className="flex items-start gap-3 p-4 bg-blue-50 rounded-lg">
                  <Shield className="w-5 h-5 text-blue-600 flex-shrink-0 mt-0.5" />
                  <div>
                    <h4 className="text-sm font-medium text-blue-900 mb-1">Our Take</h4>
                    <p className="text-sm text-blue-700">
                      {retailer.name}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProviderCard;
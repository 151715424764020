import React, { useEffect } from "react";
import { MapPin, FileText, Zap } from "lucide-react";
import { cn } from "@/lib/utils";
import { EnrollStep, useEnrollStore } from "@/providers/enrollStore";
import { planProgressSteps } from "@/utils/enroll-utils";
import { Pane } from "@/components/Pane";
export const PlanProgress: React.FC = () => {
  const { step, service_address_zip, service_address_line1, setEnroll_step } =
    useEnrollStore();
  const steps = planProgressSteps;
  const currentStep = steps.find((s) => s.key === step)?.number || 1;
  const progress = ((currentStep - 1) / (steps.length - 1)) * 100;

  useEffect(() => {
    if (
      step === EnrollStep.ENTER_LOCATION &&
      (service_address_line1 || service_address_zip)
    ) {
      setEnroll_step(EnrollStep.PLAN_SELECTION);
    }
  }, [step, service_address_line1, service_address_zip]);

  return (
    <>
      {/* Mobile Progress Bar */}
      <div className="block sm:hidden w-full px-4 py-4">
        <div className="flex items-center justify-between mb-2">
          <span className="text-sm font-medium text-blue-600">
            Step {currentStep} of {steps.length}
          </span>
          <span className="text-sm font-medium text-gray-500">
            {steps.find((s) => s.number === currentStep)?.title}
          </span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2.5">
          <div
            className="bg-blue-600 h-2.5 rounded-full transition-all duration-500 ease-out"
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>

      {/* Desktop Progress Steps */}
      <Pane className="hidden sm:block w-full rounded-xl shadow-sm bg-white border border-gray-200 overflow-hidden relative py-8">
        {/* Progress Line */}
        <div
          className="absolute top-[3.5rem] left-[2.75rem] right-[2.75rem] h-0.5 bg-gradient-to-r from-gray-100/50 to-gray-100/50"
          style={{ backdropFilter: "blur(8px)" }}
        >
          <div
            className="h-full transition-all duration-500 ease-out"
            style={{
              width: `${((currentStep - 1) / (steps.length - 1)) * 100}%`,
              background:
                "linear-gradient(90deg, #3B82F6 0%, #6366F1 50%, #8B5CF6 100%)",
              boxShadow: "0 0 20px rgba(99, 102, 241, 0.3)",
            }}
          />
        </div>

        {/* Steps */}
        <div className="relative flex justify-between px-8">
          {steps.map((step, index) => {
            const isCompleted = currentStep > step.number;
            const isCurrent = currentStep === step.number;
            const Icon = step.icon;

            return (
              <div
                key={step.number}
                className={cn(
                  "flex flex-col items-center text-center",
                  index === 0 && "text-left",
                  index === steps.length - 1 && "text-right"
                )}
              >
                {/* Step Circle */}
                <div
                  className={cn(
                    "w-14 h-14 rounded-full flex items-center justify-center relative z-10 transition-all duration-300",
                    isCompleted
                      ? "bg-gradient-to-br from-blue-500 to-indigo-600"
                      : isCurrent
                      ? "bg-gradient-to-br from-blue-50 to-indigo-50 ring-2 ring-blue-500/30"
                      : "bg-gradient-to-br from-gray-50 to-gray-100 ring-1 ring-gray-200/50",
                    "transform transition-transform duration-300",
                    (isCompleted || isCurrent) &&
                      "hover:scale-110 hover:shadow-lg"
                  )}
                  style={{
                    boxShadow: isCompleted
                      ? "0 8px 16px rgba(99, 102, 241, 0.2)"
                      : isCurrent
                      ? "0 8px 16px rgba(99, 102, 241, 0.1)"
                      : "none",
                  }}
                >
                  <Icon
                    className={cn(
                      "w-6 h-6 transition-colors",
                      isCompleted
                        ? "text-white"
                        : isCurrent
                        ? "text-blue-600"
                        : "text-gray-400"
                    )}
                  />

                  {/* Pulse Animation for Current Step */}
                  {isCurrent && (
                    <>
                      <div className="absolute -inset-1 rounded-full animate-ping bg-blue-400/20" />
                      <div className="absolute -inset-2 rounded-full bg-blue-50/50 animate-pulse" />
                    </>
                  )}

                  {/* Completion Check */}
                  {isCompleted && (
                    <div className="absolute -right-1 -top-1 w-6 h-6 bg-green-500 rounded-full flex items-center justify-center shadow-lg ring-2 ring-white">
                      <svg
                        className="w-4 h-4 text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={3}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </div>
                  )}
                </div>

                {/* Step Content */}
                <div className="mt-4 space-y-1">
                  <div
                    className={cn(
                      "font-medium transition-colors",
                      isCompleted
                        ? "text-blue-600"
                        : isCurrent
                        ? "text-gray-900"
                        : "text-gray-400"
                    )}
                  >
                    {step.title}
                  </div>
                  <div
                    className={cn(
                      "text-sm transition-colors",
                      isCompleted || isCurrent
                        ? "text-gray-600"
                        : "text-gray-400"
                    )}
                  >
                    {step.description}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Pane>
    </>
  );
};

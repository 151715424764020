import React from 'react';

const ProviderNews: React.FC = () => {
  return (
    <div className="bg-[#EEF1FF] rounded-lg p-6">
      <h2 className="text-2xl font-bold text-gray-900 mb-6">
        Electricity Providers in the News
      </h2>

      <ul className="space-y-4">
        <li className="flex gap-2">
          <span className="text-blue-600">•</span>
          <p className="text-gray-600">
            <span className="text-blue-600 hover:underline cursor-pointer">
              TXU Energy announced new solar buyback program
            </span>{' '}
            offering competitive rates for customers with solar panels, expanding their renewable energy initiatives.
          </p>
        </li>
        <li className="flex gap-2">
          <span className="text-blue-600">•</span>
          <p className="text-gray-600">
            <span className="text-blue-600 hover:underline cursor-pointer">
              Reliant Energy extends their smart home program
            </span>{' '}
            to include additional device integrations and enhanced energy monitoring features.
          </p>
        </li>
      </ul>
    </div>
  );
};

export default ProviderNews;
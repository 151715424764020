import { MapPin } from "lucide-react";

import { FileText } from "lucide-react";

import { Zap } from "lucide-react";
import { EnrollStep } from "@/providers/enrollStore";
export const planProgressSteps = [
  {
    number: 1,
    key: EnrollStep.ENTER_LOCATION,
    title: "Enter Location",
    description: "Find available plans",
    icon: MapPin,
    color: "blue",
  },
  {
    number: 2,
    key: EnrollStep.PLAN_SELECTION,
    title: "Select Plan",
    description: "Compare and choose",
    icon: FileText,
    color: "indigo",
  },
  {
    number: 3,
    key: EnrollStep.SERVICE_DETAILS,
    title: "Complete Setup",
    description: "Finish enrollment",
    icon: Zap,
    color: "purple",
  },
];

import { create } from "zustand";

import { encrypt } from "@/helpers/encoding";
import { UsageTierOpts } from "@/types/frontend";
import { LatLngExpression } from "leaflet";
import { v4 as uuidv4 } from "uuid";
import { usePlansStore } from "./planStore";
import { useSessionStore } from "./store";
import { config } from "@/config";
import { TypicalContractLengthKeys, RateType, GreenImportance, PeakUsageTime } from "@/types/plan";
export enum EnrollStep {
  ENTER_LOCATION = "ENTER_LOCATION",
  PLAN_SELECTION = "PLAN_SELECTION",
  SERVICE_DETAILS = "SERVICE_DETAILS",
  PAYMENT_METHOD = "PAYMENT_METHOD",
  REVIEW_ORDER = "REVIEW_ORDER",
  CREDIT_CHECK = "CREDIT_CHECK",
  CREDIT_RETURNED = "CREDIT_RETURNED",
  CREDIT_APPROVED = "CREDIT_APPROVED",
  CONFIRMATION = "CONFIRMATION",
}
import { HomeSize, HVACType } from "@/types/properties/property";
export interface UsageMonthDate {
  month: number;
  year: number;
  usage: number;
}
export type ChangeType = "moving" | "switching";
export type LocationSpec = "none" | "state" | "zip" | "address" | "gps";
export interface EnrollData {
  enrollId: string;
  locationSpec: LocationSpec | null;
  step: EnrollStep | null;
  creditInfo: any;
  creditScore_provider_accountId?: string;
  creditScore_provider_id?: string;
  creditScore_provider?: string;
  creditScore?: number;
  dob?: string;
  email_verified_at?: number;
  email_verified: boolean;
  email: string;
  enrollEndTime: number;
  enrollStartTime: number;
  error: boolean;
  errorMessage: string;
  firstName: string;
  lastName: string;
  current_provider: string;
  current_contract_end_date: Date | null;
  current_usage_history: UsageMonthDate[];
  current_contract_length: TypicalContractLengthKeys | null;
  current_peakUsageTime: PeakUsageTime | null;
  workFromHome: boolean;
  target_budget: number;
  wants_level_billing: boolean;
  paymentMethod_billing_address_city: string;
  paymentMethod_billing_address_country: string;
  paymentMethod_billing_address_email: string;
  paymentMethod_billing_address_line1: string;
  paymentMethod_billing_address_line2: string;
  paymentMethod_billing_address_name: string;
  paymentMethod_billing_address_phone: string;
  paymentMethod_billing_address_sameAsEnrollAddress: boolean;
  paymentMethod_billing_address_state: string;
  paymentMethod_billing_address_verified_at?: number;
  paymentMethod_billing_address_verified: boolean;
  paymentMethod_billing_address_zip: string;
  paymentMethod_cardType: string;
  paymentMethod_cardNumber: string;
  paymentMethod_cardName: string;
  paymentMethod_expMonth: number | null;
  paymentMethod_expYear: number | null;
  paymentMethod_last4: string;
  paymentMethod: string;
  paymentMethod_verified_at?: number;
  paymentMethod_verified: boolean;
  phone_verified_at?: number;
  phone_verified: boolean;
  phone: string;
  planId: string;
  retailerId: string;
  service_address_place: google.maps.places.PlaceResult | null;
  service_address_esiId: string;
  service_address_city: string;
  service_address_line1: string;
  service_address_line2: string;
  service_address_state: string;
  service_address_zip: string;
  service_address: string;
  service_coords: { lat: number; lng: number };
  service_esiId: string;
  service_estimatedUsage: number;
  service_propertyType: "home" | "apartment" | null;
  service_homeSize: HomeSize | null;
  service_homeSize_sqft: number;
  service_hvacType: HVACType | null;
  service_meterNumber: string;
  service_place?: google.maps.places.PlaceResult;
  service_startDate: Date | null;
  service_startDate_earliestPossible: boolean;
  service_status: string;
  service_type: string;
  service_changeType: ChangeType | null;
  preference_greenImportance: GreenImportance | null;
  preference_rateType: RateType | null;
  ssn?: string;
  tdu: string;
  tduId: string;
  usageTier: UsageTierOpts;
}

export interface EnrollActions {
  startEnroll: (
    planId: string,
    retailerId: string,
    zipCode: string,
    sessionId: string
  ) => void;
  setLocationSpec: (locationSpec: LocationSpec) => void;
  setEnroll_step: (enroll_step: EnrollData["step"]) => void;
  setEnroll_creditInfo: (enroll_creditInfo: EnrollData["creditInfo"]) => void;
  setEnroll_creditScore_provider_accountId: (
    enroll_creditScore_provider_accountId: EnrollData["creditScore_provider_accountId"]
  ) => void;
  setEnroll_creditScore_provider_id: (
    enroll_creditScore_provider_id: EnrollData["creditScore_provider_id"]
  ) => void;
  setEnroll_creditScore_provider: (
    enroll_creditScore_provider: EnrollData["creditScore_provider"]
  ) => void;
  setEnroll_creditScore: (
    enroll_creditScore: EnrollData["creditScore"]
  ) => void;
  setEnroll_dob: (enroll_dob: EnrollData["dob"]) => void;
  setEnroll_email_verified_at: (
    enroll_email_verified_at: EnrollData["email_verified_at"]
  ) => void;
  setEnroll_email_verified: (
    enroll_email_verified: EnrollData["email_verified"]
  ) => void;
  setEnroll_email: (enroll_email: EnrollData["email"]) => void;
  setEnroll_error: (enroll_error: EnrollData["error"]) => void;
  setEnroll_errorMessage: (
    enroll_errorMessage: EnrollData["errorMessage"]
  ) => void;
  setEnroll_firstName: (enroll_firstName: EnrollData["firstName"]) => void;
  setEnroll_enrollId: (enroll_enrollId: EnrollData["enrollId"]) => void;
  setEnroll_lastName: (enroll_lastName: EnrollData["lastName"]) => void;
  setEnroll_workFromHome: (enroll_workFromHome: EnrollData["workFromHome"]) => void;
  setEnroll_paymentMethod_billing_address_city: (
    enroll_paymentMethod_billing_address_city: EnrollData["paymentMethod_billing_address_city"]
  ) => void;
  setEnroll_paymentMethod_billing_address_country: (
    enroll_paymentMethod_billing_address_country: EnrollData["paymentMethod_billing_address_country"]
  ) => void;
  setEnroll_paymentMethod_billing_address_email: (
    enroll_paymentMethod_billing_address_email: EnrollData["paymentMethod_billing_address_email"]
  ) => void;
  setEnroll_paymentMethod_billing_address_line1: (
    enroll_paymentMethod_billing_address_line1: EnrollData["paymentMethod_billing_address_line1"]
  ) => void;
  setEnroll_paymentMethod_billing_address_line2: (
    enroll_paymentMethod_billing_address_line2: EnrollData["paymentMethod_billing_address_line2"]
  ) => void;
  setEnroll_paymentMethod_billing_address_name: (
    enroll_paymentMethod_billing_address_name: EnrollData["paymentMethod_billing_address_name"]
  ) => void;
  setEnroll_paymentMethod_billing_address_phone: (
    enroll_paymentMethod_billing_address_phone: EnrollData["paymentMethod_billing_address_phone"]
  ) => void;
  setEnroll_paymentMethod_billing_address_sameAsEnrollAddress: (
    enroll_paymentMethod_billing_address_sameAsEnrollAddress: EnrollData["paymentMethod_billing_address_sameAsEnrollAddress"]
  ) => void;
  setEnroll_paymentMethod_billing_address_state: (
    enroll_paymentMethod_billing_address_state: EnrollData["paymentMethod_billing_address_state"]
  ) => void;
  setEnroll_paymentMethod_billing_address_verified_at: (
    enroll_paymentMethod_billing_address_verified_at: EnrollData["paymentMethod_billing_address_verified_at"]
  ) => void;
  setEnroll_paymentMethod_billing_address_verified: (
    enroll_paymentMethod_billing_address_verified: EnrollData["paymentMethod_billing_address_verified"]
  ) => void;
  setEnroll_paymentMethod_billing_address_zip: (
    enroll_paymentMethod_billing_address_zip: EnrollData["paymentMethod_billing_address_zip"]
  ) => void;
  setEnroll_paymentMethod_cardType: (
    enroll_paymentMethod_cardType: EnrollData["paymentMethod_cardType"]
  ) => void;
  setEnroll_paymentMethod_cardNumber: (
    enroll_paymentMethod_cardNumber: EnrollData["paymentMethod_cardNumber"]
  ) => void;
  setEnroll_paymentMethod_cardName: (
    enroll_paymentMethod_cardName: EnrollData["paymentMethod_cardName"]
  ) => void;
  setEnroll_paymentMethod_expMonth: (
    enroll_paymentMethod_expMonth: EnrollData["paymentMethod_expMonth"]
  ) => void;
  setEnroll_paymentMethod_expYear: (
    enroll_paymentMethod_expYear: EnrollData["paymentMethod_expYear"]
  ) => void;
  setEnroll_paymentMethod_last4: (
    enroll_paymentMethod_last4: EnrollData["paymentMethod_last4"]
  ) => void;
  setEnroll_paymentMethod: (
    enroll_paymentMethod: EnrollData["paymentMethod"]
  ) => void;
  setEnroll_paymentMethod_verified_at: (
    enroll_paymentMethod_verified_at: EnrollData["paymentMethod_verified_at"]
  ) => void;
  setEnroll_paymentMethod_verified: (
    enroll_paymentMethod_verified: EnrollData["paymentMethod_verified"]
  ) => void;
  setEnroll_phone_verified_at: (
    enroll_phone_verified_at: EnrollData["phone_verified_at"]
  ) => void;
  setEnroll_phone_verified: (
    enroll_phone_verified: EnrollData["phone_verified"]
  ) => void;
  setEnroll_phone: (enroll_phone: EnrollData["phone"]) => void;
  setEnroll_service_address_place: (
    enroll_service_address_place: EnrollData["service_address_place"]
  ) => void;
  setEnroll_service_address_esiId: (
    enroll_service_address_esiId: EnrollData["service_address_esiId"]
  ) => void;
  setEnroll_service_address_city: (
    enroll_service_address_city: EnrollData["service_address_city"]
  ) => void;
  setEnroll_service_address_line1: (
    enroll_service_address_line1: EnrollData["service_address_line1"]
  ) => void;
  setEnroll_service_address_line2: (
    enroll_service_address_line2: EnrollData["service_address_line2"]
  ) => void;
  setEnroll_service_address_state: (
    enroll_service_address_state: EnrollData["service_address_state"]
  ) => void;
  setEnroll_service_address_zip: (
    enroll_service_address_zip: EnrollData["service_address_zip"]
  ) => void;
  setEnroll_service_address: (
    enroll_service_address: EnrollData["service_address"]
  ) => void;
  setEnroll_service_coords: (
    enroll_service_coords: EnrollData["service_coords"]
  ) => void;
  setEnroll_service_esiId: (
    enroll_service_esiId: EnrollData["service_esiId"]
  ) => void;
  setEnroll_service_estimatedUsage: (
    enroll_service_estimatedUsage: EnrollData["service_estimatedUsage"]
  ) => void;
  setEnroll_service_homeSize_sqft: (
    enroll_service_homeSize_sqft: EnrollData["service_homeSize_sqft"]
  ) => void;
  setEnroll_service_homeSize: (
    enroll_service_homeSize: EnrollData["service_homeSize"]
  ) => void;
  setEnroll_service_hvacType: (
    enroll_service_hvacType: EnrollData["service_hvacType"]
  ) => void;
  setEnroll_service_propertyType: (
    enroll_service_propertyType: EnrollData["service_propertyType"]
  ) => void;
  setEnroll_service_meterNumber: (
    enroll_service_meterNumber: EnrollData["service_meterNumber"]
  ) => void;
  setEnroll_service_place: (
    enroll_service_place: EnrollData["service_place"]
  ) => void;
  setEnroll_service_startDate: (
    enroll_service_startDate: EnrollData["service_startDate"]
  ) => void;
  setEnroll_service_startDate_earliestPossible: (
    enroll_service_startDate_earliestPossible: EnrollData["service_startDate_earliestPossible"]
  ) => void;
  setEnroll_service_status: (
    enroll_service_status: EnrollData["service_status"]
  ) => void;
  setEnroll_service_type: (
    enroll_service_type: EnrollData["service_type"]
  ) => void;
  setEnroll_service_changeType: (
    enroll_service_changeType: EnrollData["service_changeType"]
  ) => void;
  setEnroll_ssn: (enroll_ssn: EnrollData["ssn"]) => void;
  setEnroll_usageTier: (enroll_usageTier: EnrollData["usageTier"]) => void;
  setEnroll_planId: (enroll_planId: EnrollData["planId"]) => void;
  setEnroll_retailerId: (enroll_retailerId: EnrollData["retailerId"]) => void;
  setEnroll_tduId: (enroll_tduId: EnrollData["tduId"]) => void;
  setEnroll_tdu: (enroll_tdu: EnrollData["tdu"]) => void;
  updateLocation: ({
    service_address_line1,
    service_address_line2,
    service_address_city,
    service_address_state,
    service_address_zip,
    service_address_place,
    service_coords,
    service_address_esiId,
  }: {
    service_address_line1?: string;
    service_address_zip?: string;
    service_address_line2?: string;
    service_address_city?: string;
    service_address_state?: string;
    service_address_place?: google.maps.places.PlaceResult | null;
    service_coords?: { lat: number; lng: number };
    service_address_esiId?: string;
  }) => void;
  setEnroll_current_usage_history: (
    current_usage_history: EnrollData["current_usage_history"]
  ) => void;
  setEnroll_current_provider: (
    current_provider: EnrollData["current_provider"]
  ) => void;
  setEnroll_current_contract_end_date: (
    current_contract_end_date: EnrollData["current_contract_end_date"]
  ) => void;
  setEnroll_target_budget: (target_budget: EnrollData["target_budget"]) => void;
  setEnroll_wants_level_billing: (
    wants_level_billing: EnrollData["wants_level_billing"]
  ) => void;
  setEnroll_current_contract_length: (
    current_contract_length: EnrollData["current_contract_length"]
  ) => void;
  setEnroll_preference_greenImportance: (
    preference_greenImportance: EnrollData["preference_greenImportance"]
  ) => void;
  setEnroll_preference_rateType: (
    preference_rateType: EnrollData["preference_rateType"]
  ) => void;
  setEnroll_current_peakUsageTime: (
    current_peakUsageTime: EnrollData["current_peakUsageTime"]
  ) => void;
}

export const useEnrollStore = create<EnrollData & EnrollActions>(
  (set, get) => ({
    enrollId: "",
    locationSpec: null,
    step: EnrollStep.ENTER_LOCATION,
    creditInfo: null,
    email_verified: false,
    email: "",
    enrollEndTime: 0,
    enrollStartTime: 0,
    error: false,
    errorMessage: "",
    firstName: "",
    lastName: "",
    current_usage_history: [],
    current_provider: "",
    current_contract_end_date: null,
    current_contract_length: null,
    current_peakUsageTime: null,
    preference_greenImportance: null,
    preference_rateType: null,
    target_budget: 0,
    wants_level_billing: false,
    workFromHome: false,
    paymentMethod_billing_address_city: "",
    paymentMethod_billing_address_country: "",
    paymentMethod_billing_address_email: "",
    paymentMethod_billing_address_line1: "",
    paymentMethod_billing_address_line2: "",
    paymentMethod_billing_address_name: "",
    paymentMethod_billing_address_phone: "",
    paymentMethod_billing_address_sameAsEnrollAddress: true,
    paymentMethod_billing_address_state: "",
    paymentMethod_billing_address_verified: false,
    paymentMethod_billing_address_zip: "",
    paymentMethod_cardType: "",
    paymentMethod_cardNumber: "",
    paymentMethod_cardName: "",
    paymentMethod_expMonth: null,
    paymentMethod_expYear: null,
    paymentMethod_last4: "",
    paymentMethod: "",
    paymentMethod_verified: false,
    paymentMethod_verified_at: 0,
    phone_verified: false,
    phone: "",
    planId: "",
    retailerId: "",
    service_address_place: null,
    service_address_esiId: "",
    service_address_city: "",
    service_address_line1: "",
    service_address_line2: "",
    service_address_state: "",
    service_address_zip: "",
    service_address: "",
    service_coords: config.DEFAULT_POSITION,
    service_esiId: "",
    service_estimatedUsage: 0,
    service_homeSize: null,
    service_homeSize_sqft: 0,
    service_hvacType: null,
    service_propertyType: null,
    service_meterNumber: "",
    service_startDate: new Date(),
    service_startDate_earliestPossible: false,
    service_status: "",
    service_type: "",
    service_changeType: null,

    ssn: "",
    dob: "",
    tdu: "",
    tduId: "",
    usageTier: UsageTierOpts.MEDIUM,
    startEnroll: (
      planId: string,
      retailerId: string,
      zipCode: string,
      sessionId: string
    ) => {
      const enrollId = uuidv4();
      const tdu = usePlansStore
        .getState()
        .plans.find((plan) => plan.getxid === planId)?.tdu;
      const tduId = usePlansStore
        .getState()
        .plans.find((plan) => plan.getxid === planId)?.data.tdu.getxid;
      if (tdu && tduId) {
        set((state) => ({
          enrollId,
          planId,
          retailerId,
          zipCode,
          tdu,
          tduId,
        }));
      } else if (tdu) {
        set((state) => ({
          enrollId,
          planId,
          retailerId,
          zipCode,
          tdu,
        }));
      } else if (tduId) {
        set((state) => ({
          enrollId,
          planId,
          retailerId,
          zipCode,
          tduId,
        }));
      } else {
        set((state) => ({
          enrollId,
          planId,
          retailerId,
          zipCode,
        }));
      }
    },
    updateLocation: (location) =>
      set((state) => ({
        ...state,
        service_address_line1: location.service_address_line1 || "",
        service_address_line2: location.service_address_line2 || "",
        service_address_city: location.service_address_city || "",
        service_address_state: location.service_address_state || "",
        service_address_zip: location.service_address_zip || "",
        service_address_place: location.service_address_place || null,
        service_coords: location.service_coords || config.DEFAULT_POSITION,
        service_address_esiId: location.service_address_esiId || "",
      })),
    setLocationSpec: (locationSpec) => set((state) => ({ locationSpec })),
    setEnroll_step: (enroll_step) => set((state) => ({ step: enroll_step })),
    setEnroll_creditInfo: (creditInfo) => set((state) => ({ creditInfo })),
    setEnroll_creditScore_provider_accountId: (
      creditScore_provider_accountId
    ) => set((state) => ({ creditScore_provider_accountId })),
    setEnroll_creditScore_provider_id: (creditScore_provider_id) =>
      set((state) => ({ creditScore_provider_id })),
    setEnroll_creditScore_provider: (creditScore_provider) =>
      set((state) => ({ creditScore_provider })),
    setEnroll_creditScore: (creditScore) => set((state) => ({ creditScore })),
    setEnroll_dob: (dob) => set((state) => ({ dob })),
    setEnroll_email_verified_at: (email_verified_at) =>
      set((state) => ({ email_verified_at })),
    setEnroll_email_verified: (email_verified) => {
      set((state) => ({ email_verified }));
      if (email_verified) {
        set((state) => ({
          email_verified_at: Date.now(),
        }));
      }
    },
    setEnroll_email: (email) => set((state) => ({ email })),
    setEnroll_error: (error) => set((state) => ({ error })),
    setEnroll_errorMessage: (errorMessage) =>
      set((state) => ({ errorMessage })),
    setEnroll_firstName: (firstName) => set((state) => ({ firstName })),
    setEnroll_enrollId: (enrollId) => set((state) => ({ enrollId })),
    setEnroll_lastName: (lastName) => set((state) => ({ lastName })),
    setEnroll_paymentMethod_billing_address_city: (
      paymentMethod_billing_address_city
    ) => set((state) => ({ paymentMethod_billing_address_city })),
    setEnroll_paymentMethod_billing_address_country: (
      paymentMethod_billing_address_country
    ) => set((state) => ({ paymentMethod_billing_address_country })),
    setEnroll_paymentMethod_billing_address_email: (
      paymentMethod_billing_address_email
    ) => set((state) => ({ paymentMethod_billing_address_email })),

    setEnroll_paymentMethod_billing_address_line1: (
      paymentMethod_billing_address_line1
    ) => set((state) => ({ paymentMethod_billing_address_line1 })),

    setEnroll_paymentMethod_billing_address_line2: (
      paymentMethod_billing_address_line2
    ) => set((state) => ({ paymentMethod_billing_address_line2 })),
    setEnroll_paymentMethod_billing_address_name: (
      paymentMethod_billing_address_name
    ) => set((state) => ({ paymentMethod_billing_address_name })),
    setEnroll_paymentMethod_billing_address_phone: (
      paymentMethod_billing_address_phone
    ) => set((state) => ({ paymentMethod_billing_address_phone })),
    setEnroll_paymentMethod_billing_address_sameAsEnrollAddress: (
      paymentMethod_billing_address_sameAsEnrollAddress
    ) =>
      set((state) => ({ paymentMethod_billing_address_sameAsEnrollAddress })),
    setEnroll_paymentMethod_billing_address_state: (
      paymentMethod_billing_address_state
    ) => set((state) => ({ paymentMethod_billing_address_state })),
    setEnroll_paymentMethod_billing_address_verified_at: (
      paymentMethod_billing_address_verified_at
    ) => set((state) => ({ paymentMethod_billing_address_verified_at })),
    setEnroll_paymentMethod_billing_address_verified: (
      paymentMethod_billing_address_verified
    ) => {
      set((state) => ({ paymentMethod_billing_address_verified }));
      if (paymentMethod_billing_address_verified) {
        set((state) => ({
          paymentMethod_billing_address_verified_at: Date.now(),
        }));
      }
    },
    setEnroll_paymentMethod_billing_address_zip: (
      paymentMethod_billing_address_zip
    ) => set((state) => ({ paymentMethod_billing_address_zip })),
    setEnroll_paymentMethod_cardNumber: (paymentMethod_cardNumber) =>
      set((state) => ({ paymentMethod_cardNumber })),
    setEnroll_paymentMethod_cardName: (paymentMethod_cardName) =>
      set((state) => ({ paymentMethod_cardName })),
    setEnroll_paymentMethod_cardType: (paymentMethod_cardType) =>
      set((state) => ({ paymentMethod_cardType })),
    setEnroll_paymentMethod_expMonth: (paymentMethod_expMonth) =>
      set((state) => ({ paymentMethod_expMonth })),
    setEnroll_paymentMethod_expYear: (paymentMethod_expYear) =>
      set((state) => ({ paymentMethod_expYear })),
    setEnroll_paymentMethod_last4: (paymentMethod_last4) =>
      set((state) => ({ paymentMethod_last4 })),
    setEnroll_paymentMethod: (paymentMethod) =>
      set((state) => ({ paymentMethod })),
    setEnroll_paymentMethod_verified_at: (paymentMethod_verified_at) =>
      set((state) => ({ paymentMethod_verified_at })),
    setEnroll_paymentMethod_verified: (paymentMethod_verified) => {
      set((state) => ({ paymentMethod_verified }));
      if (paymentMethod_verified) {
        set((state) => ({
          paymentMethod_verified_at: Date.now(),
        }));
      }
    },
    setEnroll_phone_verified_at: (phone_verified_at) =>
      set((state) => ({ phone_verified_at })),
    setEnroll_phone_verified: (phone_verified) => {
      set((state) => ({ phone_verified }));
      if (phone_verified) {
        set((state) => ({
          phone_verified_at: Date.now(),
        }));
      }
    },
    setEnroll_phone: (phone) => set((state) => ({ phone })),
    setEnroll_service_address_place: (service_address_place) =>
      set((state) => ({ service_address_place })),
    setEnroll_service_address_esiId: (service_address_esiId) =>
      set((state) => ({ service_address_esiId })),
    setEnroll_service_address_city: (service_address_city) =>
      set((state) => ({ service_address_city })),
    setEnroll_service_address_line1: (service_address_line1) =>
      set((state) => ({ service_address_line1 })),
    setEnroll_service_address_line2: (service_address_line2) =>
      set((state) => ({ service_address_line2 })),
    setEnroll_service_address_state: (service_address_state) =>
      set((state) => ({ service_address_state })),
    setEnroll_service_address_zip: (service_address_zip) =>
      set((state) => ({ service_address_zip })),
    setEnroll_service_address: (service_address) =>
      set((state) => ({ service_address })),
    setEnroll_service_coords: (service_coords) =>
      set((state) => ({ service_coords })),
    setEnroll_service_esiId: (service_esiId) =>
      set((state) => ({ service_esiId })),
    setEnroll_service_estimatedUsage: (service_estimatedUsage) =>
      set((state) => ({ service_estimatedUsage })),
    setEnroll_service_homeSize: (service_homeSize) =>
      set((state) => ({ service_homeSize })),
    setEnroll_service_homeSize_sqft: (service_homeSize_sqft) =>
      set((state) => ({ service_homeSize_sqft })),
    setEnroll_service_hvacType: (service_hvacType) =>
      set((state) => ({ service_hvacType })),
    setEnroll_service_meterNumber: (service_meterNumber) =>
      set((state) => ({ service_meterNumber })),
    setEnroll_service_place: (service_place) =>
      set((state) => ({ service_place })),
    setEnroll_service_startDate: (service_startDate) =>
      set((state) => ({ service_startDate })),
    setEnroll_service_startDate_earliestPossible: (
      service_startDate_earliestPossible
    ) => set((state) => ({ service_startDate_earliestPossible })),
    setEnroll_service_status: (service_status) =>
      set((state) => ({ service_status })),
    setEnroll_service_type: (service_type) =>
      set((state) => ({ service_type })),
    setEnroll_service_changeType: (service_changeType) =>
      set((state) => ({ service_changeType })),
    setEnroll_ssn: (ssn) => set((state) => ({ ssn })),
    setEnroll_usageTier: (usageTier) => set((state) => ({ usageTier })),
    setEnroll_planId: (planId) => set((state) => ({ planId })),
    setEnroll_retailerId: (retailerId) => set((state) => ({ retailerId })),
    setEnroll_tduId: (tduId) => set((state) => ({ tduId })),
    setEnroll_tdu: (tdu) => set((state) => ({ tdu })),
    setEnroll_current_usage_history: (current_usage_history) =>
      set((state) => ({ current_usage_history })),
    setEnroll_current_provider: (current_provider) =>
      set((state) => ({ current_provider })),
    setEnroll_current_contract_end_date: (current_contract_end_date) =>
      set((state) => ({ current_contract_end_date })),
    setEnroll_target_budget: (target_budget) =>
      set((state) => ({ target_budget })),
    setEnroll_wants_level_billing: (wants_level_billing) =>
      set((state) => ({ wants_level_billing })),
    setEnroll_service_propertyType: (service_propertyType) =>
      set((state) => ({ service_propertyType })),
    setEnroll_current_contract_length: (current_contract_length) =>
      set((state) => ({ current_contract_length })),
    setEnroll_preference_greenImportance: (preference_greenImportance) =>
      set((state) => ({ preference_greenImportance })),
    setEnroll_preference_rateType: (preference_rateType) =>
      set((state) => ({ preference_rateType })),
    setEnroll_current_peakUsageTime: (current_peakUsageTime) =>
      set((state) => ({ current_peakUsageTime })),
    setEnroll_workFromHome: (workFromHome) => set((state) => ({ workFromHome })),
  })
);

export async function makeEnrollPack(sessionId: string, data: EnrollData) {
  const dataPack = { sessionId, enrollData: encrypt(JSON.stringify(data)) };
  return dataPack;
}

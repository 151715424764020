import { stripAlphanumeric, toTitleCase } from "@/helpers/genericHelpers";
import { cn } from "@/lib/utils";
import FeatureChip from "@/components/individual/featureChip";

import { PlanInfo } from "@/types/plan";

const CustomChip = ({
  label,
  color = "default",
  className,
}: {
  label: string;
  color?: "default" | "primary" | "success" | "error" | string;
  className?: string;
}) => {
  const baseClasses =
    "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium";
  const colorClasses = {
    default: "bg-gray-100 text-gray-800",
    primary: "bg-blue-100 text-blue-800",
    success: "bg-green-100 text-green-800",
    error: "bg-red-100 text-red-800",
  };
  const chipColors = Object.keys(colorClasses).includes(color)
    ? colorClasses[color as keyof typeof colorClasses]
    : `bg-${color}-100 text-${color}-800`;
  const classNames = className
    ? cn(`${baseClasses} ${chipColors} ${className}`)
    : cn(`${baseClasses} ${chipColors}`);
  return <div className={classNames}>{label}</div>;
};

const formatDescription = (description: string) => {
  let splits = description.split(".");
  splits = splits.filter((split) => split.trim() !== "");
  splits = splits.map((split) => stripAlphanumeric(split.trim()));
  let bulletPoints = splits.map((split, index) => {
    return (
      <li key={index} style={{ listStyleType: "disc" }}>
        {split}
      </li>
    );
  });
  return <ul>{bulletPoints}</ul>;
};

export const renderChips = (plan: PlanInfo) => {
  const features = Object.keys(plan.features);
  return features.map((feature) => {
    if (plan.features[feature as keyof PlanInfo["features"]]) {
      if (feature === "tags") {
        return plan.features.tags?.map((tag, i) => {
          let newTag = tag.replace("-", " ");
          newTag = toTitleCase(newTag);
          if (tag === "lowest-price") {
            return (
              <FeatureChip key={i} feature="lowest-price" variant="secondary">
                {newTag}
              </FeatureChip>
            );
          }
          return (
            <FeatureChip key={i} feature="tags" variant="secondary">
              {newTag}
            </FeatureChip>
          );
        });
      }
      return (
        <FeatureChip
          key={feature}
          feature={feature as keyof PlanInfo["features"]}
        />
      );
    }
    return null;
  });
};

const termLengthChip = (termLength: number) => {
  const termLengthNumberString = termLength.toString().match(/\d+/)?.[0];
  if (!termLengthNumberString) {
    return null;
  }
  const termLengthNumber = parseInt(termLengthNumberString, 10);
  if (termLengthNumber > 1 && termLengthNumber < 124) {
    return <CustomChip label={`${termLength} Months`} color="primary" />;
  }
  return null;
};

const ratingChip = (rating: number) => {
  if (rating > 0 && rating < 5) {
    const stars = "★".repeat(rating) + "☆".repeat(5 - rating);
    return <CustomChip label={stars} />;
  }
  return null;
};

import { Button } from "@/components/ui/button";
import { usePlansStore } from "@/providers/planStore";
import { MinusIcon, PlusIcon } from "@radix-ui/react-icons";
import React from "react";

export const ComparePlanButton: React.FC<{ id: string }> = ({ id }) => {
  const planStore = usePlansStore();

  const { comparePlans, addComparePlan, removeComparePlan } = planStore;
  if (comparePlans.includes(id)) {
    return (
      <Button
        variant="outline"
        size="icon"
        onClick={() => removeComparePlan(id)}
        className="hover:bg-destructive hover:text-destructive-foreground rounded-full"
      >
        <MinusIcon />
      </Button>
    );
  }
  return (
    <Button
      variant="outline"
      className="rounded-full shadow-lg border-2 border-gray-300 w-10 h-10 group hover:w-28 transition-all duration-200 ease-in-out overflow-hidden relative"
      onClick={() => addComparePlan(id)}
    >
      <div className="absolute inset-0 flex flex-row items-center justify-center transition-all duration-200">
        <PlusIcon className="h-5 w-5" />
        <span className="w-0 group-hover:w-auto hidden group-hover:block transition-all duration-200 whitespace-nowrap text-xs mx-2">
          Compare
        </span>
      </div>
    </Button>
  );
};

/// <reference types="vite/client" />

const {
  VITE_REACT_APP_GOOGLE_MAPS_API_KEY,
  VITE_GOOGLE_MAPS_API_KEY,
  VITE_API_URL,
  VITE_REACT_APP_ENVIRONMENT,
  VITE_AUTH0_DOMAIN,
  VITE_AUTH0_CLIENT_ID,
  VITE_ENCODING_SECRET,
  VITE_DEV_UID,
  VITE_MAP_ID,
} = import.meta.env;

if (!VITE_AUTH0_DOMAIN || !VITE_AUTH0_CLIENT_ID) {
  throw new Error("AUTH0_DOMAIN and AUTH0_CLIENT_ID must be set");
}

const geSocials = {
  Linkedin: "https://www.linkedin.com/company/getelectricity/",
  Facebook: "https://www.facebook.com/profile.php?id=61553344137548",
  X: "https://x.com/get-electricity",
  Youtube: "https://www.youtube.com/@getelectricity",
  Instagram: "https://www.instagram.com/getelectricity/",
};

export const config = {
  DEFAULT_POSITION: { lat: 30.2672, lng: -97.7431 },
  ENCODING_SECRET: VITE_ENCODING_SECRET as string,
  GOOGLE_MAPS_API_KEY: VITE_GOOGLE_MAPS_API_KEY as string,
  API_URL: VITE_API_URL as string,
  ENVIRONMENT: VITE_REACT_APP_ENVIRONMENT as string,
  VERIFY_PHONE: false,
  AUTH0_DOMAIN: VITE_AUTH0_DOMAIN as string,
  AUTH0_CLIENT_ID: VITE_AUTH0_CLIENT_ID as string,
  SOCIALS: geSocials,
  DEV_UID: VITE_DEV_UID as string,
  MAP_ID: VITE_MAP_ID as string,
};

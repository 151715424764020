



const InvalidState = () => {
    return (
        <div>
            <h1>Invalid state</h1>
        </div>
    );
};

export default InvalidState;
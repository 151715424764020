import Page from "@/views/Page";
import { NewsHeader } from "./NewsHeader";
import Api from "@/utils/api";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { RSSFeed_T, RSSItem_T } from "@/types/rss";
import { Skeleton } from "@/components/ui/skeleton";
import NewsItem from "./NewsItem";

const allFeed: RSSFeed_T = {
  url: "",
  title: "All News",
  key: "all",
};

const News = () => {
  const [feeds, setFeeds] = useState<RSSFeed_T[]>([allFeed]);
  const [sectionKey, setSectionKey] = useState<string | undefined>("all");
  const [currentNews, setCurrentNews] = useState<{
    key: string;
    articles: RSSItem_T[];
  } | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [lastFetch, setLastFetch] = useState<Date | null>(null);

  const getNews = async () => {
    try {
      setIsLoading(true);
      const feedsData = await Api.getNewsFeeds();
      setFeeds([allFeed, ...feedsData]);

      // If we have feeds and no section is selected, select the first one
      if (feedsData.length > 0 && !sectionKey) {
        setSectionKey(feedsData[0].key);
      }
    } catch (error) {
      console.error("Failed to fetch news feeds:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getFeedArticles = async (key: string) => {
    try {
      setIsLoading(true);
      const { articles } = await Api.getNewsForFeed(key);
      setCurrentNews({ key, articles });
      setLastFetch(new Date());
    } catch (error) {
      console.error("Failed to fetch feed articles:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllNews = async () => {
    try {
      const articles = await Api.getAllNews();
      setCurrentNews({ key: "all", articles });
      setLastFetch(new Date());
    } catch (error) {
      console.error("Failed to fetch all news:", error);
    }
  };

  useEffect(() => {
    getNews();
  }, []);

  useEffect(() => {
    const refreshInterval = 2 * 60 * 1000; // 2 minutes in milliseconds

    const checkAndRefresh = () => {
      if (!lastFetch || Date.now() - lastFetch.getTime() >= refreshInterval) {
        setIsLoading(true);
        if (sectionKey === "all") {
          getAllNews();
        } else if (sectionKey) {
          getFeedArticles(sectionKey);
        }
        setIsLoading(false);
      }
    };

    const interval = setInterval(checkAndRefresh, refreshInterval);

    // Initial fetch
    checkAndRefresh();

    return () => clearInterval(interval);
  }, [lastFetch]);

  useEffect(() => {
    if (sectionKey === "all") {
      getAllNews();
    } else if (sectionKey) {
      getFeedArticles(sectionKey);
    }
  }, [sectionKey]);

  return (
    <div className="h-full my-12 mx-auto w-[80%] min-h-screen flex flex-col flex-wrap justify-start gap-2">
      <NewsHeader
        sectionKey={sectionKey}
        setSectionKey={setSectionKey}
        feeds={feeds}
        getNews={getNews}
        isLoading={isLoading}
        lastFetch={lastFetch}
      />
      {isLoading ? (
        <div className="w-full space-y-4">
          <Skeleton className="h-24 w-full rounded-lg" />
          <Skeleton className="h-24 w-full rounded-lg" />
          <Skeleton className="h-24 w-full rounded-lg" />
        </div>
      ) : (
        <div className="flex flex-row flex-wrap gap-2">
          {currentNews &&
            currentNews.key === sectionKey &&
            Array.isArray(currentNews.articles) &&
            currentNews.articles
              .sort(
                (a, b) =>
                  new Date(b.pubDate || Date.now()).getTime() -
                  new Date(a.pubDate || Date.now()).getTime()
              )
              .map((item) => (
                <NewsItem
                  key={uuidv4()}
                  item={item}
                  feeds={feeds}
                />
              ))}
        </div>
      )}
    </div>
  );
};

export default News;

import { v4 as uuidv4 } from "uuid";
import { cn } from "@/lib/utils"
export const RenderElement: React.FC<{
  element: string;
  id?: string;
  className?: string;
  type?: "title" | "body";
}> = ({ element, id, className, type }): React.ReactNode => {
  if (type === "title") {
    return (
      <div
        key={id || uuidv4()}
        className={cn("text-lg font-bold my-1 border-b border-stone-300", className)}
      >
        {element}
      </div>
    );
  }
  return (
    <div key={id || uuidv4()} className={cn("text-sm pl-2 bg-background", className)}>
      {element}
    </div>
  );
};

export default RenderElement;

import React from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';

interface RangeSliderProps {
  min: number;
  max: number;
  step: number;
  value: number | [number, number];
  onChange: (value: number | [number, number]) => void;
  formatLabel?: (value: number) => string;
}

const RangeSlider: React.FC<RangeSliderProps> = ({
  min,
  max,
  step,
  value,
  onChange,
  formatLabel = (value) => value.toString()
}) => {
  // Convert single value to array for consistent handling
  const values = Array.isArray(value) ? value : [value];
  
  return (
    <div className="space-y-4">
      <SliderPrimitive.Root
        className="relative flex items-center select-none touch-none w-full h-5"
        value={values}
        onValueChange={(newValues) => {
          onChange(values.length === 1 ? newValues[0] : newValues as [number, number]);
        }}
        min={min}
        max={max}
        step={step}
        aria-label="Range"
      >
        <SliderPrimitive.Track className="bg-gray-200 relative grow rounded-full h-1">
          <SliderPrimitive.Range className="absolute bg-blue-600 rounded-full h-full" />
        </SliderPrimitive.Track>
        {values.map((_, index) => (
          <SliderPrimitive.Thumb
            key={index}
            className="block w-5 h-5 bg-white border-2 border-blue-600 rounded-full hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
          />
        ))}
      </SliderPrimitive.Root>
      
      <div className="flex justify-between text-sm text-gray-600">
        {Array.isArray(value) ? (
          <>
            <span>{formatLabel(value[0])}</span>
            <span>{formatLabel(value[1])}</span>
          </>
        ) : (
          <>
            <span>{formatLabel(min)}</span>
            <span>{formatLabel(value)}</span>
            <span>{formatLabel(max)}</span>
          </>
        )}
      </div>
    </div>
  );
};

export default RangeSlider;
import React, { createContext, useContext, useReducer } from 'react';
import { GuideState, GuideAction } from './types';

const initialState: GuideState = {
  serviceType: null,
  moveDate: null,
  hasAddress: false,
  address: '',
  currentProvider: null,
  knowsContractEnd: false,
  contractEndDate: null,
  hasUsageHistory: false,
  usageHistory: {},
  homeSize: null,
  preferences: {
    priority: null,
    contractLength: null,
    renewable: false,
  },
};

function guideReducer(state: GuideState, action: GuideAction): GuideState {
  switch (action.type) {
    case 'SET_SERVICE_TYPE':
      return { ...state, serviceType: action.payload };
    case 'SET_MOVE_DATE':
      return { ...state, moveDate: action.payload };
    case 'SET_HAS_ADDRESS':
      return { ...state, hasAddress: action.payload };
    case 'SET_ADDRESS':
      return { ...state, address: action.payload };
    case 'SET_CURRENT_PROVIDER':
      return { ...state, currentProvider: action.payload };
    case 'SET_KNOWS_CONTRACT_END':
      return { ...state, knowsContractEnd: action.payload };
    case 'SET_CONTRACT_END_DATE':
      return { ...state, contractEndDate: action.payload };
    case 'SET_HAS_USAGE_HISTORY':
      return { ...state, hasUsageHistory: action.payload };
    case 'SET_USAGE_HISTORY':
      return { ...state, usageHistory: action.payload };
    case 'SET_HOME_SIZE':
      return { ...state, homeSize: action.payload };
    case 'SET_PREFERENCE_PRIORITY':
      return { 
        ...state, 
        preferences: { ...state.preferences, priority: action.payload } 
      };
    case 'SET_CONTRACT_LENGTH':
      return { 
        ...state, 
        preferences: { ...state.preferences, contractLength: action.payload } 
      };
    case 'SET_RENEWABLE':
      return { 
        ...state, 
        preferences: { ...state.preferences, renewable: action.payload } 
      };
    default:
      return state;
  }
}

const GuideContext = createContext<{
  state: GuideState;
  dispatch: React.Dispatch<GuideAction>;
} | null>(null);

export function GuideProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(guideReducer, initialState);

  return (
    <GuideContext.Provider value={{ state, dispatch }}>
      {children}
    </GuideContext.Provider>
  );
}

export function useGuide() {
  const context = useContext(GuideContext);
  if (!context) {
    throw new Error('useGuide must be used within a GuideProvider');
  }
  return context;
}
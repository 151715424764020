import { FC, useEffect } from 'react';
import { Shield, Star, DollarSign, MapPin } from 'lucide-react';
import HubLayout from "@/layouts/hub/HubLayout";
import ProviderCard from './ProviderCard';
import ProviderSummaryTable from './ProviderSummaryTable';
import { ProviderMethodology, ProviderNews, ProviderGuide } from './sections';
import { useRetailerStore } from '@/providers/retailerStore';

const ProvidersPage: FC = () => {
  const { retailers, getRetailers } = useRetailerStore();
  useEffect(() => {
    getRetailers();
  }, []);
  return (
    <HubLayout
      title="Texas Electricity Providers"
      subtitle="Compare trusted providers and find the perfect match for your home. Enter your ZIP code to see available plans in your area."
      backgroundImage="https://images.unsplash.com/photo-1497436072909-60f360e1d4b1"
      trustBadges={[
        { icon: Shield, text: 'Licensed by PUCT' },
        { icon: Star, text: 'Top Rated Service' },
        { icon: MapPin, text: `${retailers.length} Providers` },
        { icon: DollarSign, text: 'Best Rate Guarantee' }
      ]}
      keyBenefits={[
        'Compare top electricity providers',
        'Read verified customer reviews',
        'See current promotions & deals',
        'Expert provider analysis'
      ]}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="space-y-6">
          {retailers.map((retailer) => (
            <ProviderCard key={retailer.getxid} retailer={retailer} />
          ))}
        </div>

        <section id="comparison" className="mt-16">
          <h2 className="text-2xl font-bold text-gray-900 mb-6">
            Compare Texas Electricity Providers
          </h2>
          <ProviderSummaryTable retailers={retailers} />
        </section>

        <section id="methodology" className="mt-16">
          <ProviderMethodology />
        </section>

        <section className="mt-16">
          <ProviderNews />
        </section>

        <section className="mt-16">
          <ProviderGuide />
        </section>
      </div>
    </HubLayout>
  );
};

export default ProvidersPage;
import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Check, Sparkles, ArrowRight } from "lucide-react";
import { cn } from "@/lib/utils";
import Guide from "@/components/Guide/Guide";
import Lumina, { EMOJI_MAP, LuminaState } from "@/components/lumina/Lumina";
import { useSessionStore } from "@/providers/store";
import { ChatWindow } from "@/components/lumina/ChatWindow/ChatWindow";
interface LoadingSplashProps {
  onComplete: () => void;
}

const LoadingSplash: React.FC<LoadingSplashProps> = ({ onComplete }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [showGuidePrompt, setShowGuidePrompt] = useState(false);
  const [showGuide, setShowGuide] = useState(false);
  const [luminaState, setLuminaState] = useState<LuminaState>("1F601");

  const steps = [
    { id: "analyze", label: "Analyzing your service area..." },
    { id: "search", label: "Finding available plans..." },
    { id: "compare", label: "Comparing current rates..." },
    { id: "ready", label: "Ready to show your personalized recommendations!" },
  ];

  useEffect(() => {
    const timers = steps.map((_, index) => {
      return setTimeout(() => {
        setCurrentStep(index);
        if (index === steps.length - 1) {
          setLuminaState("1F601");
          onComplete();
        }
      }, index * 800);
    });

    return () => timers.forEach((timer) => clearTimeout(timer));
  }, []);

  const handleGuideStart = () => {
    setShowGuide(true);
    setShowGuidePrompt(false);
  };

  const handleViewPlans = () => {
    onComplete();
  };

  return (
    <div className="fixed inset-0 z-50 bg-gradient-to-br from-blue-900 via-blue-800 to-blue-900">
      <div className="min-h-screen flex flex-col items-center justify-center p-4">
        {/* Lumina */}
        {/* <div className="relative mb-16">
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            className="flex justify-center"
          >
            <Lumina state={luminaState} className="w-24 h-24" />
          </motion.div>
          <motion.div
            className="absolute inset-0 rounded-full bg-amber-500/20 blur-xl"
            animate={{
              scale: [1, 1.2, 1],
              opacity: [0.5, 0.8, 0.5],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          /> */}
          {/* <ChatWindow 
            luminaState={luminaState} 
            setLuminaState={setLuminaState}
          /> */}
        {/* </div> */}

        {/* Progress Steps */}
        <div className="w-full max-w-lg mx-auto space-y-3">
          {steps.map((step, index) => {
            const isComplete = index < currentStep;
            const isCurrent = index === currentStep;

            return (
              <motion.div
                key={step.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
                className={cn(
                  "flex items-center gap-3 px-6 py-4 rounded-xl backdrop-blur-sm",
                  isComplete
                    ? "bg-green-50/10"
                    : isCurrent
                    ? "bg-white/10"
                    : "bg-white/5"
                )}
              >
                <div
                  className={cn(
                    "w-5 h-5 rounded-full flex items-center justify-center",
                    isComplete
                      ? "bg-green-500"
                      : isCurrent
                      ? "bg-white"
                      : "bg-white/20"
                  )}
                >
                  {isComplete ? (
                    <Check className="w-3 h-3 text-white" />
                  ) : (
                    <div
                      className={cn(
                        "w-2 h-2 rounded-full",
                        isCurrent ? "bg-blue-600" : "bg-white/50"
                      )}
                    />
                  )}
                </div>
                <span
                  className={cn(
                    "text-base font-medium",
                    isComplete
                      ? "text-green-300"
                      : isCurrent
                      ? "text-white"
                      : "text-white/50"
                  )}
                >
                  {step.label}
                </span>
              </motion.div>
            );
          })}
        </div>
        

        {/* Guide Prompt */}
        <AnimatePresence>
          {showGuidePrompt && !showGuide && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="mt-12 bg-white/95 backdrop-blur-sm rounded-2xl p-8 w-full max-w-lg mx-auto shadow-xl"
            >
              <h3 className="text-2xl font-semibold text-gray-900 mb-8 text-center">
                Would you like help choosing a plan?
              </h3>
              <div className="flex flex-col sm:flex-row gap-4">
                <button
                  onClick={handleGuideStart}
                  className={cn(
                    "flex-1 inline-flex items-center justify-center gap-2",
                    "px-8 py-4 rounded-xl text-base font-medium",
                    "bg-blue-600 text-white",
                    "hover:bg-blue-700 transition-colors",
                    "shadow-lg hover:shadow-xl"
                  )}
                >
                  <Sparkles className="w-5 h-5" />
                  Guide Me
                </button>
                <button
                  onClick={handleViewPlans}
                  className={cn(
                    "flex-1 inline-flex items-center justify-center gap-2",
                    "px-8 py-4 rounded-xl text-base font-medium",
                    "bg-white text-gray-700 border border-gray-200",
                    "hover:bg-gray-50 transition-colors",
                    "shadow-lg hover:shadow-xl"
                  )}
                >
                  View Plans
                  <ArrowRight className="w-5 h-5" />
                </button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Guide Modal */}
        <AnimatePresence>
          {showGuide && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 overflow-y-auto p-4"
            >
              <div className="min-h-screen flex items-center justify-center py-8">
                <motion.div
                  initial={{ scale: 0.95, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0.95, opacity: 0 }}
                  className="relative w-full max-w-2xl mx-auto"
                >
                  <Guide />
                </motion.div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default LoadingSplash;

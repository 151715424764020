import { Pane } from "@/components/Pane";
import { useEffect, useState } from "react";
import Api from "@/utils/api";
import { Button } from "@/components/ui/button";
import { formatRelative } from "date-fns";


export const NewEsiIds = () => {
  const [esiIds, setEsiIds] = useState<any[]>([]);

  const handleGetNewEsiIds = () => {
    Api.auth
      .getRecentEsiIds(new Date().valueOf() - 24 * 60 * 60 * 1000)
      .then(setEsiIds);
  };

  useEffect(() => {
    handleGetNewEsiIds();
    
    const interval = setInterval(handleGetNewEsiIds, 60000); // Poll every minute
    
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <Pane className="flex flex-col gap-1 w-[20%] px-4 items-center justify-center min-w-[50%]">
        <div className="flex flex-row justify-between w-full">
            <div className="text-lg font-bold">New ESI IDs</div>
      <Button onClick={handleGetNewEsiIds}>Refresh</Button>
      </div>
        {esiIds.length > 0 ? (
          esiIds.slice(0, 20).map((esiId) => (
            <div key={esiId.id} className="grid grid-cols-5 gap-4 w-full">
              <div className="grid sub-grid text-sm text-gray-500 text-left">{esiId.esi_id}</div>
              <div className="grid sub-grid text-xs text-gray-500 text-left">{esiId.address}</div>
              <div className="grid sub-grid text-xs text-gray-500 text-left">{esiId.city}</div>
              <div className="grid sub-grid text-xs text-gray-500 text-left">{esiId.zipcode}</div>
              <div className="grid sub-grid text-xs text-gray-500 text-left">
                {formatRelative(
                  new Date(new Date(esiId.date_added).toLocaleString('en-US', { timeZone: 'America/Chicago' })), 
                  new Date()
                )}
              </div>
            </div>
          ))
        ) : (
          <div>No recent searches</div>
        )}
    </Pane>
  );
};

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from "@/components/ui/card";
import { useState } from "react";
import Login from "./login";
import Register from "./register";

const AuthPanel = () => {
  const [display, setDisplay] = useState<"login" | "register" | "code">(
    "login"
  );
  return (
    <Card id="auth-panel" className="flex flex-col gap-4 items-center justify-around m-auto min-w-[600px] min-h-[600px] text-primary bg-background !border-none">
      <CardHeader>
      </CardHeader>
      <CardContent className="flex flex-col gap-4 items-center justify-center text-primary">
        {display === "login" ? <Login /> : <Register />}
        <Button
          variant="link"
          onClick={() => setDisplay(display === "login" ? "register" : "login")}
          className="text-primary"
        >
          {display === "login"
            ? "Don't have an account? Register"
            : "Already have an account? Login"}
        </Button>
        <Button variant="link" onClick={() => setDisplay("code")} className="text-primary">
          Login with code
        </Button>
      </CardContent>
      
    </Card>
  );
};

export default AuthPanel;


import News from "@/views/News/News";







export const NewsPanel = () => {
  return <News />;
};

import { PlanInfo, PlanPricing, Source } from "@/types/plan";
import { usePlansStore } from "@/providers/planStore";

export const PriceDisplay: React.FC<{ plan: PlanInfo }> = ({ plan }) => {
  const planStore = usePlansStore();
  const filters = planStore.filters;

  let price = plan["plan-pricing"][
    `kwh-${filters.usageTier.value}` as keyof PlanPricing
  ] as number;
  if (plan.info.source === Source.PowerToChoose) {
    price = price * 100;
  }
  return (
    <div className="flex flex-col items-center justify-center mt-4 mb-2 p-4 w-full rounded-xl bg-blue-50">
      <div className="flex flex-row items-baseline justify-center gap-1 w-full">
        <span className="text-4xl font-bold text-slate-900">
          {price.toFixed(1)}
          ¢
        </span>
        <span className="text-gray-600">/kWh</span>
      </div>
      <p className="text-xs text-gray-500">at 1000 kWh usage</p>
    </div>
  );
};

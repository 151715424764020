




const WidgetShell: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="flex flex-col items-center justify-center border-2 border-stone-500 rounded-lg p-4">
      {children}
    </div>
  );
};

export default WidgetShell;

import { RSSItem_T } from "@/types/rss";
import { cn } from "@/lib/utils";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from "@/components/ui/card";
import { ExternalLink } from "lucide-react";
import { RSSFeed_T } from "@/types/rss";

const NewsItem: React.FC<{
  item: RSSItem_T;
  feeds: RSSFeed_T[];
}> = ({ item, feeds }) => {
  let fixedLink = item.link;
  if (feeds && item.feedKey) {
    const thisFeed = feeds.find((feed) => feed.key === item.feedKey);
    if (thisFeed) {
      const feedHostname = new URL(thisFeed.url).origin;
      if (!item.link.startsWith(feedHostname)) {
        if (item.link.startsWith("/") && !thisFeed.url.endsWith("/")) {
          fixedLink = `${feedHostname}${item.link}`;
        } else if (!item.link.startsWith("/") && thisFeed.url.endsWith("/")) {
          fixedLink = `${feedHostname}${item.link}`;
        } else if (!item.link.startsWith("/") && !thisFeed.url.endsWith("/")) {
          fixedLink = `${feedHostname}/${item.link}`;
        }
      }
    }
  }
  return (
    <a href={fixedLink} target="_blank" rel="noopener noreferrer">
      <Card
        className={cn(
          "cursor-pointer hover:shadow-lg transition-shadow duration-200",
          "group flex flex-col justify-between h-[300px] w-[300px]"
        )}
      >
        <div className="flex flex-col h-full overflow-hidden">
          <CardHeader className="flex-none">
            <CardTitle className="flex items-center gap-2 text-md line-clamp-2">
              {item.title}
              <ExternalLink className="h-4 w-4 flex-shrink-0 opacity-0 group-hover:opacity-100 transition-opacity" />
            </CardTitle>
          </CardHeader>
          {item.description && (
            <CardContent className="flex-1 overflow-hidden">
              <p className="text-sm text-muted-foreground line-clamp-6">
                {item.description}
              </p>
            </CardContent>
          )}
          {item.pubDate && (
            <CardFooter className="flex-none">
              <p className="text-sm text-muted-foreground">
                {new Date(item.pubDate).toLocaleDateString()}
              </p>
            </CardFooter>
          )}
        </div>
      </Card>
    </a>
  );
};

export default NewsItem;

"use client"

import { ChevronRight, type LucideIcon } from "lucide-react"
import { iconRouteObject } from "@/views-auth/routes"
import { Link } from "react-router-dom"
import { useSidebar } from "@/components/ui/sidebar"
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible"
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuAction,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from "@/components/ui/sidebar"

export function NavMain({
  items,
}: {
  items: iconRouteObject[]
}) {
  const { state } = useSidebar();
  return (
    <SidebarGroup>
      <SidebarGroupLabel>Panels</SidebarGroupLabel>
      <SidebarMenu className="gap-3 bg-stone-100">
        {items.map((item) => (
          <Collapsible key={item.label} asChild className="bg-stone-100">
            <SidebarMenuItem>
              <SidebarMenuButton asChild tooltip={item.label} className="text-primary font-semibold bg-transparent rounded-lg hover:ring-1 hover:ring-black ">
                <Link to={item.path ?? "/"} className="flex items-center gap-2">
                  <div className="flex items-center gap-2">
                    {state === "expanded" ? <item.icon className="h-4 w-4" /> : <item.icon className="h-4 w-4" />}
                  </div>
                  <span className="text-md font-light">{item.label}</span>
                </Link>
              </SidebarMenuButton>
              {item.children?.length ? (
                <>
                  <CollapsibleTrigger asChild>
                    <SidebarMenuAction className="data-[state=open]:rotate-90">
                      <ChevronRight />
                      <span className="sr-only">Toggle</span>
                    </SidebarMenuAction>
                  </CollapsibleTrigger>
                  <CollapsibleContent>
                    <SidebarMenuSub>
                      {item.children?.map((subItem) => (
                        <SidebarMenuSubItem key={subItem.label}>
                          <SidebarMenuSubButton asChild>
                            <Link to={subItem.path ?? "/"}>
                              <span className="text-lg font-light">{subItem.label}</span>
                            </Link>
                          </SidebarMenuSubButton>
                        </SidebarMenuSubItem>
                      ))}
                    </SidebarMenuSub>
                  </CollapsibleContent>
                </>
              ) : null}
            </SidebarMenuItem>
          </Collapsible>
        ))}
      </SidebarMenu>
    </SidebarGroup>
  )
}

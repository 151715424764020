import { decrypt, encrypt } from "@/helpers/encoding";
import { UsageTierOpts } from "@/types/frontend";
import Api from "@/utils/api";
import { v4 as uuidv4 } from "uuid";
import { create } from "zustand";

export type Modals = "planDetails" | "planCompare" | "auth" | "guide" | "splash";
export interface SessionData {
  sessionId: string;
  sessionStartTime: number;
  userId?: string; // Optional, if the session is associated with a logged-in user
  role?: string; // Optional, if the session is associated with a logged-in user
  map_zoom: number;
  searches: string[];
  plansClickedIds: string[];
  emailSubmitted: boolean;
  filterHistory: any[];
  pageHistory: string[];
  createdAt: number; // Timestamp of when the session was created
  updatedAt: number; // Timestamp of the last update
  expiresAt: number; // Timestamp of when the session expires
  isLeftNavOpen: boolean;
  modalOpen: Modals | null;
  bgColor: string;
  splashNavigation: string;
}

export interface SessionAction {
  updateStateFromDataPack: (dataPack: string) => void;
  updateSearches: (searches: SessionData["searches"]) => void;
  updatePlansClickedIds: (
    plansClickedIds: SessionData["plansClickedIds"]
  ) => void;
  updateFilterHistory: (filterHistory: SessionData["filterHistory"]) => void;
  updatePageHistory: (pageHistory: SessionData["pageHistory"]) => void;
  updateCreatedAt: (createdAt: SessionData["createdAt"]) => void;
  updateUpdatedAt: (updatedAt: SessionData["updatedAt"]) => void;
  updateExpiresAt: (expiresAt: SessionData["expiresAt"]) => void;
  updateMapZoom: (map_zoom: SessionData["map_zoom"]) => void;
  startNewSession: () => void;
  setSessionId: (sessionId: string) => void;
  submitEmail: (email: string) => void;
  setBgColor: (bgColor: string) => void;
  setIsLeftNavOpen: (isLeftNavOpen: boolean) => void;
  setModalOpen: (modalOpen: Modals | null) => void;
  setSplashNavigation: (splashNavigation: string) => void;
}

export const useSessionStore = create<SessionData & SessionAction>(
  (set, get) => ({
    splashNavigation: "",
    setSplashNavigation: (splashNavigation: string) => {
      set((state) => ({ ...state, splashNavigation }));
    },
    bgColor: "",
    setBgColor: (bgColor: string) => {
      set((state) => ({ ...state, bgColor }));
    },
    sessionId: "",
    sessionStartTime: 0,
    userId: "",
    role: "",
    locationSpec: null,
    map_zoom: 6,
    homeinfo_usageTier: UsageTierOpts.MEDIUM,
    homeinfo_estimatedUsage: 0,
    homeinfo_homeSize: 0,
    homeinfo_esiId: "",
    searches: [],
    plansClickedIds: [],
    filterHistory: [],
    pageHistory: [],
    createdAt: 0,
    updatedAt: 0,
    expiresAt: 0,
    modalOpen: null,
    emailSubmitted: false,
    setModalOpen: (modalOpen: Modals | null) => {
      set((state) => ({ ...state, modalOpen }));
    },
    isLeftNavOpen: false,
    startNewSession: () => {
      set((state) => {
        return {
          ...state,
          sessionId: uuidv4(),
          sessionStartTime: Date.now(),
          createdAt: Date.now(),
          updatedAt: Date.now(),
          expiresAt: Date.now() + 1000 * 60 * 60 * 24 * 30, // 30 days from now
        };
      });
      localStorage.setItem("sessionId", get().sessionId);
      Api.startSession(makeDataPack(get().sessionId, get()))
        .catch(console.error)
        .then((userId) => {
          set((state) => {
            return {
              ...state,
              userId,
            };
          });
        });
    },
    updateStateFromDataPack: (dataPack: string) => {
      const data = JSON.parse(decrypt(dataPack));
      set((state) => ({
        ...state,
        ...data.sessiondata,
      }));
    },
    setEnrollmentDialogOpen: (enrollmentDialogOpen: boolean) => {
      set((state) => {
        const newState = { ...state, enrollmentDialogOpen };
        console.log("State", state);
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    setIsLeftNavOpen: (isLeftNavOpen: boolean) => {
      set((state) => {
        console.log("State", state);
        const newState = { ...state, isLeftNavOpen };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },

    updateSessionData: async (updates: Partial<SessionData>) => {
      set((state) => {
        console.log("State", state);
        const newState = { ...state, ...updates };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    updateSearches: (searches) => {
      set((state) => {
        const newState = { ...state, searches };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    updatePlansClickedIds: (plansClickedIds) => {
      set((state) => {
        const newState = { ...state, plansClickedIds };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    updateFilterHistory: (filterHistory) => {
      set((state) => {
        const newState = { ...state, filterHistory };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    updatePageHistory: (pageHistory) => {
      set((state) => {
        const newState = { ...state, pageHistory };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    updateCreatedAt: (createdAt) => {
      set((state) => {
        const newState = { ...state, createdAt };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    updateUpdatedAt: (updatedAt) => {
      set((state) => {
        const newState = { ...state, updatedAt };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    updateExpiresAt: (expiresAt) => {
      set((state) => {
        const newState = { ...state, expiresAt };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    updateMapZoom: (map_zoom) => {
      set((state) => {
        const newState = { ...state, map_zoom };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    setSessionId: (sessionId: string) => {
      set((state) => {
        const newState = { ...state, sessionId };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    submitEmail: (email: string) => {
      set((state) => ({ ...state, emailSubmitted: true }));
      Api.submitEmail(email).catch(console.error);
    },
  })
);

export function makeDataPack(sessionId: string, data: SessionData) {
  const dataPack = { sessionId, dataPack: encrypt(JSON.stringify(data)) };
  return dataPack;
}

export async function delay(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export const extractAddressParts = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
  const addressParts: { [key: string]: string } = {};
  addressComponents.forEach((component) => {
    if (component.types.includes('street_number')) {
      addressParts.streetNumber = component.long_name;
    } else if (component.types.includes('route')) {
      addressParts.streetName = component.long_name;
    } else if (component.types.includes('locality')) {
      addressParts.city = component.long_name;
    } else if (component.types.includes('administrative_area_level_1')) {
      addressParts.state = component.short_name;
    } else if (component.types.includes('postal_code')) {
      addressParts.zipCode = component.long_name;
    } else if (component.types.includes('neighborhood')){
      addressParts.neighborhood = component.long_name;
    }
  });
  return addressParts;
}

export const metroToTitleCase = (metro: string): string => {
  // This function takes a string 'metro' and converts it to title case.
  // It first replaces any dashes with spaces.
  // Then, it uses a regular expression to find all words in the string.
  // For each word, it capitalizes the first character and converts the rest to lowercase.
  return metro.replace(/-/g, ' ').replace(/\w\S*/g, (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
}

export const toTitleCase = (str: string): string => {
  return str.replace(/\w\S*/g, (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
}

export const extractZipCode = (address: string): string => {
  // Regular expression to match zip code patterns in the address string
  const zipCodePattern = /\b\d{5}(?:-\d{4})?\b/;
  const addressParts = address.split(',');
  if (addressParts.length < 2) {
    return '';
  }
  if (addressParts.length === 4) {
    const maybeZip = addressParts[2].trim().split(' ')[1];
    const test = maybeZip.match(zipCodePattern);
    if (test) {
      return test[0];
    }
  }

  const match = address.match(zipCodePattern);

  // Return the matched zip code or null if no match is found
  return match ? match[0] : '';
};
export const isUSAddress = (address: string): boolean => {
  // Check if the address ends with 'USA'
  return address.trim().endsWith('USA');
};
export const getStateFromAddress = (address: string): string => {
  // Regular expression to match US state abbreviations (assuming address format includes "City, State ZIP, USA")
  const stateRegex = /,\s*([A-Z]{2})\s*\d{5},\s*USA$/;
  const match = address.match(stateRegex);

  if (match && match[1]) {
    return match[1];
  }
  return '';
};
export const getStreetAddress = (address: string): string => {
  const addressParts = address.split(',');
  if (addressParts.length < 2) {
    return '';
  }
  return addressParts[0];
};
export const getCityFromAddress = (address: string): string => {
  const addressParts = address.split(',');
  if (addressParts.length < 2) {
    return '';
  }
  if (addressParts.length === 4) {
    return addressParts[1].trim();
  }
  return addressParts[1].trim();
};
export const capitilizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const stripAlphanumeric = (str: string): string => {
  return str.replace(/[^a-zA-Z0-9.,\$\s-]/g, '');
}


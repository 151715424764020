import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Api from "@/utils/api";
import { Metric_T } from "@/types/data";
import { cn } from "@/lib/utils";

const metricKeys = ["average-price-1000", "cheapest-plan-1000", "total-plans"];

const formatValue = (value: string | number, value_type: string) => {
  const valueString = typeof value === "number" ? value.toString() : value;
  switch (value_type) {
    case "number":
      return parseFloat(valueString).toFixed(0);
    case "percentage":
      return `${parseFloat(valueString).toFixed(2)}%`;
    case "cents":
      return `${parseFloat(valueString).toFixed(2)} ¢`;
    case "dollars":
      return `$${parseFloat(valueString).toFixed(2)}`;
    default:
      return valueString;
  }
};

const HeroStats: React.FC = () => {
  const [cheapestPlan, setCheapestPlan] = useState<Metric_T | null>();
  const [averagePrice, setAveragePrice] = useState<Metric_T | null>();
  const [savings, setSavings] = useState<Metric_T | null>();
  const [displayStats, setDisplayStats] = useState<Metric_T[]>([]);
  useEffect(() => {
    const fetchStats = async () => {
      try {
        let cheapestPlan = await Api.getMetric("cheapest-plan-1000");
        if (cheapestPlan) {
          cheapestPlan.name = "Best Available Rate";
          setCheapestPlan(cheapestPlan);
        }
      } catch (err) {
        console.error(err);
      }
    };

    const fetchEIA = async () => {
      try {
        const data = await Api.getEIAData("prices");
        if (data.response.data.length > 0 && data.response.data[0].price) {
          setAveragePrice({
            id: "average-price-1000",
            key: "price",
            name: "Average Rate",
            value: parseFloat(data.response.data[0].price),
            value_type: "cents",
          });
        }
      } catch (error) {
        console.error("Error fetching EIA data:", error);
      }
    };

    fetchStats();
    fetchEIA();
  }, []);
  useEffect(() => {
    if (averagePrice && cheapestPlan) {
      const avgPriceYearly = (averagePrice.value / 100) * 1000 * 12;
      const cheapestPriceYearly = (cheapestPlan.value / 100) * 1000 * 12;
      const savings = avgPriceYearly - cheapestPriceYearly;
      const savingsMetric = {
        id: "savings",
        key: "savings",
        name: "Potential Annual Savings",
        value: savings,
        value_type: "dollars" as const,
      };
      setDisplayStats([averagePrice, cheapestPlan, savingsMetric]);
    }
  }, [averagePrice, cheapestPlan]);
  const statsx = [
    {
      current: "15+",
      label: "Trusted Providers",
      color: "text-white",
    },
    {
      current: "50k+",
      label: "Happy Customers",
      color: "text-green-400",
      highlight: true,
    },
    {
      current: "$350/yr",
      label: "Average Savings",
      color: "text-white",
    },
  ];

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.6 }}
      className="grid grid-cols-3 max-w-3xl mx-auto sm:px-2 gap-2 min-h-[100px]"
    >
      {displayStats.map((stat, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.7 + index * 0.1 }}
          className={cn(
            "px-1 sm:px-2",
            "text-center",
            "col-span-1",
            "h-full grid grid-rows-2"
          )}
        >
          <div
            className={cn(
              "text-2xl lg:text-4xl font-black mb-0.5 sm:mb-1 lg:mb-2 leading-tight",
              "text-white place-self-center",
              { "text-green-400 shadow-[0_0_15px_rgba(74,222,128,0.2)] bg-green-400/10 rounded-full p-4": index === 1 }
            )}
          >
            {formatValue(stat.value, stat.value_type)}
          </div>
          <div
            className={cn(
              "text-wrap text-xs lg:text-base text-blue-100 font-medium whitespace-nowrap leading-tight",
              
            )}
          >
            {stat.name}
            {index < 2 && (
              <div className="text-xs lg:text-base text-blue-100 font-medium whitespace-nowrap leading-tight">
                per kWh
              </div>
            )}
          </div>
        </motion.div>
      ))}
    </motion.div>
  );
};

export default HeroStats;

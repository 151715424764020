import { useState } from "react";
import DataPanel from "../tasks/DataPanel";
export const Retailers = () => {
  const [editing, setEditing] = useState<string | null>(null);
  return (
    <div>
      <div>Retailers</div>
      <DataPanel />
    </div>
  );
};

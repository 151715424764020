import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader
} from "@/components/ui/sidebar";
import { useAuthStore } from "@/providers/authStore";
import { panels } from "@/views-auth/routes";
import {
  AudioWaveform,
  Command,
  Zap
} from "lucide-react";
import * as React from "react";
import { NavMain } from "./nav-main";
import { NavUser } from "./nav-user";
import { TeamSwitcher } from "./team-switcher";



const data: {
  teams: any;
} = {
  
  teams: [
    {
      name: "GetElectricity",
      logo: Zap,
      plan: "",
    },
    {
      name: "GetUtilities",
      logo: AudioWaveform,
      plan: "",
    },
    {
      name: "XYZ",
      logo: Command,
      plan: "",
    },
  ],
};

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const { user } = useAuthStore();
  return (
    <Sidebar variant="inset" collapsible="icon" {...props} className="h-full bg-stone-100">
      <SidebarHeader className="bg-stone-100">
        <TeamSwitcher teams={data.teams} />
      </SidebarHeader>
      <SidebarContent className="flex flex-col gap-2 text-lg bg-stone-100">
        <NavMain items={panels} />
      </SidebarContent>
      <SidebarFooter>

        <NavUser user={user} />
      </SidebarFooter>
    </Sidebar>
  );
}

import "@/utils/leaflet-icons";
import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import { LatLngExpression } from "leaflet";
import { useSessionStore } from "@/providers/store";
import { useEffect } from "react";
import { useEnrollStore } from "@/providers/enrollStore";

const defaultPosition: LatLngExpression = { lat: 30.2672, lng: -97.7431 };

// Component to handle map view updates
const MapUpdater = ({
  center,
  zoom,
}: {
  center: LatLngExpression;
  zoom: number;
}) => {
  const map = useMap();

  useEffect(() => {
    map.setView(center, zoom);
    // Force a refresh of the map tiles
    map.invalidateSize();
  }, [map, center, zoom]);

  return null;
};

const Map = () => {
  const { service_coords } = useEnrollStore();
  const position = service_coords || defaultPosition;
  const { map_zoom } = useSessionStore() || 13;

  return (
    <div className="h-[400px] w-full relative">
      <MapContainer
        center={position}
        zoom={map_zoom}
        scrollWheelZoom={false}
        style={{ height: "100%", width: "100%" }}
        className="z-0"
      >
        <MapUpdater center={position} zoom={map_zoom} />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position}>
          <Popup>{map_zoom}</Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default Map;

export const usStateAbbreviations: string[] = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const usStateNames: string[] = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const statesJoined = usStateAbbreviations.map((state) => {
  return {
    short: state,
    long: usStateNames[usStateAbbreviations.indexOf(state)],
  };
});

export const findState = (state: string): string => {
  const normalizeState = (s: string) => s.toLowerCase().replace(/[^a-z]/g, '');
  const normalizedInput = normalizeState(state);

  const stateObj = statesJoined.find(s => 
    normalizeState(s.short) === normalizedInput || 
    normalizeState(s.long) === normalizedInput
  );

  return stateObj ? stateObj.long : state.toUpperCase();
};

export const validateState = (potentialState: string): boolean => {
  const normalizeState = (state: string) => state.replace(/[^a-z0-9]/gi, '').toLowerCase();
  
  const normalizedPotentialState = normalizeState(potentialState);
  
  const allStateOptions = [
    ...usStateAbbreviations,
    ...usStateNames,
    ...usStateNames.map(normalizeState)
  ];

  return allStateOptions.some(state => normalizeState(state) === normalizedPotentialState);
};

export const validateStateAbbreviation = (state: string): boolean => {
  return usStateAbbreviations.includes(state.toUpperCase());
};

export const validateStateName = (state: string): boolean => {
  return usStateNames.includes(state);
};

interface StateRegex {
  state: string;
  regex: RegExp;
}

const stateRegexes: StateRegex[] = [
  { state: 'AL', regex: /^\d{7,8}$/ }, // Alabama
  { state: 'AK', regex: /^[A-Z]{1}\d{6}$/ }, // Alaska
  { state: 'AZ', regex: /^[A-Z]{1}\d{8}$|^\d{9}$|^[A-Z]{2}\d{2,5}$|^\d{9}$/ }, // Arizona
  { state: 'AR', regex: /^\d{9}$|^\d{8}$|^[A-Z]{1}\d{6}$|^\d{4} \d{5}$/ }, // Arkansas
  { state: 'CA', regex: /^[A-Z]{1}\d{7}$/ }, // California
  { state: 'CO', regex: /^[A-Z]{1}\d{3,6}$|^\d{9}$|^\d{6}-\d{3}$/ }, // Colorado
  { state: 'CT', regex: /^\d{9}$/ }, // Connecticut
  { state: 'DE', regex: /^\d{1,7}$/ }, // Delaware
  { state: 'DC', regex: /^\d{7}$|^\d{9}$/ }, // District of Columbia
  { state: 'FL', regex: /^[A-Z]{1}\d{12}$/ }, // Florida
  { state: 'GA', regex: /^\d{7,9}$|^[A-Z]{1}\d{8}$|^\d{9}$/ }, // Georgia
  { state: 'HI', regex: /^\d{9}$/ }, // Hawaii
  { state: 'ID', regex: /^[A-Z]{2}\d{6}[A-Z]{1}$|^\d{9}$|^\d{3}-\d{3}-\d{3}$/ }, // Idaho
  { state: 'IL', regex: /^[A-Z]{1}\d{11}$/ }, // Illinois
  { state: 'IN', regex: /^[A-Z]{1}\d{9}$|^\d{10}$|^\d{9}$/ }, // Indiana
  { state: 'IA', regex: /^\d{9}$/ }, // Iowa
  { state: 'KS', regex: /^[A-Z]{1}\d{8}$|^\d{9}$/ }, // Kansas
  { state: 'KY', regex: /^\d{9}$|^[A-Z]{1}\d{8}$/ }, // Kentucky
  { state: 'LA', regex: /^\d{9}$/ }, // Louisiana
  { state: 'ME', regex: /^\d{7}$|^\d{8}$/ }, // Maine
  { state: 'MD', regex: /^[A-Z]{1}\d{12}$/ }, // Maryland
  { state: 'MA', regex: /^\d{9}$/ }, // Massachusetts
  { state: 'MI', regex: /^[A-Z]{1}\d{12}$/ }, // Michigan
  { state: 'MN', regex: /^[A-Z]{1}\d{12}$|^\d{9}$/ }, // Minnesota
  { state: 'MS', regex: /^\d{9}$/ }, // Mississippi
  { state: 'MO', regex: /^[A-Z]{1}\d{5,9}$|^\d{8}$/ }, // Missouri
  { state: 'MT', regex: /^\d{9}$|^[A-Z]{1}\d{8}$|^\d{13}$/ }, // Montana
  { state: 'NE', regex: /^\d{9}$/ }, // Nebraska
  { state: 'NV', regex: /^\d{9}$|^[X]{1}\d{8}$|^\d{10}$/ }, // Nevada
  { state: 'NH', regex: /^\d{2}[A-Z]{3}\d{5}$/ }, // New Hampshire
  { state: 'NJ', regex: /^[A-Z]{1}\d{14}$/ }, // New Jersey
  { state: 'NM', regex: /^\d{9}$|^\d{8}$|^[A-Z]{1}\d{8}$/ }, // New Mexico
  { state: 'NY', regex: /^[A-Z]{1}\d{7}$|^\d{9}$/ }, // New York
  { state: 'NC', regex: /^\d{1,12}$/ }, // North Carolina
  { state: 'ND', regex: /^[A-Z]{3}\d{6}$|^\d{9}$/ }, // North Dakota
  { state: 'OH', regex: /^[A-Z]{2}\d{6}$|^\d{8}$/ }, // Ohio
  { state: 'OK', regex: /^[A-Z]{1}\d{9}$|^\d{9}$/ }, // Oklahoma
  { state: 'OR', regex: /^\d{1,9}$|^[A-Z]{1}\d{6}$/ }, // Oregon
  { state: 'PA', regex: /^\d{8}$|^\d{9}$/ }, // Pennsylvania
  { state: 'RI', regex: /^\d{7,8}$/ }, // Rhode Island
  { state: 'SC', regex: /^\d{5,11}$/ }, // South Carolina
  { state: 'SD', regex: /^\d{6,10}$|^[A-Z]{1}\d{9}$/ }, // South Dakota
  { state: 'TN', regex: /^\d{7,9}$/ }, // Tennessee
  { state: 'TX', regex: /^\d{8}$/ }, // Texas
  { state: 'UT', regex: /^[A-Z]{1}\d{9}$|^\d{8}$|^\d{4,10}$/ }, // Utah
  { state: 'VT', regex: /^\d{8}$/ }, // Vermont
  { state: 'VA', regex: /^[A-Z]{1}\d{8}$|^\d{9}$/ }, // Virginia
  { state: 'WA', regex: /^[A-Z]{1}\d{7}[A-Z]{1}$|^\d{12}$|^[A-Z]{7}\d{1}$/ }, // Washington
  { state: 'WV', regex: /^\d{7}$|^[A-Z]{1}\d{6}$/ }, // West Virginia
  { state: 'WI', regex: /^[A-Z]{1}\d{13}$/ }, // Wisconsin
  { state: 'WY', regex: /^\d{9}$/ }, // Wyoming
];

export const validateDriverLicense = (
  state: string,
  licenseNumber: string
): boolean => {
  const stateRegex = stateRegexes.find((sr) => sr.state === state);
  if (stateRegex) {
    return stateRegex.regex.test(licenseNumber);
  }
  throw new Error('State not supported for validation');
};

export function validatePhoneNumber(phoneNumber: string): boolean {
  const phoneNumberRegex =
    /^(?:\+?1\s*(?:[.-]\s*)?)?(\(?\d{3}\)?|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}(?:\s*(?:#|x\.?|ext\.?|extension)\s*\d{1,5})?$/;
  return phoneNumberRegex.test(phoneNumber);
}

export function validateString(input: string): boolean {
  const stringRegex = /^[a-zA-Z0-9\s.,!?'"()-]+$/;
  return stringRegex.test(input);
}
export const validateZipCode = (zipCode: string): boolean => {
  const zipCodeRegex = /^\d{5}$/;
  return zipCodeRegex.test(zipCode);
}

import EvSavings from "./widgets/ev-savings";
import MortgageCalculator from "./widgets/mortgage";
import Budget from "./widgets/budget/budget";
const Calculators = () => {
  return (
    <div className="w-full h-full p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="w-full">
          <Budget />
        </div>
        <div className="w-full">
          <EvSavings />
        </div>
        <div className="w-full">
          <MortgageCalculator />
        </div>
      </div>
    </div>
  );
};

export default Calculators;

import { findState, validateState } from "@/helpers/validators";
import { getStateSVG } from "@/views/Localized/helpers";
import StateHeader from "./components/StateHeader";
import StateDeregulationInfo from "./components/StateInfo";

const StateDetail = ({state}: {state: string}) => {
    return (
        <div className="p-2">
            <StateHeader state={state} />
            <StateDeregulationInfo state={state} />
        </div>
    );
};

export default StateDetail;